import { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import Card from './Card'
import Spacer from './Spacer'

type Alignment = {
   alignItems: 'flex-start' | 'center'
}

const Wrapper = styled.div`
   display: flex;
   flex-direction: column;
`

const StyledCard = styled(Card)<Alignment>`
   display: flex;
   align-items: ${(props) => props.alignItems};
   flex-direction: row;
`

const Radio = styled.div<{ checked?: boolean }>`
   display: flex;
   justify-content: center;
   align-items: center;

   width: 24px;
   height: 24px;
   border-radius: 50%;

   background: ${(props) => props.theme.neutral.white};
   border: 2px solid
      ${(props) =>
         props.checked
            ? props.theme.colors.brand.secondary.main
            : props.theme.neutral.lightness};
`

const RadioCheck = styled.div`
   width: 12px;
   height: 12px;
   border-radius: 50%;

   background: ${(props) => props.theme.colors.brand.secondary.main};
`

export type Choice<T = any> = {
   data: T
   component: (data: T) => JSX.Element
}

type ChoicesProps = {
   choices: Choice[]
   selected: number
   alignItems?: Alignment['alignItems']
   onSelectedChange: (choice: Choice, index: number) => void
}

const Choices = ({
   choices,
   selected,
   alignItems = 'center',
   onSelectedChange,
}: ChoicesProps): JSX.Element => {
   const [selectedIndex, setSelectedIndex] = useState<number>(-1)

   useEffect(() => {
      setSelectedIndex(selected)
   }, [selected])

   useEffect(() => {
      if (selectedIndex > -1) {
         if (onSelectedChange)
            onSelectedChange(choices[selectedIndex], selectedIndex)
      }
   }, [selectedIndex])

   const renderChoice = (choice: Choice, index: number) => {
      const checked = index === selectedIndex

      return (
         <Fragment key={index}>
            <StyledCard
               onClick={() => setSelectedIndex(index)}
               alignItems={alignItems}
            >
               <Radio checked={checked}>{checked && <RadioCheck />}</Radio>
               <Spacer right={24} />
               {choice.component(choice.data)}
            </StyledCard>
            <Spacer bottom={16} />
         </Fragment>
      )
   }

   return <Wrapper>{choices.map(renderChoice)}</Wrapper>
}

export default Choices
