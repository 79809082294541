import styled from 'styled-components'

const Divider = styled.div`
   width: 100%;
   height: 0px;

   border: 1px solid ${(props) => props.theme.neutral.lightest};
`

export default Divider
