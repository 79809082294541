import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import api from '../api'

interface zipGeneratorResponse {
   link: string
}

interface  zipGeneratorRequest {
   children_id: number[]
}

export const zipGeneratorChildId = createAsyncThunk(
   'zipGeneratorChildId',
   async ({ children_id }: zipGeneratorRequest) => {
      return await api
         .get(`/children/download-images?${children_id.map((item, idx) => `children_id[${idx}]=${item}&`).join('')}`,
         )
         .then((res) => res.data)
   },
)
///children/download-images?children_id[]=3

const zipGeneratorChildIdSlice = createSlice({
   name: 'zipGenerator',
   initialState: { status: 'idle', message: '' },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(zipGeneratorChildId.pending, (state) => {
         state.status = 'loading'
      })
      builder.addCase(
        zipGeneratorChildId.fulfilled,
         (state, action: PayloadAction<zipGeneratorResponse>) => {
            state.status = 'ok'
            state.message = action.payload.link
         },
      )
      builder.addCase(zipGeneratorChildId.rejected, (state) => {
         state.status = 'rejected'
      })
   },
})

export default zipGeneratorChildIdSlice.reducer
