import Footer from 'components/Footer'
import routes from '../routes/routes.json'
import { Fragment, useEffect, useState } from 'react'
import { useFormatMessage } from 'react-intl-hooks'
import { Link } from 'react-router-dom'
import fns from 'services/storage'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser } from 'services/slices/login'
import { RootState } from 'services/store'
import styled, { css } from 'styled-components'
import { toast } from 'react-toastify'
import Checkbox from 'components/Checkbox'
import Button from 'components/Button'


const Login = (): JSX.Element => {
   const t = useFormatMessage()
   const dispatch = useDispatch()

   const [email, setEmail] = useState<string>('')
   const [password, setPassword] = useState<string>('')
   const [rememberMe, setRememberMe] = useState<boolean>(false)
   const [disabled, setDisabled] = useState<boolean>(false)
   const [error, setError] = useState<boolean>(false)

   const loginUserAuth = (e) => {
      e.preventDefault()
      setError(!error)
      dispatch(loginUser({ email: email, password: password }))
      setDisabled(true)
   }

   const { token, loadingStatus } = useSelector((state: RootState) => ({
      token: state.login.params.auth_secure_token,
      loadingStatus: state.login.status,
   }))

   useEffect(() => {
      if (loadingStatus === 'ok') {
         setDisabled(false)
         fns.setToken({ access_token: token })
         //fns.setUser({ id: 123 })
         window.location.href = routes.approvals
      } else if (loadingStatus === 'rejected') {
         setError(true)
         setDisabled(false)
      }
   }, [loadingStatus])

   return (
      <Fragment>
         <Content>
            <H1>{t({ id: 'Login' })}</H1>

            <Form onSubmit={loginUserAuth}>
               <Label>
                  {t({ id: 'Usuario' })}
                  <Input
                     type="email"
                     name="user"
                     placeholder={t({
                        id: 'Digite seu e-mail aqui',
                     }).toString()}
                     onChange={(event) => setEmail(event.target.value)}
                     required
                  />
               </Label>

               <Label>
                  {t({ id: 'Senha' })}
                  <Input
                     type="password"
                     name="password"
                     placeholder={t({
                        id: 'Digite sua senha aqui',
                     }).toString()}
                     onChange={(event) => setPassword(event.target.value)}
                     required                  
                  />
               </Label>               

               <DivFlex>
                  <CheckboxContainer>
                     {/* <Checkbox
                        checked={rememberMe}
                        onClick={() => setRememberMe(!rememberMe)}
                     />
                     <span>{t({ id: 'Memorizar senha' })}</span> */}
                  </CheckboxContainer>

                  {/* <RecSenha to={routes.recovery}>
                     {t({ id: 'Recuperar Senha' })}
                  </RecSenha> */}
               </DivFlex>

               <Error>
                  {error && <LabelError>
                     {t({ id: 'Error login' })}
                  </LabelError>}
               </Error>

               <Button
                  disabled={disabled}
                  type="submit"
                  full
                  label={t({ id: 'Confirmar' }).toString()}
               />
            </Form>
         </Content>
         <Footer />
      </Fragment>
   )
}

const Content = styled.div(
   ({ theme }) => css`
      flex-direction: column;
      align-items: center;
      padding-top: ${theme.spacing.xl};
      min-height: 80vh;
      display: flex;
   `,
)

const H1 = styled.h1(
   ({ theme }) => css`
      padding-bottom: ${theme.spacing.xl};
      font-size: ${theme.text.heading1.size};
      color: ${theme.neutral.main};
      font-family: ${theme.text.heading1.family};
      font-weight: ${theme.text.heading1.weight};
   `,
)

const Error = styled.div(
   ({ theme }) => css`
      flex-direction: column;
      align-items: center; 
      display: flex;
      
   `,
)


const RecSenha = styled(Link)(
   ({ theme }) => css`
      font-family: ${theme.text.title.family};
      line-height: ${theme.text.title.lineHeight};
      color: ${theme.colors.brand.primary.main};
      font-size: ${theme.text.title.size};
      text-decoration: none;
   `,
)

const DivFlex = styled.div(
   ({ theme }) => css`
      padding-bottom: ${theme.spacing.xl};
      justify-content: space-between;
      display: flex;
   `,
)

const Form = styled.form(
   ({ theme }) => css`
      box-shadow: '0px 1px 0px ${theme.neutral.lightest}';
      background: ${theme.neutral.white};
      border-radius: ${theme.spacing.xs};
      padding: ${theme.spacing.xl};
   `,
)

const Input = styled.input(
   ({ theme }) => css`
      border: 1.2px solid ${theme.neutral.lightest};
      margin: ${theme.spacing.xs} 0 ${theme.spacing.md};
      padding: ${theme.spacing.sm} ${theme.spacing.md};
      border-radius: ${theme.spacing.xs};
      display: block;
      width: calc(450px);

      line-height: ${theme.text.text1.lineHeight};
      font-family: ${theme.text.text1.family};
      font-size: ${theme.text.text1.size};
      font-weight: ${theme.text.text1.weight};

      :focus {
         outline-color: ${theme.colors.brand.secondary.main};
      }
   `,
)

const CheckboxContainer = styled.div(
   ({ theme }) => css`
      font-family: ${theme.text.text1.family};
      font-size: ${theme.text.text1.size};
      color: ${theme.colors.input};
      font-weight: ${theme.text.text1.weight};
      display: flex;
      align-items: center;

      span {
         margin-left: ${theme.spacing.sm};
      }
   `,
)

const Label = styled.label(
   ({ theme }) => css`
      line-height: ${theme.text.text1.lineHeight};
      font-family: ${theme.text.text1.family};
      font-size: ${theme.text.text1.size};
      color: ${theme.colors.input};
      font-weight: ${theme.text.text1.weight};
   `,
)

const LabelError = styled.label(
   ({ theme }) => css`
      line-height: ${theme.text.text1.lineHeight};
      font-family: ${theme.text.text1.family};
      font-size: ${theme.text.text1.size};
      font-weight: ${theme.text.text1.weight};
      color: red;
      margin-bottom: 10px;
   `,
)

export default Login
