import { css } from '@emotion/react';
import { BeatLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Spinner = () => {
  return (
    <BeatLoader color={'#123abc'} css={override} loading={true} />
  );
};

export default Spinner;