import { configureStore } from '@reduxjs/toolkit'
import loginReducer from './slices/login'
import recoveryPasswordReducer from './slices/recoveryPassword'
import listPackagesReducer from './slices/listPackages'
import listApprovalsReducer from './slices/listApprovals'
import listPackageDetailsReducer from './slices/listPackageDetails'
import listDonorsReducer from './slices/listDonors'
import listRevelationsReducer from './slices/listRevelations'
import listRevelationDetailsReducer from './slices/listRevelationDetails'
import listChosenChildReducer from './slices/listChosenChild'
import newDonorReducer from './slices/newDonor'
import idReferredReducer from './slices/idreferred'
import newDonorPhotoReducer from './slices/donorImage'
import zipcodeSearchReducer from './slices/zipcodeSearch'
import approvePhotoReducer from './slices/approvePhoto'
import approvePayReducer from './slices/approvePay'
import eventDateReducer from './slices/eventDate'
import donorsDetailsReducer from './slices/donorsDetails'
import zipGeneratorReducer from './slices/zipGenerator'
import zipGeneratorChildIdReducer from './slices/zipGeneratorChildId'
import reproveDonorPackageDetailsReducer from './slices/reproveDonorPackageDetails'
import attachModalReducer from './slices/modalAttachChild'
import updateDonorReducer from './slices/updateDonor'
import revelationByEmailReducer from './slices/sendRevelationByEmail'
import donationProductsReducer from './slices/donationProducts'
import financialInstitutionsReducer from './slices/financialInstitutions'
import searchChildReducer from './slices/searchChild'
import newPasswordReducer from './slices/newPassword'
import choosePhotoAgainReducer from './slices/choosePhotoAgain'
import chosenRevealReducer from './slices/chosenReveal'

export const store = configureStore({
   reducer: {
      login: loginReducer,
      recoveryPassword: recoveryPasswordReducer,
      listPackages: listPackagesReducer,
      listApprovals: listApprovalsReducer,
      listPackageDetails: listPackageDetailsReducer,
      listDonors: listDonorsReducer,
      listRevelations: listRevelationsReducer,
      listRevelationDetails: listRevelationDetailsReducer,
      listChosenChild: listChosenChildReducer,
      newDonor: newDonorReducer,
      idReferred: idReferredReducer,
      donorImage: newDonorPhotoReducer,
      zipcodeSearch: zipcodeSearchReducer,
      approvePhoto: approvePhotoReducer,
      approvePay: approvePayReducer,
      eventDate: eventDateReducer,
      donorsDetail: donorsDetailsReducer,
      zipGenerator: zipGeneratorReducer,
      zipGeneatorChildId: zipGeneratorChildIdReducer,
      reproveDonorPackageDetails: reproveDonorPackageDetailsReducer,
      attachChildModal: attachModalReducer,
      updateDonor: updateDonorReducer,
      sendRevelationByEmail: revelationByEmailReducer,
      donationProducts: donationProductsReducer,
      financialInstitutions: financialInstitutionsReducer,
      searchChild: searchChildReducer,
      newPassword: newPasswordReducer,
      choosePhotoAgain: choosePhotoAgainReducer,
      chosenReveal: chosenRevealReducer,
   },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
