import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import api from 'services/api'

export type PackageInfoProps = {
   donor_id: number
   name: string
   revelation_sent: string
   revelation_type: string
   simma_donor_id: string
   tracking_code: string
}

export interface PackageDetailsProps {
   paginate: {
      has_more: boolean
      page: number
      total: number
   }
   results: {
      package_info: {
         date: string
         id: string
         package_donors: PackageInfoProps[]
         title: string
      }
   }
}

interface listRevelationDetailsRequest {
   pg?: number
   pp?: number
   revelationId: string
}

export const listRevelationDetails = createAsyncThunk(
   'listRevelationDetails',
   async ({ pg, pp, revelationId }: listRevelationDetailsRequest) => {
      return await api
         .get(`/revelation/${revelationId}?pp=${pp ?? 15}&pg=${pg ?? 1}`)
         .then((res) => res.data)
   },
)

const listRevelationDetailsSlice = createSlice({
   name: 'listRevelationDetails',
   initialState: {
      status: 'idle',
      paginate: {
         has_more: false,
         page: 0,
         total: 0,
      },
      results: {
         package_info: {
            date: '',
            id: '',
            package_donors: [],
            title: '',
         },
      },
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(listRevelationDetails.pending, (state) => {
         state.status = 'loading'
      })
      builder.addCase(
         listRevelationDetails.fulfilled,
         (state, action: PayloadAction<PackageDetailsProps>) => {
            state.status = 'ok'
            state.results.package_info = action.payload.results.package_info
         },
      ),
         builder.addCase(listRevelationDetails.rejected, (state) => {
            state.status = 'rejected'
         })
   },
})

export default listRevelationDetailsSlice.reducer
