import Button from 'components/Button'
import Spacer from 'components/Spacer'
import { useFormatMessage } from 'react-intl-hooks'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useEffect, useState } from 'react';
import { env } from 'process'
import axios from 'axios';
import api from '../services/api'

const Container = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   max-width: 530px;
   margin: 0 auto;
   min-height: 90vh;
`

const ThanksIcon = styled.img`
   width: 400px;
   object-fit: container;
`

const Title = styled.p`
   max-width: 404px;

   font-family: ${(props) => props.theme.text.heading1.family};
   font-size: ${(props) => props.theme.text.heading1.size};
   font-weight: ${(props) => props.theme.text.heading1.weight};
   line-height: ${(props) => props.theme.text.heading1.lineHeight};

   color: ${(props) => props.theme.neutral.main};
   text-align: center;

   margin: 0;
`

const Subtitle = styled.p`
   max-width: 352px;

   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   color: ${(props) => props.theme.neutral.sub};
   text-align: center;

   margin: 0;
`

const getStatePayment = (url) => {
    return axios.get(
      url
  );
}

const postUpdatePayment = (id, data) => {
   api.post(`/checkout/update-payment/${id}`, data).then((res) =>   
      console.log(res.data)      
   )   
}

const Thanks = (): JSX.Element => {
   const history = useHistory()
   const t = useFormatMessage()
   const [status, setStatus] = useState('')
   const [date_last_updated, setDate_last_updated] = useState('')
   const [status_detail, setStatus_detail] = useState('')
   const [id, setId] = useState('')
   


   useEffect(() => {
      const currentUrl = window.location.href;
      const queryString = currentUrl.split('?')[1];
      const accToken = process.env.REACT_APP_MEPA_ACCESS_TOKEN
      if (queryString) {
         const payment_id = currentUrl.split('=')[1];
         (async () => {
            try {
               const url = `https://api.mercadopago.com/v1/payments/${payment_id}?access_token=${accToken}`
               const objPay = await getStatePayment(url)
                  .then((data) => {
                     setStatus(data.data.status)
                     setStatus_detail(data.data.status_detail)
                     setDate_last_updated(data.data.date_last_updated)
                     setId(payment_id)
                  })
               
               
            } catch (e) {
               return console.error('Error getting identificationTypes: ', e);
               
            }
         })()

      } 
      
   }, [])   

   
   const updatePymentStatus = () => {
      
      const transaction = {
      id: id,
      status: status,
      status_detail: status_detail,
      date_last_updated: date_last_updated
      }

      if (id && status && status_detail && date_last_updated) {         
         postUpdatePayment(id, transaction)
      }
   }
 
   updatePymentStatus();

   return (
      <Container>
         <ThanksIcon src="/assets/icons/Group_19455.png" />
         <Spacer bottom={80} />

         <Title>{t({ id: 'sua doacao foi realizada com sucesso' })}</Title>
         <Spacer bottom={16} />

         <Subtitle>
            {t({
               id: 'voce recebera um email de confirmacao com a explicacao dos proximos passos',
            })}
         </Subtitle>
         <Spacer bottom={40} />

         <div>
            <Button
               label={t({ id: 'ir para pagina inicial' }).toString()}
               onClick={() => history.push('/')}
            />
         </div>
      </Container>
   )
}

export default Thanks
