import React from 'react';

interface FileUploaderProps {
   onFileLoad: (base64String: string) => void;
}

const FileUploader: React.FC<FileUploaderProps> = ({ onFileLoad }) => {
   const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
         const reader = new FileReader();
         reader.onloadend = () => {
            const base64String = reader.result?.toString(); // Aquí se elimina el prefijo de la cadena base64
            onFileLoad(base64String);
         };
         reader.readAsDataURL(file);
      }
   };

   return (
      <input
         type="file"
         onChange={handleFileChange}
         accept="image/*"
         style={{ display: 'none' }}
         id="fileInput"
      />
   );
};

export default FileUploader;
