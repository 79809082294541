import { useEffect, useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import styled, { css } from 'styled-components';
import Input from 'components/Input';
import { Form, Formik } from 'formik';
import Spacer from 'components/Spacer';
import * as yup from 'yup';
import Button from 'components/Button';
import { REQUIRED_LABEL } from 'services/validators';
import PhotoPicker, { ImgPicked } from 'components/PhotoPicker';
import VideoPicker, { VideoPicked } from 'components/VideoPicker';
import { useDispatch, useSelector } from 'react-redux';
import { attachChildModal } from 'services/slices/modalAttachChild';
import { RootState } from 'services/store';
import { toast } from 'react-toastify';
import { searchChild } from 'services/slices/searchChild';
import ReactLoading from 'react-loading';
import theme from '../../styles/theme';
import useRegionalization from 'hooks/useRegionalization';

type Values = {
   child_id: string;
   child_name: string;
   child_city: string;
   child_age: string;
   child_id_complement: string;
};

type ModalProps = {
   isVisible: boolean;
};

const photosLimits = [
   '*Selecione um arquivo com até 10MB',
   '*Formatos aceitos: JPG , JPEG e PNG',
];

const photosLimitsMX = [
   '*Selecione um arquivo com até 10MB',
   '*Formato aceito: PDF',
];

const videoLimits = ['*Selecione um arquivo com até 50MB'];

type AttachChildValidation = {
   onSubmit?: () => void;
   onClose: () => void;
   isOpen?: boolean;
};

const AttachChildModal = ({
   onSubmit,
   onClose,
   isOpen,
}: AttachChildValidation): JSX.Element => {
   const t = useFormatMessage();
   const dispatch = useDispatch();
   const { country } = useRegionalization();
   const [imgPicked, setImgPicked] = useState<ImgPicked>();
   const [videoPicked, setVideoPicked] = useState<VideoPicked>();
   const [isSearching, setIsSearching] = useState<boolean>(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(true);

   const {
      loadingChildId,
      childCitySimma,
      childNameSimma,
      childAgeSimma,
      data,
      donor_id,
   } = useSelector((state: RootState) => ({
      data: state.attachChildModal.result,
      loadingChildId: state.searchChild.status,
      childCitySimma: state.searchChild.city,
      childNameSimma: state.searchChild.name,
      childAgeSimma: state.searchChild.child_age,
      donor_id: state.donorsDetail.donor_data.id,
   }));

   const handleSubmit = async (values: Values) => {
      const data = {
         child_id: `${values.child_id}-${values.child_id_complement}`,
         child_name: values.child_name,
         child_city: values.child_city,
         child_age: values.child_age,
         child_photo: imgPicked.url,
         child_video: videoPicked.url,
         donor_id: donor_id,
      };

      const response: any = await dispatch(attachChildModal(data));

      if (attachChildModal.fulfilled.match(response)) {
         if (onSubmit) onSubmit();
      } else {
         toast.error(
            t({ id: 'Nao foi possível vincular o compromisso' }).toString(),
         );
      }
   };

   const handleSearchChild = async (
      child_id: string,
      child_id_complement: string,
   ) => {
      setIsSearching(true);
      if (child_id.length > 0 && child_id_complement.length > 0) {
         await dispatch(
            searchChild({
               frist_id: child_id.toString(),
               second_id: child_id_complement.toString(),
            }),
         );
         setIsSearching(false);
      } else {
         toast.error(t({ id: 'ID invalido' }));
         setIsSearching(false);
      }
   };

   const FormSchema = yup.object().shape({
      child_id: yup.string().required(REQUIRED_LABEL),
      child_name: yup.string().required(REQUIRED_LABEL),
      child_city: yup.string().required(REQUIRED_LABEL),
      child_id_complement: yup.string().required(REQUIRED_LABEL),
   });
   return (
      <CustomAppModal isVisible={isOpen}>
         <ContainerModal>
            <ModalCloseButton type="button" onClick={() => onClose()} />
            <Formik
               initialValues={{
                  child_id: data?.child_id ?? null,
                  child_name: data?.child_name ?? '',
                  child_city: data?.child_city ?? '',
                  child_age: data?.child_age ?? '',
                  child_id_complement: '',
               }}
               onSubmit={handleSubmit}
               validationSchema={FormSchema}
            >
               {({
                  values,
                  errors,
                  touched,
                  isValid,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
               }) => {
                  useEffect(() => {
                     if (loadingChildId === 'ok' && childNameSimma) {
                        setFieldValue('child_name', childNameSimma);
                        if (childCitySimma) {
                           setFieldValue('child_city', childCitySimma);
                           setFieldValue('child_age', childAgeSimma);
                           setIsDisabled(true);
                        } else {
                           setIsDisabled(false);
                        }
                     } else if (
                        (loadingChildId === 'ok' && !childNameSimma) ||
                        loadingChildId === 'rejected'
                     ) {
                        toast.error(t({ id: 'Crianca nao encontrada' }));
                     }
                  }, [loadingChildId]);

                  return (
                     <Form onSubmit={handleSubmit}>
                        <H1>{t({ id: 'vincular crianca' })}</H1>
                        <Spacer bottom={40} />
                        <SearchContainer>
                           <label>{t({ id: 'child id' })}</label>

                           <DivFlex>
                              <Input
                                 placeholder={t({ id: 'Digite' }).toString()}
                                 value={values.child_id}
                                 onChange={handleChange('child_id')}
                                 onBlur={handleBlur('child_id')}
                                 error={
                                    touched.child_id
                                       ? errors.child_id
                                       : undefined
                                 }
                                 type="text"
                                 name="child_id"
                                 autoComplete="child_id"
                                 autoCapitalize="off"
                                 maxLength={7}
                                 wrapperStyle={{
                                    maxWidth: '40%',
                                    paddingRight: '20px',
                                 }}
                              />
                              <Divider>-</Divider>
                              <Input
                                 placeholder={t({ id: 'Digite' }).toString()}
                                 value={values.child_id_complement}
                                 onChange={handleChange('child_id_complement')}
                                 onBlur={handleBlur('child_id_complement')}
                                 error={
                                    touched.child_id
                                       ? errors.child_id
                                       : undefined
                                 }
                                 type="text"
                                 name="child_id_complement"
                                 autoComplete="child_id_complement"
                                 autoCapitalize="off"
                                 maxLength={7}
                                 wrapperStyle={{ maxWidth: '40%' }}
                              />
                              <SearchButton
                                 onClick={() =>
                                    handleSearchChild(
                                       values.child_id,
                                       values.child_id_complement,
                                    )
                                 }
                                 disabled={isSearching}
                              >
                                 {isSearching ? (
                                    <ReactLoading
                                       type="spin"
                                       color={theme.neutral.white}
                                       width={30}
                                       height={30}
                                    />
                                 ) : (
                                    <i className="fas fa-arrow-right"></i>
                                 )}
                              </SearchButton>
                           </DivFlex>
                        </SearchContainer>
                        <Spacer bottom={24} />
                        <Input
                           label={t({ id: 'nome da crianca' }).toString()}
                           placeholder={t({ id: 'Digite' }).toString()}
                           value={values.child_name}
                           onChange={handleChange('child_name')}
                           onBlur={handleBlur('child_name')}
                           error={
                              touched.child_name ? errors.child_name : undefined
                           }
                           type="text"
                           disabled
                           name="child_name"
                           autoComplete="child_name"
                           autoCapitalize="words"
                           maxLength={50}
                        />
                        <Spacer bottom={24} />
                        <Input
                           label={t({ id: 'cidade da crianca' }).toString()}
                           placeholder={t({ id: 'Digite' }).toString()}
                           value={values.child_city}
                           onChange={handleChange('child_city')}
                           onBlur={handleBlur('child_city')}
                           type="text"
                           error={
                              touched.child_city ? errors.child_city : undefined
                           }
                           disabled={isDisabled}
                           name="child_city"
                           autoComplete="on"
                           autoCapitalize="off"
                           maxLength={20}
                        />
                        <Spacer bottom={40} />
                        <Title>{t({ id: 'foto da crianca' })}</Title>
                        <Spacer bottom={24} />
                        <PhotoPicker
                           limits={photosLimits}
                           previewUrl={imgPicked?.url}
                           onImgPicked={setImgPicked}
                        />

                        <Spacer bottom={40} />
                        <Title>{t({ id: 'video da crianca' })}</Title>
                        <Spacer bottom={24} />
                        <VideoPicker
                           limits={videoLimits}
                           previewUrl={videoPicked?.url}
                           onVideoPicked={setVideoPicked}
                        />
                        <Spacer bottom={40} />
                        <Button
                           type="submit"
                           label={t({ id: 'Continuar' }).toString()}
                           full
                           disabled={!isValid || !videoPicked || !imgPicked}
                        />
                     </Form>
                  );
               }}
            </Formik>
         </ContainerModal>
      </CustomAppModal>
   );
};

const Divider = styled.div(
   ({ theme }) => css`
      margin-top: 8px;
      margin-right: ${theme.spacing.sm};
      height: 58.38px;
      display: flex;
      align-items: center;
      justify-content: center;
   `,
);
const DivFlex = styled.div`
   display: flex;
   align-items: flex-start;
`;
const SearchContainer = styled.div(
   ({ theme }) => css`
      label {
         font-family: ${theme.text.text1.family};
         font-weight: ${theme.text.text1.weight};
         color: ${theme.colors.input};
      }
   `,
);
const SearchButton = styled.div<{ disabled: boolean }>(
   ({ theme, disabled }) => css`
      width: 60px;
      height: 58.38px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 19px;
      margin-top: 8px;
      margin-left: -10px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      background-color: ${theme.colors.brand.secondary.main};
      color: ${theme.neutral.white};

      cursor: ${disabled ? 'no-drop' : 'pointer'};
   `,
);

const CustomAppModal = styled.div<ModalProps>(
   ({ isVisible }) => css`
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1001;
      width: 100%;
      background-color: #6c6f77a2;

      opacity: ${isVisible ? 1 : 0};
      pointer-events: ${isVisible ? 'auto' : 'none'};
      transition: all ease 0.2s;
   `,
);

const H1 = styled.div(
   ({ theme }) => css`
      font-family: ${theme.text.heading1.family};
      font-weight: ${theme.text.heading1.weight};
      font-size: ${theme.text.heading1.size};
      color: ${theme.neutral.main};
   `,
);
const Title = styled.div(
   ({ theme }) => css`
      font-family: ${theme.text.title.family};
      font-weight: ${theme.text.title.weight};
      font-size: ${theme.text.title.size};
      color: ${theme.neutral.main};
   `,
);
const ContainerModal = styled.div(
   ({ theme }) => css`
      position: relative;
      border-radius: ${theme.spacing.xs};
      background-color: ${theme.neutral.white};
      max-width: 530px;
      margin: ${theme.spacing.xl} auto;
      padding: ${theme.spacing.xl};
   `,
);

const ModalCloseButton = styled.button`
   width: 30px;
   height: 30px;

   position: absolute;
   right: -50px;
   top: 0;
   z-index: 1010;

   background: none;
   background-size: 23px;
   background-repeat: no-repeat;
   background-image: url('/assets/images/icons/checkboxFalseInverse.svg');
   background-position: center;

   @media screen and (max-width: 590px) {
      position: absolute;
      right: 5px;
      top: 50px;
      background-image: url('/assets/images/icons/checkboxFalseInverse.svg');

      filter: invert(1);
   }
`;

export default AttachChildModal;
