import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from 'services/api'

type RequestParams = {
   token: string
   photo_b64: string
   id: string
}

export const chooseNewPhotoAgain = createAsyncThunk(
   'chooseNewPhotoAgain',
   async ({ token, photo_b64, id }: RequestParams) => {
      return await api
         .put(`change/donor-image/${id}/${token}`, {
            token: token,
            photo_b64: photo_b64,
         })
         .then((res) => res.data)
   },
)

const chooseNewPhotoAgainSlice = createSlice({
   name: 'chooseNewPhotoAgain',
   initialState: {
      status: 'idle',
      token: '',
      photo_b64: '',
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(chooseNewPhotoAgain.pending, (state) => {
         state.status = 'loading'
      })
      builder.addCase(chooseNewPhotoAgain.fulfilled, (state) => {
         state.status = 'ok'
      })
      builder.addCase(chooseNewPhotoAgain.rejected, (state) => {
         state.status = 'rejected'
      })
   },
})

export default chooseNewPhotoAgainSlice.reducer
