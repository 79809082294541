import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import api from 'services/api'

interface listPackagesInterface {
   status: string
   filters: {
      package_status: {
         accomplished: string
         all: string
         pending: string
         scheduled: string
      }
   }
   paginate: {
      has_more: boolean
      page: number
      total: number
   }
   results: [
      {
         child_quantity: string
         event_date: null
         package_id: number
         status: string
         title: string
      },
   ]
}

interface listPackagesRequest {
   pg: number
   pp: number
   term?: string
   status: string
}

export const listPackages = createAsyncThunk(
   'listPackages',
   async ({ pg, pp, term, status }: listPackagesRequest) => {
      return await api
         .get(
            `/package?status=${status ? status : 'all'}&pp=${pp}&pg=${pg}${
               term ? `&term=${term}` : ''
            }`,
         )
         .then((res) => res.data)
   },
)

const listPackagesSlice = createSlice({
   name: 'listPackages',
   initialState: {
      status: 'idle',
      filters: {
         package_status: {
            accomplished: '',
            all: '',
            pending: '',
            scheduled: '',
         },
      },
      paginate: {
         has_more: false,
         page: 0,
         total: 0,
      },
      results: [],
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(listPackages.pending, (state) => {
         state.status = 'loading'
      })
      builder.addCase(
         listPackages.fulfilled,
         (state, action: PayloadAction<listPackagesInterface>) => {
            state.status = 'ok'
            state.results = action.payload.results
            state.paginate = action.payload.paginate
         },
      ),
         builder.addCase(listPackages.rejected, (state) => {
            state.status = 'rejected'
         })
   },
})

export default listPackagesSlice.reducer
