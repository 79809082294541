import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import FilterSelect from 'components/FilterSelect'
import ListInfo from 'components/ListInfo'
import { useFormatMessage } from 'react-intl-hooks'
import theme from '../styles/theme'
import { listPackages } from 'services/slices/listPackages'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'services/store'
import ReactLoading from 'react-loading'
import debounce from 'lodash.debounce'
import Pagination from 'components/Pagination'
import Container from 'components/Container'
import NoResult from 'components/NoResult'
import useRegionalization from 'hooks/useRegionalization'

const Packages = (): JSX.Element => {
   const t = useFormatMessage()
   const dispatch = useDispatch()
   const {country} = useRegionalization()
   const { result, loadingStatus, paginate } = useSelector(
      (state: RootState) => ({
         result: state.listPackages.results,
         loadingStatus: state.listPackages.status,
         paginate: state.listPackages.paginate,
      }),
   )

   const [status, setStatus] = useState<string>('')
   const [term, setTerm] = useState<string>('')
   const [pg, setPg] = useState<number>(1)
   const [pp] = useState<number>(country === 'mx' ? 9 : 15)
   const [lastPage, setLastPage] = useState<number>(0)

   useEffect(() => {
      dispatch(
         listPackages({
            status: status,
            pg: pg,
            pp: pp,
            term: term,
         }),
      )
   }, [status, term, pg])

   useEffect(() => {
      setLastPage(/*NUMBERS OF PAGES */ Math.ceil(paginate.total / pp))
   }, [paginate])

   const cleanFilters = () => {
      setStatus('all')
      setTerm('')
   }

   const searchFilter = debounce((event) => {
      if (event.target.value.length > 2 || event.target.value.length == 0) {
         setTerm(event.target.value)
      }
   }, 500)

   return (
      <Container>
         <Content>
            <H1>{t({ id: 'Pacotes' })}</H1>

            <Input
               type="email"
               name="user"
               placeholder={t({ id: 'Pesquise por nome do pacote' }).toString()}
               onChange={(event) => searchFilter(event)}
            />

            <DivFlex>
               <FilterSelect
                  value={status}
                  onChangeValue={(optionValue) => setStatus(optionValue.value)}
                  label={t({ id: 'Status' }).toString()}
                  options={[
                     { label: t({ id: 'Todos' }).toString(), value: 'all' },
                     {
                        label: t({ id: 'Pendente' }).toString(),
                        value: 'pending',
                     },
                     {
                        label: t({ id: 'Agendado' }).toString(),
                        value: 'scheduled',
                     },
                     {
                        label: t({ id: 'Realizado' }).toString(),
                        value: 'accomplished',
                     },
                  ]}
               />

               <LineVertical />

               <Filter onClick={cleanFilters}>
                  {t({ id: 'Limpar' })}
                  <img src={`${theme.pathIcons}/close.svg`} />
               </Filter>
            </DivFlex>

            <LineHorizontal />

            <List>
               <DivGrid>
                  <TitleTable>{t({ id: 'Pacote' })}</TitleTable>
                  <TitleTable>{t({ id: 'Vagas' })}</TitleTable>
                  <TitleTable>{t({ id: 'Status' })}</TitleTable>
                  <TitleTable>{t({ id: 'Data Evento' })}</TitleTable>
               </DivGrid>

               {loadingStatus === 'ok' && <ListInfo list={result} />}
               {loadingStatus === 'loading' && (
                  <LoadingContainer>
                     <ReactLoading
                        type="spin"
                        color={theme.colors.brand.primary.main}
                     />
                  </LoadingContainer>
               )}
               {loadingStatus === 'ok' && result.length === 0 && (
                  <NoResult label={t({ id: 'Nenhum resultado' }).toString()} />
               )}
            </List>

            {loadingStatus === 'ok' && Math.ceil(paginate.total / pp) > 1 && (
               <Pagination
                  pg={pg}
                  setPg={setPg}
                  lastPage={lastPage}
                  total={paginate.total}
               />
            )}
         </Content>
      </Container>
   )
}

const LoadingContainer = styled.div`
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
`

const Content = styled.div(
   ({ theme }) => css`
      min-height: 100vh;
      padding-top: 56px;
      flex-direction: column;
      align-items: center;
      display: flex;
      margin-bottom: ${theme.spacing.xl};
   `,
)

const H1 = styled.h1(
   ({ theme }) => css`
      padding-bottom: ${theme.spacing.xl};
      font-size: ${theme.text.heading1.size};
      color: ${theme.neutral.main};
      font-family: ${theme.text.heading1.family};
      font-weight: ${theme.text.heading1.weight};
   `,
)

const Input = styled.input(
   ({ theme }) => css`
      border: 1.2px solid ${theme.neutral.lightest};
      margin: ${theme.spacing.xs} 0 ${theme.spacing.md};
      padding: ${theme.spacing.sm} ${theme.spacing.md};
      border-radius: ${theme.spacing.xs};
      display: block;
      width: 100%;

      line-height: ${theme.text.text1.lineHeight};
      font-family: ${theme.text.text1.family};
      font-size: ${theme.text.text1.size};
      font-weight: ${theme.text.text1.weight};

      :focus {
         outline-color: ${theme.colors.brand.secondary.main};
      }
   `,
)

const LineVertical = styled.div(
   ({ theme }) => css`
      background-color: ${theme.neutral.lightest};
      margin-right: ${theme.spacing.lg};
      height: 32px;
      width: 2px;
   `,
)

const LineHorizontal = styled.div(
   ({ theme }) => css`
      background-color: ${theme.neutral.lightest};
      margin: ${theme.spacing.xl} 0;
      width: 100%;
      height: 2px;
   `,
)

const Filter = styled.button(
   ({ theme }) => css`
      display: flex;
      align-items: center;
      font-family: ${theme.text.title.family};
      font-size: ${theme.text.title.size};
      line-height: ${theme.text.title.lineHeight};
      font-weight: ${theme.text.title.weight};
      color: ${theme.neutral.lightness};
      background: transparent;

      :nth-last-child(n + 2) {
         padding-right: ${theme.spacing.lg};
      }

      > span {
         color: ${theme.colors.brand.secondary.main};
         padding: 0 ${theme.spacing.xs};
      }
   `,
)

const DivGrid = styled.div(
   ({ theme }) => css`
      grid-template-columns: 4fr 1fr 1.5fr 2.5fr 1fr;
      margin-bottom: ${theme.spacing.md};
      padding: ${theme.spacing.sm} ${theme.spacing.md} 4px;
      width: 100%;
      grid-gap: 20px;
      align-items: center;
      display: grid;
   `,
)

const TitleTable = styled.p(
   ({ theme }) => css`
      line-height: ${theme.text.text1.lineHeight};
      font-family: ${theme.text.text1.family};
      font-size: ${theme.text.text1.size};
      font-weight: ${theme.text.text1.weight};
      color: ${theme.neutral.lightness};
      min-width: 100px;
   `,
)

const List = styled.div(
   ({ theme }) => css`
      width: 100%;
      > :last-child {
         margin-bottom: ${theme.spacing.xl};
      }
   `,
)

const DivFlex = styled.div`
   display: flex;
`

export default Packages
