import { GenericObject } from '@types'
import constate from 'constate'
import { useState } from 'react'

interface CheckoutForm {
   name?: string
   document?: string
   email?: string
   phone?: {
      country?: string
      value?: string
   }
   ocupation?: string
   birth_date?: string
   gender?: string
   address_street?: string
   address_number?: string
   address_complement?: string
   address_zipcode?: string
   address_neightborhood?: string
   address_city?: string
   address_state?: string
   first_name?: string
   last_name?: string
   photo?: {
      url?: string
   }
   card?: {
      number?: string
      exp_month?: string
      exp_year?: string
      cvc?: string
      holder_name?: string
   }
}

const [CheckoutFormProvider, useCheckoutForm] = constate(() => {
   const [checkoutStep, setCheckoutStep] = useState<number>(0)
   const [checkoutFormData, setCheckoutFormData] = useState<CheckoutForm>({})

   const addCheckoutFormData = (formData: GenericObject) => {
      setCheckoutFormData((_formData) => ({ ..._formData, ...formData }))
   }

   return {
      checkoutStep,
      setCheckoutStep,

      checkoutFormData,
      setCheckoutFormData,

      addCheckoutFormData,
   }
})

export { CheckoutFormProvider }

export default useCheckoutForm
