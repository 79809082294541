import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from 'services/api'

export const recoveryPassword = createAsyncThunk(
   'recoveryPassword',
   async ({ email }: { email: string }) => {
      return await api
         .post('/auth/forgot-password', {
            email: email,
         })
         .then((res) => res.data)
   },
)

const recoveryPasswordSlice = createSlice({
   name: 'recoveryPassword',
   initialState: {
      status: 'idle',
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(recoveryPassword.pending, (state, action) => {
         state.status = 'loading'
      })
      builder.addCase(recoveryPassword.fulfilled, (state, action) => {
         state.status = 'ok'
      }),
         builder.addCase(recoveryPassword.rejected, (state, action) => {
            state.status = 'rejected'
         })
   },
})

export default recoveryPasswordSlice.reducer
