import Footer from 'components/Footer'
import routes from '../routes/routes.json'
import { Fragment, useEffect, useState } from 'react'
import { useFormatMessage } from 'react-intl-hooks'
import { Link, useHistory, useParams } from 'react-router-dom'

import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'services/store'
import { newPassword } from 'services/slices/newPassword'
import { toast } from 'react-toastify'

const RecoveryPasswordNewPassword = (): JSX.Element => {
   const t = useFormatMessage()
   const dispatch = useDispatch()
   const history = useHistory()
   const [sendedRequest, setSendedRequest] = useState<boolean>(false)
   const [email, setEmail] = useState<string>('')
   const [password, setPassword] = useState<string>('')
   const [passwordConfirm, setPasswordConfirm] = useState<string>('')

   const { token } = useParams<{ token: string }>()

   const recoveryPasswordFunction = async (e) => {
      e.preventDefault()
      if (password === passwordConfirm) {
         toast.dismiss()
         dispatch(
            newPassword({
               email: email,
               token: token,
               new_password: password,
               new_password_confirmation: passwordConfirm,
            }),
         )
         setSendedRequest(true)
      } else {
         toast.error(t({ id: 'As senhas não conferem' }))
      }
   }

   const { loadingStatus } = useSelector((state: RootState) => ({
      loadingStatus: state.newPassword.status,
   }))

   useEffect(() => {
      if (loadingStatus === 'ok') {
         history.push(routes.login)
         setSendedRequest(false)
      } else if (loadingStatus === 'rejected') {
         setSendedRequest(false)
         toast.error(t({ id: 'Email ou token inválidos' }))
      }
   }, [loadingStatus])

   return (
      <Fragment>
         <Content>
            <H1>{t({ id: 'Nova Senha' })}</H1>
            <Form onSubmit={recoveryPasswordFunction}>
               <Label>
                  {t({ id: 'Email' })}
                  <Input
                     type="email"
                     name="user"
                     placeholder="Digite seu email aqui"
                     onChange={(event) => setEmail(event.target.value)}
                     required
                  />
               </Label>

               <Label>
                  {t({ id: 'Nova Senha' })}
                  <Input
                     type="password"
                     name="password"
                     placeholder="Digite a nova senha"
                     onChange={(event) => setPassword(event.target.value)}
                     required
                  />
               </Label>

               <Label>
                  {t({ id: 'Confirme a nova senha' })}
                  <Input
                     type="password"
                     name="passwordConfirm"
                     placeholder="Digite a nova senha novamente"
                     onChange={(event) =>
                        setPasswordConfirm(event.target.value)
                     }
                     required
                  />
               </Label>

               <Submit disabled={sendedRequest} type="submit">
                  {t({ id: 'Criar nova senha' })}
               </Submit>
            </Form>
         </Content>

         <Footer />
      </Fragment>
   )
}

const Content = styled.div(
   ({ theme }) => css`
      flex-direction: column;
      align-items: center;
      padding-top: ${theme.spacing.xl};
      min-height: 80vh;
      display: flex;
   `,
)

const H1 = styled.h1(
   ({ theme }) => css`
      padding-bottom: ${theme.spacing.xl};
      font-size: ${theme.text.heading1.size};
      color: ${theme.neutral.main};
      font-family: ${theme.text.heading1.family};
      font-weight: ${theme.text.heading1.weight};
   `,
)

const Form = styled.form(
   ({ theme }) => css`
      box-shadow: '0px 1px 0px ${theme.neutral.lightest}';
      background: ${theme.neutral.white};
      border-radius: ${theme.spacing.xs};
      padding: ${theme.spacing.xl};
   `,
)

const Submit = styled.button<{ disabled: boolean }>(
   ({ theme }) => css`
      padding: ${theme.spacing.sm} ${theme.spacing.md};
      font-family: ${theme.text.title.family};
      border-radius: ${theme.spacing.xxl};
      background: ${theme.colors.brand.primary.main};
      color: ${theme.neutral.white};
      font-weight: ${theme.text.title.weight};
      border: none;
      width: 450px;
   `,
)

const Input = styled.input(
   ({ theme }) => css`
      border: 1.2px solid ${theme.neutral.lightest};
      margin: ${theme.spacing.xs} 0 ${theme.spacing.md};
      padding: ${theme.spacing.sm} ${theme.spacing.md};
      border-radius: ${theme.spacing.xs};
      display: block;
      width: calc(450px);

      line-height: ${theme.text.text1.lineHeight};
      font-family: ${theme.text.text1.family};
      font-size: ${theme.text.text1.size};
      font-weight: ${theme.text.text1.weight};

      :focus {
         outline-color: ${theme.colors.brand.secondary.main};
      }
   `,
)

const Label = styled.label(
   ({ theme }) => css`
      line-height: ${theme.text.text1.lineHeight};
      font-family: ${theme.text.text1.family};
      font-size: ${theme.text.text1.size};
      color: ${theme.colors.input};
      font-weight: ${theme.text.text1.weight}; ;
   `,
)

const Subtitle = styled.span(
   ({ theme }) => css`
      margin-bottom: ${theme.spacing.xl};
      font-family: ${theme.text.text1.family};
      line-height: ${theme.text.text1.lineHeight};
      max-width: 407px;
      text-align: center;
      color: ${theme.colors.input};
   `,
)

export default RecoveryPasswordNewPassword
