import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'services/api';

export type DonorDetails = {
   first_name: string;
   last_name: string;
   identificationNumber: string;
   identificationType: string;
   email: string;
   phone: {
      country: string;
      value: string;
   };
   birthdate: string;
   gender: string;
   address_street: string;
   address_city: string;
   referral_code?: string;
   simma_cityCode?: string;
   validation_status?: string;
   simma_docTypeCode?: string;
   registerInSimma?: boolean;
   approvedPhotography?: boolean;
   date: string;
};

/**
 * se eliminan del objeto las propiedades que no aplican para el modelo de colombia
 */

type DonorResponse = {
   donor: {
      id: string;
   };
};

/**
 * 1)Registra el elegido en la bd y retorna un id asignado
 * 2)la accion del case fulfilled actualizan el id y el estado
 * 2.1)las otras accines solo actualizan el estado
 * 3)Asigana un al store newDonor un status y el id retornado
 */
export const newDonor = createAsyncThunk(
   'donor',
   async (data: DonorDetails) => {
      return await api.post('chosenRegister', data).then((res) => res.data);
   },
);

const newDonorSlice = createSlice({
   name: 'donor',
   initialState: {
      status: 'idle',
      id: null,
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(newDonor.pending, (state) => {
         state.status = 'loading';
      }),
         builder.addCase(
            newDonor.fulfilled,
            (state, action: PayloadAction<DonorResponse>) => {
               state.status = 'ok';
               state.id = action.payload.donor.id;
            },
         ),
         builder.addCase(newDonor.rejected, (state) => {
            state.status = 'rejected';
            state.id = '';
         });
   },
});

export default newDonorSlice.reducer;
