import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'services/api';

interface RevelationByEmail {
   message?: string;
   error?: string;
}

export const RevelationByEmail = createAsyncThunk(
   'RevelationByEmail',
   async ({ donor_id, child_id }: { donor_id: String; child_id: string }) => {
      return await api
         .get(`revelations/send-email/donor/${donor_id}/child/${child_id}`)
         .then((res) => res.data);
   },
);

export const RevelationByEmailSlice = createSlice({
   name: 'RevelationByEmail',
   initialState: {
      status: 'idle',
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(RevelationByEmail.pending, (state) => {
         state.status = 'loading';
      });
      builder.addCase(RevelationByEmail.fulfilled, (state) => {
         // eslint-disable-next-line @typescript-eslint/no-extra-semi
         state.status = 'ok';
      });
      builder.addCase(RevelationByEmail.rejected, (state) => {
         state.status = 'rejected';
      });
   },
});

export default RevelationByEmailSlice.reducer;
