import AppModal from 'components/AppModal'

import styled, { css } from 'styled-components/macro'
import Spacer from 'components/Spacer'

type VideoChosenChildModal = {
   onClose: () => void
   src?: string
}
const VideoChosenChild = ({
   onClose,
   src,
}: VideoChosenChildModal): JSX.Element => {
   return (
      <>
         <AppModalCustom>
            <Spacer top={100} />
            <ContainerModal>
               <ModalCloseButton type="button" onClick={() => onClose()} />

               <VideofromChild>
                  <iframe
                     width="100%"
                     height="100%"
                     src={src}
                     title="YouTube video player"
                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
               </VideofromChild>
            </ContainerModal>
         </AppModalCustom>
      </>
   )
}

export default VideoChosenChild

const AppModalCustom = styled.div(
   ({ theme }) => css`
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1001;
      width: 100%;
      height: 100%;
      background-color: #6c6f77a2;
   `,
)

const VideofromChild = styled.div(
   ({ theme }) => css`
      width: 100%;
      height: 720px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
   `,
)
const ContainerModal = styled.div(
   ({ theme }) => css`
      max-width: 990px;
      border-radius: ${theme.spacing.xxs};
      background-color: ${theme.neutral.white};
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      position: relative;

      @media screen and (max-width: 940px) {
         height: 100vh;
         background-color: #6c6f77;
      }
   `,
)
const ModalCloseButton = styled.button`
   width: 30px;
   height: 30px;

   position: absolute;
   right: -62px;
   top: 5px;
   z-index: 1010;

   background: none;
   background-size: 23px;
   background-repeat: no-repeat;
   background-image: url('/assets/images/icons/checkboxFalseInverse.svg');
   background-position: center;
   @media screen and (max-width: 1470px) {
      top: 20px;
      position: absolute;
      right: 70px;
   }
   @media screen and (max-width: 1172px) {
      top: 20px;
      position: absolute;
      right: 2px;
   }
   @media screen and (max-width: 890px) {
      position: absolute;
      right: 5px;
      top: 20px;
      background-image: url('/assets/images/icons/checkboxFalseInverse.svg');
   }
`
