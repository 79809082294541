import { Fragment, ReactNode, useEffect, useMemo, useState } from 'react'
import { useFormatMessage } from 'react-intl-hooks'
import styled from 'styled-components'
import Spacer from './Spacer'

const Wrapper = styled.div`
   width: 100%;
   padding: 40px;
   border-radius: 8px;
   background-color: ${(props) => props.theme.neutral.white};
   box-shadow: 0px 1px 0px #e2e2ea;
`

const StepHeader = styled.div`
   display: flex;
   flex-direction: column;
   width: 100%;
`

const StepRow = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   width: 100%;
`

const StepInfo = styled.div`
   display: flex;
   flex: 1;
   flex-direction: row;
   align-items: center;
`

const StepNumber = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   width: 32px;
   height: 32px;
   border-radius: 50%;

   background-color: ${(props) => props.theme.colors.brand.primary.main};

   font-family: ${(props) => props.theme.text.title.family};
   font-size: ${(props) => props.theme.text.title.size};
   font-weight: ${(props) => props.theme.text.title.weight};
   line-height: ${(props) => props.theme.text.title.lineHeight};

   color: ${(props) => props.theme.neutral.white};
`

const StepTitle = styled.p`
   font-family: ${(props) => props.theme.text.title.family};
   font-size: ${(props) => props.theme.text.title.size};
   font-weight: ${(props) => props.theme.text.title.weight};
   line-height: ${(props) => props.theme.text.title.lineHeight};

   color: ${(props) => props.theme.neutral.main};

   margin: 0;
`

const StepPosition = styled.p`
   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   color: ${(props) => props.theme.neutral.sub};

   margin: 0;
`

const StepProgress = styled.div<{ completed: boolean }>`
   display: flex;
   flex: 1;
   height: 3px;

   background-color: ${(props) =>
      props.completed
         ? props.theme.colors.brand.secondary.main
         : props.theme.neutral.lightest};
`

export type Step = {
   title: string
   component: ReactNode
}

type StepsProps = {
   steps: Step[]
   activeStep?: number
   onStepChange?: (step: Step, index: number) => void
}

const Steps = ({
   steps,
   activeStep,
   onStepChange,
}: StepsProps): JSX.Element => {
   const [stepIndex, setStepIndex] = useState<number>(activeStep ?? 0)
   const t = useFormatMessage()
   useEffect(() => {
      if (activeStep >= 0) setStepIndex(activeStep)
   }, [activeStep])

   useEffect(() => {
      if (onStepChange) onStepChange(steps[stepIndex], stepIndex)
   }, [stepIndex])

   const renderStepProgress = (step: Step, index: number) => {
      const completed = index <= stepIndex
      return (
         <Fragment key={index}>
            {index !== 0 && <Spacer left={9} />}
            <StepProgress completed={completed} />
         </Fragment>
      )
   }

   const currentStep = useMemo(() => steps[stepIndex], [steps, stepIndex])

   return (
      <Fragment>
         <Wrapper>
            <StepHeader>
               <StepRow>
                  <StepInfo>
                     <StepNumber>
                        <span>{stepIndex + 1}</span>
                     </StepNumber>
                     <Spacer right={16} />
                     <StepTitle>
                        {steps[stepIndex] ? steps[stepIndex].title : ''}
                     </StepTitle>
                  </StepInfo>

                  <StepPosition>
                     {t({ id: 'Etapa' })} {stepIndex + 1} {t({ id: 'de' })}{' '}
                     {steps.length}
                  </StepPosition>
               </StepRow>

               <Spacer bottom={21} />

               <StepRow>{steps.map(renderStepProgress)}</StepRow>
            </StepHeader>
            <Spacer bottom={40} />

            {currentStep && currentStep.component}
         </Wrapper>

         <Spacer bottom={40} />
      </Fragment>
   )
}

export default Steps
