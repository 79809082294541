import ProtectedRoute from 'components/ProtectedRoute'
import ScrollToTop from 'components/scrollToTop'
import LandingPage from 'pages/LandingPage'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import storage from 'services/storage'
import AppRouter from './AppRouter'
import AuthRouter from './AuthRouter'

const Router = (): JSX.Element => {
   const isAuthenticated = () => {
      const token = storage.getToken()
      //const user = storage.getUser()

      if (token && token != null) return true
      else return false
   }

   return (
      <BrowserRouter>
         <ToastContainer />
         <ScrollToTop>
            <Switch>
               <Route exact path="/" component={LandingPage} />

               <ProtectedRoute
                  path="/app"
                  component={AppRouter}
                  canAccess={isAuthenticated()}
                  redirectTo="/login"
               />

               <ProtectedRoute
                  component={AuthRouter}
                  canAccess={!isAuthenticated()}
                  redirectTo="/app/approvals"
               />
            </Switch>
         </ScrollToTop>
      </BrowserRouter>
   )
}

export default Router
