import { useFormatMessage } from 'react-intl-hooks'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import routes from '../routes/routes.json'
import theme from '../styles/theme'
import BadgeStatus from './BadgeStatus'

type Item = {
   child_quantity: string
   event_date: null | Date
   package_id: number
   status: string
   title: string
}

interface ListInfoInterface {
   list: Item[]
}

const ListInfo = ({ list }: ListInfoInterface) => {
   const t = useFormatMessage()

   return (
      <>
         {list.map((item, index) => (
            <ItemList key={index}>
               <TextTableBold>{item.title}</TextTableBold>
               <DivFlex>
                  <img src={`${theme.pathIcons}/user.svg`} />
                  <TextTable>{item.child_quantity}/15</TextTable>
               </DivFlex>

               <TextTable>
                  <BadgeStatus status={item.status} />
                  {t({ id: item.status })}
               </TextTable>

               <DivFlex>
                  <img src={`${theme.pathIcons}/calendar.svg`} />
                  <TextTable>
                     {item.event_date
                        ? new Date(item.event_date)
                             .toLocaleString()
                             .substr(0, 16)
                        : '-'}
                  </TextTable>
               </DivFlex>

               <ButtonContainer>
                  <ButtonList
                     to={`${routes.packageDetails.replace(
                        ':packageID',
                        item.package_id.toString(),
                     )}`}
                  >
                     {t({ id: 'Abrir' })}
                  </ButtonList>
               </ButtonContainer>
            </ItemList>
         ))}
      </>
   )
}

export default ListInfo

const DivGrid = styled.div(
   ({ theme }) => css`
      grid-template-columns: 4fr 1fr 1.5fr 2.5fr 1fr;
      margin-bottom: ${theme.spacing.md};
      padding: 28px 24px;
      width: 100%;
      grid-gap: 20px;
      align-items: center;
      display: grid;
   `,
)

const ItemList = styled(DivGrid)(
   ({ theme }) => css`
      background-color: ${theme.neutral.white};
      margin-bottom: ${theme.spacing.xs};
      box-shadow: 0px 1px 0px ${theme.neutral.lightest};
      border-radius: 8px;
      border: 1px solid transparent;
      transition: border ease 0.3s;
      will-change: border;

      &:hover {
         border: 1px solid ${theme.colors.brand.secondary.main};
      }
   `,
)

const TitleTable = styled.p(
   ({ theme }) => css`
      line-height: ${theme.text.text1.lineHeight};
      font-family: ${theme.text.text1.family};
      font-size: ${theme.text.text1.size};
      font-weight: ${theme.text.text1.weight};
      color: ${theme.neutral.lightness};
   `,
)

const TextTableBold = styled(TitleTable)(
   ({ theme }) => css`
      color: ${theme.neutral.main};
      font-weight: ${theme.text.title.weight};
   `,
)

const TextTable = styled(TitleTable)(
   ({ theme }) => css`
      color: ${theme.neutral.main};
   `,
)

const DivFlex = styled.div(
   ({ theme }) => css`
      display: flex;

      > img {
         margin-right: ${theme.spacing.sm};
      }
   `,
)

const ButtonContainer = styled.div`
   text-align: right;
`

const ButtonList = styled(Link)(
   ({ theme }) => css`
      background: rgba(255, 95, 0, 0.08);
      padding: 10px 16px;
      font-family: ${theme.text.heading2.family};
      color: ${theme.colors.brand.primary.main};
      font-weight: ${theme.text.title.weight};
      font-size: ${theme.text.title.size};
      border-radius: ${theme.spacing.xxl};
      text-decoration: none;
      transition: all ease 0.3s;

      &:hover {
         color: ${theme.neutral.white};
         background: ${theme.colors.brand.primary.main};
      }
   `,
)
