import { useFormatMessage } from 'react-intl-hooks'
import styled from 'styled-components'
import Spacer from './Spacer'

type InstructionsProps = {
   instructions: string[]
}

const Instructions = ({ instructions }: InstructionsProps): JSX.Element => {
   const t = useFormatMessage()
   const renderInstruction = (instruction: string, index: number) => {
      return (
         <LineWrapper>
            {index > 0 && <Spacer top={16} />}
            <Line key={index}>
               <InstructionIcon className="fas fa-arrow-right" />
               <Spacer right={16} />
               <Instruction>{t({ id: instruction })}</Instruction>
            </Line>
         </LineWrapper>
      )
   }

   return <Wrapper>{instructions.map(renderInstruction)}</Wrapper>
}

export default Instructions

const Wrapper = styled.div`
   display: flex;
   flex-direction: column;
   padding-right: 78px;
`

const LineWrapper = styled.div`
   display: flex;
   flex-direction: column;
`

const Line = styled.div`
   display: flex;
   flex-direction: row;
   align-items: flex-start;
`

const InstructionIcon = styled.i`
   font-size: 24px;
   width: 24px;
   height: 24px;
   color: ${(props) => props.theme.colors.brand.secondary.main};
   font-weight: 400;
`

const Instruction = styled.p`
   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   color: ${(props) => props.theme.neutral.sub};

   margin: 0;
`
