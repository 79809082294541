import useRegionalization from 'hooks/useRegionalization'
import Checkout from 'pages/Checkout'
import ChoosePhotoAgain from 'pages/ChoosePhotoAgain'
import ChosenRevelation from 'pages/ChosenRevelation'
import Login from 'pages/Login'
import PhotoUpdatedSucessfully from 'pages/PhotoUpdatedSuccessfully'
import RecoveryPassword from 'pages/RecoveryPassword'
import RecoveryPasswordConfirmation from 'pages/RecoveryPasswordConfirmation'
import RecoveryPasswordNewPassword from 'pages/RecoveryPasswordNewPassword'
import Register from 'pages/Register'
import Thanks from 'pages/Thanks'
import { Fragment } from 'react'
import { Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import Terms from '../pages/Terms'
import ThanksUps from '../pages/ThanksUps';

const Header = styled.header`
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 16px 16px 24px 16px;
   background: #ffffff;
   box-shadow: inset 0px -1px 0px #e2e2ea;
`

const HeaderLogo = styled.img`
   max-width: 146px;
   width: 100%;
   object-fit: contain;
`

const AuthRouter = (): JSX.Element => {
   const { path } = useRouteMatch()
   const { country } = useRegionalization()
   return (
      <Fragment>
         <Header>
            <Link to="/">
               {country === 'mx' ? (
                  <HeaderLogo src="/assets/VMB_Logo.png" />
               ) : (
                  <HeaderLogo src="/assets/vmb.png" />
               )}
            </Link>
         </Header>

         <Switch>
            <Route exact path={`/login`} component={Login} />

            <Route exact path={`/register`} component={Register} />

            <Route exact path={`/checkout`} component={Checkout} />

            <Route path={`/checkout/:referredID`} component={Checkout} />

            <Route exact path={`/thanks`} component={Thanks} />

            <Route exact path={`/thanksUps`} component={ThanksUps} />

            <Route exact path={`/terms`} component={Terms} />

            <Route path={`/mural-crianca`} component={ChosenRevelation} />

            <Route
               exact
               path={`/donors/change-photo/:id/:token`}
               component={ChoosePhotoAgain}
            />

            <Route
               exact
               path={`/photo/updated`}
               component={PhotoUpdatedSucessfully}
            />

            <Route exact path={`/recovery`} component={RecoveryPassword} />

            <Route
               exact
               path={`/recovery/confirmation`}
               component={RecoveryPasswordConfirmation}
            />

            <Route
               exact
               path={`/reset-password/:token`}
               component={RecoveryPasswordNewPassword}
            />

            <Redirect from="*" to="/" />
         </Switch>
      </Fragment>
   )
}

export default AuthRouter
