import styled from 'styled-components'
import hexToRgba from 'utils/hexToRgba'

const Card = styled.div`
   padding: 24px 36px;
   background: ${(props) => props.theme.neutral.white};
   border: 1.2px solid ${(props) => props.theme.neutral.lightest};
   border-radius: 8px;

   cursor: pointer;
`

export const BlueCard = styled.div`
   padding: 24px;
   background: ${(props) =>
      hexToRgba(props.theme.colors.brand.secondary.main, 0.08)};
      
   border: 1px solid ${(props) => props.theme.colors.brand.secondary.main};
   border-radius: 8px;
`

export default Card
