import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'services/api';

type InfoDonor = {
   id: string;
};

export const deleteDonor = createAsyncThunk(
   'DeleteDonor',
   async ({ id }: InfoDonor) => {
      return await api.delete(`/chosenDelete/${id}`).then((res) => res.data);
   },
);

const deleteDonorSlice = createSlice({
   name: 'DeleteDonor',
   initialState: {
      status: 'idle',
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(deleteDonor.pending, (state) => {
         state.status = 'loading';
      });
      builder.addCase(deleteDonor.fulfilled, (state) => {
         state.status = 'ok';
      });
      builder.addCase(deleteDonor.rejected, (state) => {
         state.status = 'rejected';
      });
   },
});

export default deleteDonorSlice.reducer;
