import { ChangeEvent, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import hexToRgba from 'utils/hexToRgba';
import { BlueCard } from './Card';
import Spacer from './Spacer';
import { toast } from 'react-toastify';
import { useFormatMessage } from 'react-intl-hooks';

type ThumbnailProps = {
   url?: string;
};

const Wrapper = styled(BlueCard)<ThumbnailProps>`
   display: flex;
   flex: 1;
   flex-direction: column;
   width: 100%;
   padding: 57px 40px;
   justify-content: center;
   align-items: center;

   background: ${(props) =>
      props.url
         ? `url('${props.url}')`
         : hexToRgba(props.theme.colors.brand.secondary.main, 0.08)};
   background-size: cover;
   background-position: center;
`;

const Icon = styled.img<ThumbnailProps>`
   width: 80px;
   height: 80px;
   object-fit: contain;

   ${(props) =>
      props.url &&
      css`
         pointer-events: none;
         opacity: 0;
      `}
`;

const Limit = styled.p<ThumbnailProps>`
   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};
   line-height: ${(props) => props.theme.text.text1.lineHeight};
   text-align: center;

   color: ${(props) => props.theme.colors.brand.secondary.main};

   margin: 0;

   ${(props) =>
      props.url &&
      css`
         pointer-events: none;
         opacity: 0;
      `}
`;

const PickerButton = styled.button`
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   padding: 8px 24px;

   background: ${(props) => props.theme.colors.brand.secondary.main};
   border-radius: 90px;

   outline: none;
   border: none;
   cursor: pointer;
`;

const PickerButtonText = styled.p`
   font-family: ${(props) => props.theme.text.title.family};
   font-size: ${(props) => props.theme.text.title.size};
   font-weight: ${(props) => props.theme.text.title.weight};
   line-height: ${(props) => props.theme.text.title.lineHeight};

   color: ${(props) => props.theme.neutral.white};

   margin: 0;
`;

export type ImgPicked = {
   file: File;
   url: string;
};

type PhotoPickerProps = {
   limits: string[];
   previewUrl?: string;
   onImgPicked: (picker: ImgPicked) => void;
};

const PhotoPicker = ({
   limits,
   previewUrl,
   onImgPicked,
}: PhotoPickerProps): JSX.Element => {
   const t = useFormatMessage();
   const fileRef = useRef<HTMLInputElement>(null);

   const [preview, setPreview] = useState<string>('');

   const handleFileUpload = (ev: ChangeEvent<HTMLInputElement>) => {
      if (ev.target && ev.target.files) {
         const file = ev.target.files[0];

         if (file) {
            const reader = new FileReader();
            if (file.size > 10000000) {
               toast.warning(t({ id: 'Favor, foto até 10mb' }).toString());
               return;
            }
            reader.onload = (e) => {
               if (e.target && typeof e.target.result === 'string') {
                  setPreview(e.target.result);
                  onImgPicked({
                     file,
                     url: e.target.result,
                  });
               }
            };

            reader.readAsDataURL(file);
         }
      }
   };

   const forceOpenPicker = () => {
      if (fileRef.current) {
         fileRef.current.click();
      }
   };

   const getPreviewUrl = () => {
      if (preview) {
         return preview;
      }

      if (previewUrl) {
         return `${previewUrl}`;
      }

      return undefined;
   };

   const renderLimit = (limit: string) => {
      return <Limit url={getPreviewUrl()}>{t({ id: limit })}</Limit>;
   };

   return (
      <Wrapper url={getPreviewUrl()}>
         <Icon url={getPreviewUrl()} src="/assets/icons/image.png" />
         <Spacer bottom={16} />

         {limits.map(renderLimit)}
         <Spacer bottom={40} />

         <PickerButton>
            <PickerButtonText onClick={forceOpenPicker}>
               {getPreviewUrl()
                  ? t({ id: 'Selecionar outra foto' })
                  : t({ id: 'Selecionar foto' })}
            </PickerButtonText>

            <input
               style={{ display: 'none' }}
               type="file"
               id="file"
               ref={fileRef}
               onChange={handleFileUpload}
               accept="image/png, image/jpeg"
            />
         </PickerButton>
      </Wrapper>
   );
};

export default PhotoPicker;
