import Approvals from 'pages/Approvals';
import RevelationTemp from 'pages/RevelationTemp';
import Donors from 'pages/Donors';
import Home from 'pages/Home';
import Revelations from 'pages/Revelations';
import { Fragment } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Packages from 'pages/Packages';
import ChosenChild from 'pages/ChosenChild';
import AdminHeader from 'components/AdminHeader';
import RevelationDetails from 'pages/RevelationDetails';
import PackageDetails from 'pages/PackageDetails';
import DonorDetails from 'pages/DonorDetails';
import { useFormatList } from 'react-intl-hooks';
import Utils from 'pages/Utils';

const AppRouter = (): JSX.Element => {
   const { path } = useRouteMatch();

   return (
      <Fragment>
         <AdminHeader />
         <Switch>
            <Route exact path={`${path}/approvals`} component={Approvals} />

            <Route exact path={`${path}/packages`} component={Packages} />

            <Route
               exact
               path={`${path}/package/:packageID`}
               component={PackageDetails}
            />

            <Route
               exact
               path={`${path}/revelations`}
               component={RevelationTemp}
            />

            <Route
               exact
               path={`${path}/revelation/:RevelationID`}
               component={RevelationDetails}
            />

            <Route exact path={`${path}/donors`} component={Donors} />
            <Route exact path={`${path}/utils`} component={Utils} />
            <Route
               exact
               path={`${path}/donors/details/:donorID`}
               component={DonorDetails}
            />
            <Route
               exact
               path={`${path}/chosenChild/:childID`}
               component={ChosenChild}
            />

            <Redirect from="*" to={`${path}/approvals`} />
         </Switch>
      </Fragment>
   );
};

export default AppRouter;
