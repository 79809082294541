import { useMemo } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

type Props = RouteProps & {
   canAccess?: boolean
   canAccessFunction?: () => boolean
   redirectTo?: string
}

const ProtectedRoute = ({
   canAccess,
   canAccessFunction,
   redirectTo,
   ...props
}: Props): JSX.Element => {
   const canActive = (): boolean => {
      if (canAccess) {
         return canAccess
      }

      if (canAccessFunction) {
         return canAccessFunction()
      }

      return false
   }

   const redirectToPage = useMemo(() => {
      if (redirectTo) return redirectTo

      return '/'
   }, [redirectTo])

   return canActive() ? <Route {...props} /> : <Redirect to={redirectToPage} />
}

export default ProtectedRoute
