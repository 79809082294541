import Button, { BackButton, ButtonRow } from 'components/Button'
import Instructions from 'components/Instructions'
import PhotoPicker, { ImgPicked } from 'components/PhotoPicker'
import Spacer from 'components/Spacer'
import useRegionalization from 'hooks/useRegionalization'
import { Fragment, useState } from 'react'
import { useFormatMessage } from 'react-intl-hooks'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { newDonorPhoto } from 'services/slices/donorImage'
import { RootState } from 'services/store'
import styled from 'styled-components'

const Title = styled.p`
   font-family: ${(props) => props.theme.text.title.family};
   font-size: ${(props) => props.theme.text.title.size};
   font-weight: ${(props) => props.theme.text.title.weight};
   line-height: ${(props) => props.theme.text.title.lineHeight};

   color: ${(props) => props.theme.neutral.main};

   margin: 0;
`

const Subtitle = styled.p`
   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   color: ${(props) => props.theme.neutral.sub};

   margin: 0;
`

const Photos = styled.div`
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
`

const Photo = styled.img`
   flex: 1;
   max-width: 100px;
   width: 100%;
   object-fit: cover;
`

const instructions = [
   'Escolha uma foto bem iluminada, que se possa ver claramente o seu rosto',
   'Dê preferência àquela foto que mostre seu sorriso! Evite expor sua imagem ou cenários inadequados.',
   'A foto será impressa para as crianças lhe escolherem, portanto selecione a imagem mais adequada e de melhor qualidade possível',
]

const photos = [
   '/assets/photos/image_29.png',
   '/assets/photos/image_30.png',
   '/assets/photos/image_31.png',
]

const photosMx = [
   '/assets/photos/mom.jpg',
   '/assets/photos/family.jpg',
   '/assets/photos/dad.jpg',
   '/assets/photos/girl.jpg',
]

const photosLimits = [
   '*Selecione um arquivo com até 10MB',
   '*Formatos aceitos: JPG , JPEG e PNG',
]
type Values = {
   photo: ImgPicked
}

type CheckoutDataFormProps = {
   onBack: () => void
   onSubmit: (values: Values) => void
}

const CheckoutForms = ({
   onBack,
   onSubmit,
}: CheckoutDataFormProps): JSX.Element => {
   const [filePicked, setFilePicked] = useState<ImgPicked>()
   const [isLoading, setIsLoading] = useState<boolean>(false)
   const { country } = useRegionalization()

   const t = useFormatMessage()

   const dispatch = useDispatch()

   const { id } = useSelector((state: RootState) => ({
      id: state.newDonor.id,
   }))

   /**
    * la Funcion guarda la imagen en la bd como donor_photo_base64
    * @param photo no se esta usando esta interface de ImgPicked al realizar el registro en la bd
    */

   const handleSubmit = async (photo: ImgPicked) => {
      setIsLoading(true)

      const response: any = await dispatch(
         newDonorPhoto({ donor_photo_base64: photo.url, id: id }),
      )

      setIsLoading(false)

      if (newDonorPhoto.rejected.match(response)) {
         toast.error(t({ id: 'Tamanho inválido' }))
      }

      if (newDonorPhoto.fulfilled.match(response)) {
         if (filePicked) onSubmit({ photo: filePicked })
      }
   }

   const renderPhoto = (path: string, index: number) => {
      return <Photo key={index} src={path} />
   }

   return (
      <Fragment>
         <Title>
            {t({ id: 'Escolha ou tire uma foto e nos envie no botao abaixo' })}
         </Title>
         <Spacer bottom={16} />

         <Subtitle>
            {t({
               id: 'Essa sera a foto que as criancas utilizarao para escolher voce, entao capriche, hein',
            })}
         </Subtitle>
         <Spacer bottom={40} />

         <Instructions instructions={instructions} />
         <Spacer bottom={40} />

         <Title>{t({ id: 'Exemplos de fotos aprovadas' })}</Title>
         <Spacer bottom={16} />
         {country === 'mx' ? (
            <Photos>{photosMx.map(renderPhoto)}</Photos>
         ) : (
            <Photos>{photos.map(renderPhoto)}</Photos>
         )}

         <Spacer bottom={40} />

         <Title>{t({ id: 'Adicione sua foto' })}</Title>
         <Spacer bottom={28} />

         <PhotoPicker
            limits={photosLimits}
            previewUrl={filePicked?.url}
            onImgPicked={setFilePicked}
         />
         <Spacer bottom={40} />

         <ButtonRow>
            {/*<BackButton
               type="button"
               label={t({ id: 'Voltar' }).toString()}
               onClick={onBack}
            />*/}

            <Button
               type="button"
               label={t({ id: 'Continuar' }).toString()}
               disabled={filePicked === undefined || isLoading}
               onClick={() => handleSubmit(filePicked)}
            />
         </ButtonRow>
      </Fragment>
   )
}

export default CheckoutForms
