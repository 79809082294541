import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import api from 'services/api'

export type PackageProps = {
   child_quantity_in_package: number
   donation_status: string
   donor_event_id: number
   donor_id: number
   media_source: string
   name: string
   simma_donor_id: string
   tracking_code: string
   total_child_quantity: string
}

interface listPackageDetailsInterface {
   paginate: {
      has_more: boolean
      page: number
      total: number
   }
   results: {
      package_info: {
         date: string
         id: string
         package_donors: PackageProps[]
         title: string
      }
   }
}

interface listPackageDetailsRequest {
   pg?: number
   pp?: number
   packageId: string
}

export const listPackageDetails = createAsyncThunk(
   'listPackageDetails',
   async ({ pg, pp, packageId }: listPackageDetailsRequest) => {
      return await api
         .get(`/package/${packageId}?pp=${pp ?? 15}&pg=${pg ?? 1}`)
         .then((res) => res.data)
   },
)

const listPackageDetailsSlice = createSlice({
   name: 'listPackageDetails',
   initialState: {
      status: 'idle',
      paginate: {
         has_more: false,
         page: 0,
         total: 0,
      },
      results: {
         package_info: {
            date: '',
            id: '',
            package_donors: [],
            title: '',
         },
      },
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(listPackageDetails.pending, (state) => {
         state.status = 'loading'
      })
      builder.addCase(
         listPackageDetails.fulfilled,
         (state, action: PayloadAction<listPackageDetailsInterface>) => {
            state.status = 'ok'
            state.results = action.payload.results
         },
      ),
         builder.addCase(listPackageDetails.rejected, (state) => {
            state.status = 'rejected'
         })
   },
})

export default listPackageDetailsSlice.reducer
