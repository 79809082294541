import styled, { css } from 'styled-components'

interface BadgeStatusProps {
   status: string
}

export default function BadgeStatus({ status }: BadgeStatusProps) {
   return <BadgeSign status={status} />
}

const BadgeSign = styled.div(
   ({ status }: BadgeStatusProps) => css`
      width: 10px;
      height: 10px;
      border-radius: 12px;
      background-color: ${status === 'pending' || status === 'pendent'
         ? '#E29B11'
         : status === 'approved' ||
           status === 'paid' ||
           status === 'yes' ||
           status === 'active' ||
           status === 'accomplished' ||
           status === 'pagado'
         ? '#58C27D'
         : status === 'schedule' || status === 'scheduled'
         ? '#1DADC9'
         : status === 'refused' ||
           status === 'reproved' ||
           status === 'not' ||
           status === 'desactive'
         ? '#E74848'
         : ''};
      display: inline-block;
      margin-right: 16px;
   `,
)
