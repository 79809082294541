import styled, { css } from 'styled-components/macro'
import Spacer from 'components/Spacer'
type LetterChosenChildModal = {
   onClose: () => void
   src?: string
}
const LetterChosenChild = ({
   onClose,
   src,
}: LetterChosenChildModal): JSX.Element => {
   return (
      <>
         <AppModalCustom>
            <Spacer top={75} />

            <ContainerModal>
               <ModalCloseButton type="button" onClick={() => onClose()} />

               <LetterChosen
                  src={src ?? '/assets/images/examples/letter-chosen.png'}
               ></LetterChosen>
            </ContainerModal>

            <Spacer top={75} />
         </AppModalCustom>
      </>
   )
}

export default LetterChosenChild

const AppModalCustom = styled.div(
   ({ theme }) => css`
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: 100%;
      z-index: 1001;
      background-color: #6c6f77a2;
      width: 100%;
      margin: 0 auto;
      overflow: auto;
   `,
)

const ContainerModal = styled.div(
   ({ theme }) => css`
      max-width: 720px;
      border-radius: ${theme.spacing.xxs};
      background-color: ${theme.neutral.white};
      padding: ${theme.spacing.xxs};
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      @media screen and (max-width: 790px) {
         height: 100vh;
         background-color: #6c6f77;
      }
   `,
)

const LetterChosen = styled.img(
   ({ theme }) => css`
      width: 90%;
      display: flex;
      justify-content: center;
   `,
)

const ModalCloseButton = styled.button`
   width: 30px;
   height: 30px;

   position: absolute;
   right: -62px;
   top: 5px;
   z-index: 1010;

   background: none;
   background-size: 23px;
   background-repeat: no-repeat;
   background-image: url('/assets/images/icons/checkboxFalseInverse.svg');
   background-position: center;
   @media screen and (max-width: 1470px) {
      top: 20px;
      position: absolute;
      right: 70px;
   }
   @media screen and (max-width: 1172px) {
      top: 20px;
      position: absolute;
      right: 2px;
   }
   @media screen and (max-width: 890px) {
      position: absolute;
      right: 5px;
      top: 20px;
      background-image: url('/assets/images/icons/checkboxFalseInverse.svg');
   }
`
