import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'services/api';

interface searchChildRequest {
   frist_id: string;
   second_id: string;
}

interface searchChildResponse {
   name: string;
   city: string;
   child_age: string;
}

export const searchChild = createAsyncThunk(
   'searchChild',
   async ({ frist_id, second_id }: searchChildRequest) => {
      return await api
         .get(`/searchSponsored/${frist_id}-${second_id}`)
         .then((res) => res.data);
   },
);

const searchChildSlice = createSlice({
   name: 'searchChild',
   initialState: {
      status: 'idle',
      name: '',
      city: '',
      child_age: '',
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(searchChild.pending, (state) => {
         state.status = 'loading';
      });
      builder.addCase(
         searchChild.fulfilled,
         (state, action: PayloadAction<searchChildResponse>) => {
            state.status = 'ok';
            state.name = action.payload.name;
            state.city = action.payload.city;
            state.child_age = action.payload.child_age;
         },
      ),
         builder.addCase(searchChild.rejected, (state) => {
            state.status = 'rejected';
         });
   },
});

export default searchChildSlice.reducer;
