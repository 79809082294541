import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import api from 'services/api'
interface ReproveDonorFromPackageDetails {
   donor_id: number
}

export const reproveDonorPackageDetails = createAsyncThunk(
   'reproveDonorPackageDetails',
   async ({ donor_id }: ReproveDonorFromPackageDetails) => {
      return await api
         .patch(`/donors/remove/from-event/${donor_id}`)
         .then((res) => res.data)
   },
)

const reproveDonorPackageDetailsSlice = createSlice({
   name: 'reproveDonorPackageDetails',
   initialState: {
      status: 'idle',
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(reproveDonorPackageDetails.pending, (state) => {
         state.status = 'loading'
      })
      builder.addCase(reproveDonorPackageDetails.fulfilled, (state) => {
         state.status = 'ok'
      })
      builder.addCase(reproveDonorPackageDetails.rejected, (state) => {
         state.status = 'rejected'
      })
   },
})

export default reproveDonorPackageDetailsSlice.reducer
