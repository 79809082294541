import { useFormatMessage } from 'react-intl-hooks'
import styled, { css } from 'styled-components'

const PhotoUpdatedSucessfully = (): JSX.Element => {
   const t = useFormatMessage()
   return (
      <Container>
         <IMG src="/assets/images/photoupdated.png" alt="Logo Visão Mundial" />
         <H1>
            {' '}
            <p>{t({ id: 'Sua foto foi atualizada' })}</p>
            {t({ id: 'com sucesso' })}
         </H1>
         <BUTTON>{t({ id: 'Ir para a página inicial' })}</BUTTON>
      </Container>
   )
}

const Container = styled.header(
   ({ theme }) => css`
      align-items: center;
      display: flex;
      flex-direction: column;
   `,
)

const IMG = styled.img(
   ({ theme }) => css`
      width: 126px;
      margin-top: 80px;
   `,
)

const H1 = styled.h1`
   padding-top: 40px;
   text-align: center;
   font-family: ${(props) => props.theme.text.heading1.family};
   font-style: normal;
   font-weight: ${(props) => props.theme.text.heading1.weight};
   font-size: ${(props) => props.theme.text.heading1.size};
   line-height: ${(props) => props.theme.text.heading1.lineHeight};
   text-align: center;
   color: ${(props) => props.theme.neutral.main};
`

const BUTTON = styled.button`
   padding: 16px 24px;
   border-radius: 90px;
   margin-top: 40px;
   color: ${(props) => props.theme.neutral.white};
   background: ${(props) => props.theme.colors.brand.primary.main};
   font-family: ${(props) => props.theme.text.text1.family};
   font-style: normal;
   font-weight: bold;
   font-size: ${(props) => props.theme.text.text1.size};
   line-height: ${(props) => props.theme.text.text1.lineHeight};
`
export default PhotoUpdatedSucessfully
