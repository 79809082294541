import Button from 'components/Button';
import LetterChosenChild from 'components/Modals/LetterChosenChild';
import VideoChosenChild from 'components/Modals/VideoChosenChild';
import { useState, useEffect } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Link, useParams, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import routes from '../routes/routes.json';
import { useDispatch, useSelector } from 'react-redux';
import { listChosenChild } from 'services/slices/listChosenChild';
import { RootState } from 'services/store';
import ReactLoading from 'react-loading';
import Container from 'components/Container';
import { RevelationByEmail } from 'services/slices/sendRevelationByEmail';
import { toast } from 'react-toastify';
import useRegionalization from 'hooks/useRegionalization';

interface ButtonDisableProps {
   isDisable: boolean;
}

type Item = {
   child_id: string;
   child_city: string;
   child_name: string;
   child_photo: string;
   child_video: string;
   child_Age: string;
   digital_sending: string;
   letter_photo: string;
   revelation_type: string;
   tracking_code: string;
};

const ChosenChild = (): JSX.Element => {
   const t = useFormatMessage();
   const dispatch = useDispatch();
   const { country } = useRegionalization();
   const history = useHistory();
   const { childID } = useParams<{ childID: string }>();
   //const { donorID } = useParams<{ donorID: number }>()
   const [openVideoModal, setOpenVideoModal] = useState<boolean>();
   const [openLetterModal, setOpenLetterModal] = useState<boolean>();
   const [messageSend, setmessageSend] = useState<Boolean>();
   const [child, setChild] = useState<Item>();
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [response, setResponse] = useState(null);

   useEffect(() => {
      dispatch(listChosenChild({ childId: childID }));
   }, []);

   const { data, loadingStatus, donorData } = useSelector(
      (state: RootState) => ({
         data: state.listChosenChild.results,
         loadingStatus: state.listChosenChild.status,
         donorData: 123,
      }),
   );

   useEffect(() => {
      if (loadingStatus === 'ok') {
         setChild(data[0]);
      }
   }, [loadingStatus]);

   useEffect(() => {
      if (response) {
         dispatch(listChosenChild({ childId: childID }));
      }
   }, [response]);

   const handleSendRevelationByEmail = async () => {
      setIsLoading(true);

      const infoEmail = {
         donor_id: data[0].donor_id,
         child_id: data[0].child_id,
      };
      const response: any = await dispatch(RevelationByEmail(infoEmail));

      if (RevelationByEmail.fulfilled.match(response)) {
         setResponse(response);
         toast.success(t({ id: 'Email send com sucesso' }));
      } else {
         toast.error(t({ id: 'Nao foi possível send email' }).toString());
      }
      setIsLoading(false);
   };

   const handleOpenLetter = () => {
      if (country === 'br') {
         return (
            <SeeLetter onClick={() => setOpenLetterModal(true)}>
               <img src={`${theme.pathIcons}/email.svg`} />
               {t({ id: 'ver carta' })}
            </SeeLetter>
         );
      } else {
         return (
            <SeeLetter onClick={() => window.open(child.letter_photo)}>
               <img src={`${theme.pathIcons}/email.svg`} />
               {t({ id: 'ver carta' })}
            </SeeLetter>
         );
      }
   };

   return (
      <Container>
         <Content>
            <DivHeader>
               <BackButton onClick={() => history.goBack()}>
                  <img src={`${theme.pathIcons}/arrowLeft.svg`} />
                  {t({ id: 'Voltar' })}
               </BackButton>
               <Hr />
            </DivHeader>

            {loadingStatus !== 'ok' && (
               <LoadingContainer>
                  <ReactLoading
                     type="spin"
                     color={theme.colors.brand.primary.main}
                  />
               </LoadingContainer>
            )}

            {loadingStatus === 'ok' && child && (
               <>
                  <DivFlexWGap>
                     <ChosenData>
                        <ChildName>{child.child_name}</ChildName>
                        <ChosenInfo>
                           <p>
                              <span> {t({ id: 'Cidade' })}</span>
                              {child.child_city}
                           </p>

                           <p>
                              <span>{t({ id: 'ch code' })}</span>
                              {child.child_id}
                           </p>
                           <p>
                              <span>{t({ id: 'Revelacao' })}</span>
                              {t({ id: child.revelation_type })}
                           </p>
                           <p>
                              <span>{t({ id: 'Envio digital' })}</span>
                              {t({ id: child.digital_sending })}
                           </p>
                        </ChosenInfo>
                        <DivFlexForButtons>
                           <SeeVideo onClick={() => setOpenVideoModal(true)}>
                              <img src={`${theme.pathIcons}/video.svg`} />
                              {t({ id: 'ver video' })}
                           </SeeVideo>
                        </DivFlexForButtons>
                        <ButtonRevelations
                           disabled={
                              isLoading || child.digital_sending === 'Enviado'
                           }
                           label={t({
                              id: 'Enviar Revelacao por email',
                           }).toString()}
                           onClick={() => handleSendRevelationByEmail()}
                        />
                     </ChosenData>

                     <ChosenPhoto>
                        <img
                           src={`https://chosenapi.worldvision.co/image/${child.child_photo}`}
                           alt="Child Photo"
                        />
                     </ChosenPhoto>
                  </DivFlexWGap>
               </>
            )}
         </Content>

         {openVideoModal && (
            <VideoChosenChild
               src={loadingStatus === 'ok' && child && child.child_video}
               onClose={() => setOpenVideoModal(false)}
            />
         )}
         {openLetterModal && (
            <LetterChosenChild
               src={loadingStatus === 'ok' && child && child.letter_photo}
               onClose={() => setOpenLetterModal(false)}
            />
         )}
      </Container>
   );
};

export default ChosenChild;

const LoadingContainer = styled.div`
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
`;

const ChildName = styled.h1(
   ({ theme }) => css`
      font-family: ${theme.text.heading1.family};
      font-size: ${theme.text.heading1.size};
      font-weight: ${theme.text.heading1.weight};
      margin-bottom: ${theme.spacing.xl};
      padding-top: ${theme.spacing.xl};
   `,
);

const ChosenData = styled.div`
   display: flex;
   flex-direction: column;
   width: 45%;
`;

const ChosenInfo = styled.div(
   ({ theme }) => css`
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      p {
         font-family: ${theme.text.text1.family};
         font-size: ${theme.text.text1.size};
         font-weight: ${theme.text.text1.weight};
         line-height: ${theme.text.text1.lineHeight};
         color: ${theme.neutral.main};
      }
      span {
         font-family: ${theme.text.text1.family};
         font-size: ${theme.text.text1.size};
         font-weight: ${theme.text.text1.weight};
         line-height: ${theme.text.text1.lineHeight};
         color: ${theme.neutral.lightness};
         width: 130px;
         display: inline-block;
      }
   `,
);

const ChosenPhoto = styled.div(
   ({ theme }) => css`
      width: 50%;
      margin-top: ${theme.spacing.xs};

      img {
         width: 100%;
         height: 400px;
         object-fit: cover;
         border-radius: ${theme.spacing.xs};
      }
   `,
);

const ButtonRevelations = styled(Button)(
   ({ theme }) => css`
      padding: 16px 24px;
      max-width: 290px;

      span {
         font-size: 1rem;
      }

      /* &:disabled {
         cursor: not-allowed;
         pointer-events: none;
         opacity: 0.5;
      }*/
   `,
);

const Content = styled.div(
   ({ theme }) => css`
      padding-top: 64px;
      padding: ${theme.spacing.sm};
   `,
);

const DivFlexWGap = styled.div(
   ({}) => css`
      width: 100%;
      align-items: center;
      justify-content: space-between;
      display: flex;
      align-items: flex-start;

      @media screen and (max-width: 990px) {
         flex-wrap: wrap;

         flex-direction: column;
      }
   `,
);

const DivFlexForButtons = styled.div(
   ({ theme }) => css`
      align-items: center;
      display: flex;
      margin-top: ${theme.spacing.xl};
      margin-bottom: ${theme.spacing.xl};
   `,
);

const SeeVideo = styled.button(
   ({ theme }) => css`
      flex: 1;
      color: ${theme.neutral.main};
      padding: ${theme.spacing.sm} ${theme.spacing.sm};
      font-size: ${theme.text.title.size};
      font-family: ${theme.text.title.family};
      font-weight: ${theme.text.title.weight};
      box-shadow: 0px 1px 0px #e2e2ea;
      border-radius: ${theme.spacing.xs};
      text-decoration: none;
      margin-right: ${theme.spacing.xl};
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 1px 0px 0px ${theme.neutral.lightest};
      background-color: ${theme.neutral.white};
      transition: all 200ms ease;
      :hover {
         background-color: ${theme.neutral.background};
         box-shadow: 0px 1px 0px 0px ${theme.neutral.lightest};
      }
      > img {
         margin-right: 11px;
      }
      @media screen and (max-width: 632px) {
         margin: 0;
      }
   `,
);
const SeeLetter = styled.button(
   ({ theme }) => css`
      flex: 1;
      color: ${theme.neutral.main};
      padding: ${theme.spacing.sm} ${theme.spacing.sm};
      font-size: ${theme.text.title.size};
      font-family: ${theme.text.title.family};
      font-weight: ${theme.text.title.weight};
      box-shadow: 0px 1px 0px #e2e2ea;
      border-radius: ${theme.spacing.xs};
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 1px 0px 0px ${theme.neutral.lightest};
      background-color: ${theme.neutral.white};
      transition: all 200ms ease;
      :hover {
         background-color: ${theme.neutral.background};
      }
      > img {
         margin-right: 11px;
      }
   `,
);

const DivHeader = styled.div(
   ({ theme }) => css`
      width: 100%;
      margin-top: ${theme.spacing.md};
      margin-bottom: ${theme.spacing.md};
   `,
);

const BackButton = styled.button(
   ({ theme }) => css`
      color: ${theme.neutral.light};
      font-size: ${theme.text.text1.size};
      font-family: ${theme.text.text1.family};
      line-height: ${theme.text.text1.lineHeight};
      font-weight: ${theme.text.text1.weight};
      text-decoration: none;
      margin-left: ${theme.spacing.xs};
      display: flex;
      background-color: transparent;
      align-items: center;

      > img {
         margin-right: 11px;
      }
   `,
);

const Hr = styled.div(
   ({ theme }) => css`
      width: 100%;
      margin-top: ${theme.spacing.md};
      margin-bottom: ${theme.spacing.md};
      border: 1px solid ${theme.neutral.lightest};
   `,
);
