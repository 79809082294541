import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import FooterTermsAndUsers from 'components/FooterTermsAndUsers'
import { useFormatMessage } from 'react-intl-hooks'
const LandingPage = (): JSX.Element => {
   const t = useFormatMessage()
   return (
      <div>
         {/* <Header>
            <Container>
               <HeaderStyled>
                  <BackButton>
                     <BackButtonIcon
                        src="/assets/icons/Shape.png"
                        className="back-button"
                     />{' '}
                     Voltar
                  </BackButton>
                  <HeaderLogo src="/assets/VMB_Logo.png" />
                  <div></div>
               </HeaderStyled>
            </Container>
         </Header> */}
         <TermsAndUsers>
            <TitleStyled>{t({ id: 'Termos de Uso' })}</TitleStyled>
         </TermsAndUsers>
         <DescriptionStyled>
            <Container>
               <H5>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
                  neque libero, accumsan sit amet rhoncus vitae, hendrerit at
                  elit.
               </H5>
               <P>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
                  neque libero, accumsan sit amet rhoncus vitae, hendrerit at
                  elit. Donec sit amet magna nunc. Donec gravida massa quis
                  euismod molestie. Phasellus viverra vehicula massa eu
                  facilisis. Curabitur ut libero massa. Mauris ultrices lorem
                  lectus, ut lobortis erat sodales ac. Pellentesque habitant
                  morbi tristique senectus et netus et malesuada fames ac turpis
                  egestas.
               </P>
               <P>
                  {' '}
                  Praesent justo leo, pulvinar non euismod eget, volutpat quis
                  felis. Pellentesque tempus ipsum sed dui ultricies, at
                  pharetra eros condimentum. Ut tristique mi eget purus varius
                  finibus. In hac habitasse platea dictumst. Sed leo nisi,
                  sollicitudin id finibus eget, fringilla in nibh. Sed tempus
                  nibh ac justo ultricies posuere. In vel magna hendrerit,
                  cursus quam et, vehicula purus. Sed quis lacus nulla.
               </P>
               <P>
                  Nulla efficitur leo felis, a scelerisque augue auctor eget.
                  Donec dolor mi, malesuada vitae ex aliquet, efficitur viverra
                  neque. Donec efficitur turpis faucibus, accumsan augue sed,
                  venenatis odio. Phasellus quis tellus velit. Maecenas et
                  lobortis risus. Pellentesque faucibus neque libero, eu
                  volutpat tellus auctor et. Aliquam sed posuere justo, eu
                  euismod ligula. Interdum et malesuada fames ac ante ipsum
                  primis in faucibus. Praesent a sodales nisl, non vulputate
                  massa. Pellentesque habitant morbi tristique senectus et netus
                  et malesuada fames ac turpis egestas. Maecenas at ante ut
                  lectus tempus ultricies et faucibus purus. Vestibulum at
                  lobortis metus. Fusce elementum tortor accumsan leo congue
                  tincidunt nec eu sem.
               </P>
               <P>
                  Ut lacinia ultricies lectus nec porttitor. Cras arcu magna,
                  aliquam at ultrices a, maximus sit amet urna. Phasellus
                  suscipit nulla eu nunc faucibus hendrerit. Praesent semper
                  tincidunt ligula, eget aliquam libero iaculis in. Ut in
                  lacinia odio, lacinia mattis tellus. Phasellus non quam at
                  felis aliquet laoreet id a felis. In ullamcorper, turpis
                  euismod auctor commodo, ligula metus tempor mi, in semper est
                  enim et purus. Nullam tincidunt ornare libero, sit amet
                  condimentum eros gravida nec. Aenean laoreet quam justo, a
                  commodo est commodo vel. Morbi viverra pulvinar facilisis. In
                  nisl quam, dignissim a tortor non, dignissim imperdiet mi.
                  Pellentesque gravida hendrerit dictum. Pellentesque habitant
                  morbi tristique senectus et netus et malesuada fames ac turpis
                  egestas.
               </P>
               <P>
                  In congue ut ligula quis posuere. Donec efficitur scelerisque
                  justo, sit amet mollis nisl pellentesque vitae. Donec cursus
                  ut velit et pretium. Quisque eu rutrum felis, eget faucibus
                  lacus. Maecenas facilisis magna purus, id bibendum libero
                  fringilla eget. Ut dignissim, elit id ultrices tempus, metus
                  dui volutpat augue, non iaculis nisi nibh id tellus. Integer
                  id bibendum elit, nec mattis nisi. Sed aliquam tincidunt arcu
                  nec bibendum. Donec fermentum nibh sagittis venenatis
                  fringilla. Maecenas quis orci sed nulla facilisis ultrices.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
                  neque libero, accumsan sit amet rhoncus vitae, hendrerit at
                  elit. Donec sit amet magna nunc. Donec gravida massa quis
                  euismod molestie. Phasellus viverra vehicula massa eu
                  facilisis. Curabitur ut libero massa. Mauris ultrices lorem
                  lectus, ut lobortis erat sodales ac. Pellentesque habitant
                  morbi tristique senectus et netus et malesuada fames ac turpis
                  egestas.
               </P>
               <P>
                  Praesent justo leo, pulvinar non euismod eget, volutpat quis
                  felis. Pellentesque tempus ipsum sed dui ultricies, at
                  pharetra eros condimentum. Ut tristique mi eget purus varius
                  finibus. In hac habitasse platea dictumst. Sed leo nisi,
                  sollicitudin id finibus eget, fringilla in nibh. Sed tempus
                  nibh ac justo ultricies posuere. In vel magna hendrerit,
                  cursus quam et, vehicula purus. Sed quis lacus nulla.
               </P>
               <P>
                  Nulla efficitur leo felis, a scelerisque augue auctor eget.
                  Donec dolor mi, malesuada vitae ex aliquet, efficitur viverra
                  neque. Donec efficitur turpis faucibus, accumsan augue sed,
                  venenatis odio. Phasellus quis tellus velit. Maecenas et
                  lobortis risus. Pellentesque faucibus neque libero, eu
                  volutpat tellus auctor et. Aliquam sed posuere justo, eu
                  euismod ligula. Interdum et malesuada fames ac ante ipsum
                  primis in faucibus. Praesent a sodales nisl, non vulputate
                  massa. Pellentesque habitant morbi tristique senectus et netus
                  et malesuada fames ac turpis egestas. Maecenas at ante ut
                  lectus tempus ultricies et faucibus purus. Vestibulum at
                  lobortis metus. Fusce elementum tortor
               </P>
               <P>
                  accumsan leo congue tincidunt nec eu sem. Ut lacinia ultricies
                  lectus nec porttitor. Cras arcu magna, aliquam at ultrices a,
                  maximus sit amet urna. Phasellus suscipit nulla eu nunc
                  faucibus hendrerit. Praesent semper tincidunt ligula, eget
                  aliquam libero iaculis in. Ut in lacinia odio, lacinia mattis
                  tellus. Phasellus non quam at felis aliquet laoreet id a
                  felis. In ullamcorper, turpis euismod auctor commodo, ligula
                  metus tempor mi, in semper est enim et purus. Nullam tincidunt
                  ornare libero, sit amet condimentum eros gravida nec. Aenean
                  laoreet quam justo, a commodo est commodo vel. Morbi viverra
                  pulvinar facilisis. In nisl quam, dignissim a tortor non,
                  dignissim imperdiet mi. Pellentesque gravida hendrerit dictum.
                  Pellentesque habitant morbi tristique senectus et netus et
                  malesuada fames ac turpis egestas.
               </P>
            </Container>
         </DescriptionStyled>
         <FooterTermsAndUsers />
      </div>
   )
}

const Container = styled.div`
   margin: 0;
   padding: 0;
   width: 70%;
`
/* HEADER */
const Header = styled.header(
   ({ theme }) => css`
      height: 72px;
      display: flex;
      justify-content: center;
      background: ${theme.neutral.white};
      box-shadow: inset 0px -1px 0px #e2e2ea;
   `,
)

const HeaderLogo = styled.img`
   height: 32px;
   object-fit: contain;
   margin-top: 16px;
   margin-right: 84px;
`

const HeaderStyled = styled.div`
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: flex-start;
`

const BackButton = styled.button(
   ({ theme }) => css`
      background: ${theme.neutral.white};
      border: none;
      width: 82px;
      margin-top: 24px;
      font-family: ${theme.text.text1.family};
      font-size: ${theme.text.text1.size};
      line-height: ${theme.text.text1.lineHeight};
      font-weight: ${theme.text.text1.weight};
      color: ${theme.colors.input};
      font-size: 16px;
      display: flex;
      align-items: center;
   `,
)

const BackButtonIcon = styled.img`
   margin-right: 10px;
   width: 16px;
`
/* SECTION TERMS AND USERS */
const TermsAndUsers = styled.div`
   width: 100%;
   background: #fff3eb;
   height: 160px;
   display: flex;
   justify-content: center;
   align-items: center;
`

const TitleStyled = styled.h1(
   ({ theme }) => css`
      font-family: ${theme.text.heading1.family};
      font-size: ${theme.text.heading1.size};
      line-height: ${theme.text.heading1.lineHeight};
      font-weight: ${theme.text.heading1.weight};
      color: ${theme.neutral.main};
   `,
)

/* DESCRIPTION TERMS AND USERS */

const DescriptionStyled = styled.main(
   ({ theme }) => css`
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 178px;
   `,
)

const H5 = styled.h5(
   ({ theme }) => css`
      margin: 0;
      padding-top: ${theme.spacing.xxl};
      font-family: ${theme.text.text1.family};
      font-size: ${theme.text.text1.size};
      line-height: ${theme.text.text1.lineHeight};
      font-weight: ${theme.text.title.weight};
      color: ${theme.neutral.main};
   `,
)

const P = styled.p(
   ({ theme }) => css`
      font-family: ${theme.text.text1.family};
      font-size: ${theme.text.text1.size};
      line-height: ${theme.text.text1.lineHeight};
      font-weight: ${theme.text.text1.weight};
      color: ${theme.neutral.sub};
      padding-top: ${theme.spacing.xl};
   `,
)

export default LandingPage
