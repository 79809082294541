import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'services/api';

type InfoDonor = {
   id: string;
};

export const registerDonorInSimma = createAsyncThunk(
   'RegisterDonorInSimmah',
   async ({ id }: InfoDonor) => {
      return await api
         .get(`/registerDonorInSimma/${id}`)
         .then((res) => res.data);
   },
);

const registerDonorInSimmaSlice = createSlice({
   name: 'DeleteDonor',
   initialState: {
      status: 'idle',
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(registerDonorInSimma.pending, (state) => {
         state.status = 'loading';
      });
      builder.addCase(registerDonorInSimma.fulfilled, (state) => {
         state.status = 'ok';
      });
      builder.addCase(registerDonorInSimma.rejected, (state) => {
         state.status = 'rejected';
      });
   },
});

export default registerDonorInSimmaSlice.reducer;
