import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import api from 'services/api'

interface ChosenRevealRequest {
   token: string
}

type ChildProps = {
   id: number
   name: string
   age: string
   city: string
   photo: string
   video: string
   letter: string
}

type DonorProps = {
   id: number
   name: string
}

interface ChosenRevealResponse {
   results: {
      donor: DonorProps
      child: ChildProps
   }
}

export const chosenReveal = createAsyncThunk(
   'chosenReveal',
   async ({ token }: ChosenRevealRequest) => {
      return await api
         .get(`/show-mural-children/${token}`)
         .then((res) => res.data)
   },
)

const chosenRevealSlice = createSlice({
   name: 'chosenReveal',
   initialState: {
      status: 'idle',
      results: {
         donor: {
            id: 0,
            name: '',
         },
         child: {
            id: 0,
            name: '',
            age: '',
            city: '',
            photo: '',
            video: '',
            letter: '',
         },
      },
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(chosenReveal.pending, (state) => {
         state.status = 'loading'
      })
      builder.addCase(
         chosenReveal.fulfilled,
         (state, action: PayloadAction<ChosenRevealResponse>) => {
            state.status = 'ok'
            state.results = action.payload.results
         },
      ),
         builder.addCase(chosenReveal.rejected, (state) => {
            state.status = 'rejected'
         })
   },
})

export default chosenRevealSlice.reducer
