import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from 'services/api'

interface newPasswordRequest {
   email: string
   token: string
   new_password: string
   new_password_confirmation: string
}

export const newPassword = createAsyncThunk(
   'newPassword',
   async ({
      email,
      token,
      new_password,
      new_password_confirmation,
   }: newPasswordRequest) => {
      return await api
         .post('/auth/reset-password', {
            email: email,
            token: token,
            new_password: new_password,
            new_password_confirmation: new_password_confirmation,
         })
         .then((res) => res.data)
   },
)

const newPasswordSlice = createSlice({
   name: 'newPassword',
   initialState: {
      status: 'idle',
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(newPassword.pending, (state, action) => {
         state.status = 'loading'
      })
      builder.addCase(newPassword.fulfilled, (state, action) => {
         state.status = 'ok'
      }),
         builder.addCase(newPassword.rejected, (state, action) => {
            state.status = 'rejected'
         })
   },
})

export default newPasswordSlice.reducer
