import { Fragment, SelectHTMLAttributes } from 'react'
import styled, { css, CSSProperties } from 'styled-components'
import Spacer from './Spacer'

const Wrapper = styled.div`
   position: relative;
   display: flex;
   flex-direction: column;
   flex: 1;
`

const Label = styled.label`
   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   color: ${(props) => props.theme.neutral.sub};
`

type SelectOption = {
   label: string
   value: string
}

type SelectProps = SelectHTMLAttributes<HTMLSelectElement> & {
   label?: string
   error?: string
   wrapperStyle?: CSSProperties
   select?: boolean
   selectOptions?: SelectOption[]
   renderLabel?: (props: SelectProps) => JSX.Element
   renderSelect?: (props: SelectProps) => JSX.Element
}

const StyledSelect = styled.select<SelectProps>`
   padding: 16px;
   padding-right: 30px;

   outline: none;

   border: 1.2px solid ${(props) => props.theme.neutral.lightest};
   border-radius: 8px;

   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   transition: border-color ease 0.5s;
   will-change: border-color;
   color: ${(props) => props.theme.neutral.sub};

   appearance: none;

   &::placeholder {
      color: ${(props) => props.theme.neutral.lightest};
   }

   &:focus {
      border-color: ${(props) => props.theme.colors.brand.secondary.main};
   }

   ${(props) =>
      props.error &&
      css`
         border-color: ${(props) => props.theme.feedback.error.dark};
      `}
`

const FormFeedback = styled.div`
   width: 100%;
   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   color: ${(props) => props.theme.feedback.error.dark};

   white-space: nowrap;
   //  overflow: hidden;
   //  text-overflow: ellipsis;
`

const EntryIcon = styled.i<{ hasLabel: boolean }>`
   position: absolute;
   top: ${(props) => (props.hasLabel ? 55 : 32)}px;
   right: 16px;
   color: ${(props) => props.theme.neutral.lightness};
   cursor: pointer;
   pointer-events: none;
`

const InputSelect = (inputProps: SelectProps): JSX.Element => {
   const { label, wrapperStyle, error, renderSelect, renderLabel, ...props } =
      inputProps

   return (
      <Wrapper style={wrapperStyle}>
         {renderLabel
            ? renderLabel(inputProps)
            : label && <Label>{label}</Label>}
         <Spacer bottom={8} />
         {renderSelect ? (
            renderSelect(inputProps)
         ) : (
            <StyledSelect error={error} {...props} />
         )}
         
         {renderSelect ? 
            <EntryIcon            
            hasLabel={label !== undefined}
            />
         :
            <EntryIcon
            className="fas fa-chevron-down"
            hasLabel={label !== undefined}
             />}

         
         {error && (
            <Fragment>
               <Spacer top={6} />
               <FormFeedback>{error}</FormFeedback>
            </Fragment>
         )}
      </Wrapper>
   )
}

export default InputSelect
