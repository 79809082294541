import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'services/api';

interface AttachChildModal {
   child_id: string;
   child_name: string;
   child_city: string;
   child_age: string;
   child_photo: string;
   child_video: string;
   donor_id: number;
}

interface AttachChildResponse {
   status: string;
   result: AttachChildModal;
}

export const attachChildModal = createAsyncThunk(
   'attachChild',
   async (data: AttachChildModal) => {
      return await api.post('/revelation', data).then((res) => res.data);
   },
);

const attachChildSlice = createSlice({
   name: 'attachChild',
   initialState: {
      status: 'idle',
      result: {
         child_id: '',
         child_name: '',
         child_city: '',
         child_age: '',
         child_photo: '',
         child_video: '',
         donor_id: 0,
      },
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(attachChildModal.pending, (state) => {
         state.status = 'loading';
      });
      builder.addCase(
         attachChildModal.fulfilled,
         (state, action: PayloadAction<AttachChildResponse>) => {
            state.status = 'ok';
            state.result = action.payload.result;
         },
      );
      builder.addCase(attachChildModal.rejected, (state) => {
         state.status = 'rejected';
      });
   },
});

export default attachChildSlice.reducer;
