import styled, { css } from 'styled-components'
import { useFormatMessage } from 'react-intl-hooks'
import FooterTermsAndUsers from 'components/FooterTermsAndUsers'
import { useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'services/store'
import { useEffect, useState } from 'react'
import { chosenReveal } from 'services/slices/chosenReveal'
import ReactLoading from 'react-loading'
import theme from '../styles/theme'
import ChosenMedia from 'components/Modals/ChosenMedia'
import useRegionalization from 'hooks/useRegionalization'

const ChosenRevelation = (): JSX.Element => {
   const t = useFormatMessage()
   const location = useLocation()
   const dispatch = useDispatch()
   const { country } = useRegionalization()
   const auxToken = location.search
   const userToken = auxToken.slice(7)

   const { result, loadingStatus } = useSelector((state: RootState) => ({
      result: state.chosenReveal.results,
      loadingStatus: state.chosenReveal.status,
   }))

   type MediaProps = 'img' | 'video'

   const [openMediaModal, setOpenMediaModal] = useState<boolean>(false)
   const [mediaSrc, setMediaSrc] = useState<string>('')
   const [mediaType, setMediaType] = useState<MediaProps>('img')

   useEffect(() => {
      dispatch(chosenReveal({ token: userToken }))
   }, [])

   const handleOpenMediaModal = (src: string, mediaSelected: MediaProps) => {
      setOpenMediaModal(true)
      setMediaSrc(src)
      setMediaType(mediaSelected)
   }

   return (
      <Content>
         {loadingStatus === 'ok' ? (
            <div>
               <SectionRevelation>
                  {/* header */}
                  <H3>
                     {t({ id: 'Ola,' })} {result.donor.name},
                  </H3>
                  <H2>{t({ id: 'você foi escolhido(a)' })}</H2>
               </SectionRevelation>
               <ChosenInformation>
                  <Container>
                     <H1>{result.child.name}</H1>
                     <Description>
                        {t({ id: 'Mora em' })} {result.child.city}
                     </Description>
                     <Photos>
                        <UL>
                           <LI>
                              <VideoStyled src={result.child.video} />

                              <ButtonPhotosStyled
                                 onClick={() =>
                                    handleOpenMediaModal(
                                       result.child.video,
                                       'video',
                                    )
                                 }
                              >
                                 {t({ id: 'Abrir Vídeo' })}
                              </ButtonPhotosStyled>
                           </LI>
                           <LI>
                              <PhotoStyled src={result.child.photo} />

                              <ButtonPhotosStyled
                                 onClick={() =>
                                    handleOpenMediaModal(
                                       result.child.photo,
                                       'img',
                                    )
                                 }
                              >
                                 {t({ id: 'Abrir Foto' })}
                              </ButtonPhotosStyled>
                           </LI>
                           <LI>
                              <PhotoStyled src={result.child.letter} />
                              {country === 'br' ? (
                                 <ButtonPhotosStyled
                                    onClick={() =>
                                       handleOpenMediaModal(
                                          result.child.letter,
                                          'img',
                                       )
                                    }
                                 >
                                    {t({ id: 'Abrir Carta' })}
                                 </ButtonPhotosStyled>
                              ) : (
                                 <ButtonPhotosStyled
                                    onClick={() =>
                                       window.open(result.child.letter)
                                    }
                                 >
                                    {t({ id: 'Abrir Carta' })}
                                 </ButtonPhotosStyled>
                              )}
                           </LI>
                        </UL>
                     </Photos>
                  </Container>
               </ChosenInformation>

               <FooterTermsAndUsers />

               {openMediaModal && (
                  <ChosenMedia
                     src={mediaSrc}
                     type={mediaType}
                     onClose={() => setOpenMediaModal(false)}
                  />
               )}
            </div>
         ) : (
            <LoadingContainer>
               <ReactLoading
                  type="spin"
                  color={theme.colors.brand.primary.main}
               />
            </LoadingContainer>
         )}
      </Content>
   )
}

const LoadingContainer = styled.div`
   height: 100vh;
   margin-top: -75px;
   display: flex;
   justify-content: center;
   align-items: center;
`

const Content = styled.div``

const Container = styled.div(
   ({ theme }) => css`
      margin: 0;
      padding: 0;
      width: 70%;
      display: flex;
      align-items: center;
      flex-direction: column;
   `,
)

const SectionRevelation = styled.div(
   ({ theme }) => css`
      background: #fff3eb;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: ${theme.spacing.xxl};
      padding-bottom: ${theme.spacing.xxl};
   `,
)

const H3 = styled.div(
   ({ theme }) => css`
      margin: 0;
      padding: 0;
      font-family: ${theme.text.heading2.family};
      font-size: ${theme.text.heading2.size};
      line-height: ${theme.text.heading2.lineHeight};
      font-weight: ${theme.text.heading2.weight};
      color: ${theme.neutral.main};
   `,
)

const H2 = styled.div(
   ({ theme }) => css`
      margin: 0;
      padding: 0;
      font-family: ${theme.text.heading1.family};
      font-size: ${theme.text.heading1.size};
      line-height: ${theme.text.heading1.lineHeight};
      font-weight: ${theme.text.heading1.weight};
      color: ${theme.neutral.main};
   `,
)

const ChosenInformation = styled.div(
   ({ theme }) => css`
      margin: 0;
      padding: 0;
      font-family: ${theme.text.heading1.family};
      font-size: ${theme.text.heading1.size};
      line-height: ${theme.text.heading1.lineHeight};
      font-weight: ${theme.text.heading1.weight};
      color: ${theme.neutral.main};
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 120px;
   `,
)

const IMG = styled.img(
   ({ theme }) => css`
      width: 240px;
      height: 240px;
      border-radius: 50%;
      margin: 0;
      object-fit: cover;
   `,
)

const H1 = styled.div(
   ({ theme }) => css`
      margin: 0;
      font-family: ${theme.text.heading2.family};
      font-size: 56px;
      line-height: 64px;
      font-weight: ${theme.text.title.weight};
      color: ${theme.colors.brand.primary.main};
      padding-top: ${theme.spacing.xl};
      text-align: center;
   `,
)

const Description = styled.h4(
   ({ theme }) => css`
      font-family: ${theme.text.text1.family};
      font-size: ${theme.text.text1.size};
      line-height: ${theme.text.text1.lineHeight};
      font-weight: ${theme.text.text1.weight};
      color: ${theme.neutral.main};
      padding-top: ${theme.spacing.sm};
   `,
)

const Photos = styled.div(
   ({ theme }) => css`
      width: 100%;
      padding-top: 40px;
   `,
)

const UL = styled.ul(
   ({ theme }) => css`
      display: flex;
      justify-content: center;

      li {
         margin-right: ${theme.spacing.lg};

         &:last-child {
            margin: 0;
         }
      }

      @media (max-width: 1020px) {
         flex-direction: column;
         align-items: center;

         li {
            margin-right: 0;
            margin-bottom: ${theme.spacing.lg};

            &:last-child {
               margin: 0;
            }
         }
      }
   `,
)

const PhotoStyled = styled.img(
   ({ theme }) => css`
      width: 100%;
      height: 256px;
      object-fit: cover;
   `,
)

const VideoStyled = styled.video(
   ({ theme }) => css`
      width: 100%;
      height: 256px;
      object-fit: cover;
   `,
)

const LI = styled.li(
   ({ theme }) => css`
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 33%;

      @media (max-width: 1020px) {
         width: 100%;
      }
   `,
)

const ButtonPhotosStyled = styled.button(
   ({ theme }) => css`
      padding: 8px 16px;
      width: 113px;
      height: 36px;
      margin-top: 24px;
      border-radius: 90px;
      background: ${theme.colors.brand.primary.main};
      font-family: ${theme.text.heading1.family};
      font-size: 14px;
      font-weight: ${theme.text.title.weight};
      color: ${theme.neutral.white};
      line-height: 20px;
   `,
)

export default ChosenRevelation
