import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from 'services/api'

export const loginUser = createAsyncThunk(
   'login',
   async ({ email, password }: { email: string; password: string }) => {
      return await api
         .post('/auth/login', {
            email: email,
            password: password,
         })
         .then((res) => res.data)
   },
)

const loginUserSlice = createSlice({
   name: 'login',
   initialState: {
      status: 'idle',
      params: { auth_secure_token: null },
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(loginUser.pending, (state, action) => {
         state.status = 'loading'
      })
      builder.addCase(loginUser.fulfilled, (state, action) => {
         state.status = 'ok'
         state.params.auth_secure_token =
            action.payload.auth_secure_token
      }),
         builder.addCase(loginUser.rejected, (state, action) => {
            state.status = 'rejected'
            state.params.auth_secure_token = null
         })
   },
})

export default loginUserSlice.reducer
