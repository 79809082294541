import styled from 'styled-components/macro'

const Footer = (): JSX.Element => {
   return (
      <FooterCustom>
         <img src="/assets/images/logo-mdb.svg" alt="Logo Visão Mundial" />
      </FooterCustom>
   )
}

const FooterCustom = styled.footer`
   justify-content: center;
   display: flex;
   padding: 40px 0;
`

export default Footer
