import styled, { css } from 'styled-components'
import fns from 'services/storage'
import { useFormatMessage } from 'react-intl-hooks'
import routes from '../routes/routes.json'
import { useHistory } from 'react-router-dom'
const DropdownUser = (): JSX.Element => {
   const t = useFormatMessage()
   const history = useHistory()

   const LogOff = () => {
      fns.clear()
      window.location.reload()
   }

   return (
      <>
         <Dropdown>
            <img
               src={
                  'https://ui-avatars.com/api/?name=Usuario&background=FF5F00&color=fff'
               }
            />
            <DropdownContent>
               {/*  TO-DO Guilherme: ir para tela de "meu perfil" 
               <ButtonToGo onClick={() => history.push(routes.)}>
                  {t({ id: 'Meu perfil' })}
               </ButtonToGo>*/}
               <ButtonToGo onClick={LogOff}>{t({ id: 'Sair' })}</ButtonToGo>
            </DropdownContent>
         </Dropdown>
      </>
   )
}

export default DropdownUser

const DropdownContent = styled.div(
   ({ theme }) => css`
      display: none;
      position: absolute;

      width: 120px;
      right: 10px;
      border-radius: 6px;
      box-shadow: 0px 10px 30px rgba(13, 51, 32, 0.1);
      border: 1px solid ${(props) => props.theme.neutral.lightest};

      button {
         display: block;
         color: #000000;
         width: 100%;
         padding: 16px;
         text-decoration: none;
         transition: all 0.1s ease;
         font-family: ${(props) => props.theme.text.text1.family};
         font-size: ${(props) => props.theme.text.text1.size};
         line-height: ${(props) => props.theme.text.text1.lineHeight};
         font-weight: ${(props) => props.theme.text.text1.weight};
         color: ${(props) => props.theme.neutral.light};
      }
      button:hover {
         color: ${(props) => props.theme.colors.brand.secondary.light};
         background-color: ${theme.neutral.background};
      }
   `,
)

const Dropdown = styled.div(
   ({ theme }) => css`
      display: inline-block;
      position: relative;
      :hover > div {
         display: block;
      }

      img {
         border-radius: 50%;
         width: 40px;
      }
   `,
)
const ButtonToGo = styled.button(
   ({ theme }) =>
      css`
         background-color: ${(props) => props.theme.neutral.white};
         border-radius: 6px;
      `,
)
