import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'services/api';

export type updateDonorDetails = {
   name: string;
   document: string;
   email: string;
   phone: {
      country: string;
      value: string;
   };
   ocupation: string;
   birthdate: string;
   gender: string;
   address_street: string;
   address_number: string;
   address_complement?: string;
   address_zipcode: string;
   address_neightborhood: string;
   address_city: string;
   address_state: string;
   event_status: string;
   donor_photo_base64: string;
};

type updateDonorDetailsRequest = {
   data: updateDonorDetails;
   id: string;
};

type DonorResponse = {
   message: string;
};

export const updateDonor = createAsyncThunk(
   'updateDonor',
   async ({ id, data }: updateDonorDetailsRequest) => {
      return await api
         .post(`/chosenUpdate/${id}`, data)
         .then((res) => res.data);
   },
);

const updateDonorSlice = createSlice({
   name: 'updateDonor',
   initialState: {
      status: 'idle',
      message: '',
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(updateDonor.pending, (state) => {
         state.status = 'loading';
      });
      builder.addCase(
         updateDonor.fulfilled,
         (state, action: PayloadAction<DonorResponse>) => {
            state.status = 'ok';
            state.message = action.payload.message;
         },
      );
      builder.addCase(updateDonor.rejected, (state) => {
         state.status = 'rejected';
      });
   },
});

export default updateDonorSlice.reducer;
