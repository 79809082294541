const defaultTheme = {
   text: {
      heading1: {
         family: 'Poppins',
         size: '32px',
         lineHeight: '40px',
         weight: '600',
      },
      heading2: {
         family: 'Poppins',
         size: '20px',
         lineHeight: '24px',
         weight: '600',
      },
      title: {
         family: 'Inter',
         size: '16px',
         lineHeight: '24px',
         weight: '700',
      },
      text1: {
         family: 'Inter',
         size: '16px',
         lineHeight: '24px',
         weight: '500',
      },
      text2: {
         family: 'Inter',
         size: '10px',
         lineHeight: '24px',
         weight: '500',
      },
   },
   colors: {
      brand: {
         primary: {
            main: '#FF5F00',
            light: '#FF7929',
            dark: '#D65000',
         },
         secondary: {
            main: '#1DADC9',
            light: '#1DADC9',
            dark: '#1DADC9',
         },
      },

      input: '#8D8F96',
   },
   neutral: {
      main: '#353945',
      sub: '#8D8F96',
      light: '#777E90',
      lightness: '#B1B5C3',
      lightest: '#E2E2EA',
      background: '#FCFCFD',
      white: '#FFFFFF',
   },
   feedback: {
      success: {
         main: '#58C27D',
         light: '#5ED286',
         dark: '#40B067',
      },
      error: {
         main: '#E74848',
         light: '#F86060',
         dark: '#D73434',
      },
      important: {
         main: '#E29B11',
         light: '#FCAD13',
         dark: '#C98A0F',
      },
   },
   spacing: {
      xxs: '4px',
      xs: '8px',
      sm: '16px',
      md: '24px',
      lg: '32px',
      xl: '40px',
      xsl: '60px',
      xxl: '80px',
      xxsl: '90px',
      xxxl: '110px',
      huge: '160px',
   },

   pathIcons: '/assets/images/icons',
};

export default defaultTheme;
