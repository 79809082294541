import { Fragment, useEffect, useMemo, useState } from 'react';
import fns from 'services/storage';
import styled, { css } from 'styled-components';
import FilterSelect from 'components/FilterSelect';
import routes from '../routes/routes.json';
import { useFormatMessage } from 'react-intl-hooks';
import ReactLoading from 'react-loading';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'services/store';
import { listApprovals } from 'services/slices/listApprovals';
import { approvePhoto } from 'services/slices/approvePhoto';
import debounce from 'lodash.debounce';
import BadgeStatus from '../components/BadgeStatus';
import Pagination from 'components/Pagination';
import { ConfirmationApproveOrReproveDonor } from 'components/Modals/ConfirmationApproveOrReproveDonor';
import { toast } from 'react-toastify';
import Container from 'components/Container';
import NoResult from 'components/NoResult';
import Checkbox from 'components/Checkbox';
import { useHistory } from 'react-router';
import useRegionalization from 'hooks/useRegionalization';
import theme from 'styles/theme';
import ErrorBoundary from '../pages/dist/errorBoundari.jsx';

export type Item = {
   child_quantity?: number;
   donation_id?: number;
   donation_status?: string;
   donor_media_id?: number;
   email?: string;
   id?: number;
   media_source?: string;
   name?: string;
   simma_donor_id?: string;
   validation_status?: string;
   typeIncription?: string;
   donor_photo_base64?: String;
};

type ModalProps = {
   isVisible: boolean;
};

type ButtonAprovationProps = {
   gotChecked: boolean;
};

const Approvals = (): JSX.Element => {
   const t = useFormatMessage();
   const history = useHistory();
   const dispatch = useDispatch();
   const { country } = useRegionalization();
   const { result, loadingStatus, photoApproveStatus, paginate } = useSelector(
      (state: RootState) => ({
         result: state.listApprovals.results,
         loadingStatus: state.listApprovals.status,
         photoApproveStatus: state.approvePhoto.status,
         paginate: state.listApprovals.paginate,
      }),
   );

   const [donationStatus, setDonationStatus] = useState<string>('all');
   const [validationStatus, setValidationStatus] = useState<string>('all');
   const [term, setTerm] = useState<string>('');
   const [pg, setPg] = useState<number>(1);
   const [lastPage, setLastPage] = useState<number>(0);
   const [actionSelected, setActionSelected] = useState<string>('');
   const [pp, setPp] = useState<number>(country === 'mx' ? 9 : 15);
   const [items, setItems] = useState<Item[]>(result);
   const [selectedItem, setSelectedItem] = useState<Item>();
   const [selecteds, setSelecteds] = useState<Item[]>([]);
   const [photoModalOpen, setPhotoModalOpen] = useState<boolean>(false);
   const [photoListOpen, setPhotoListOpen] = useState<boolean>(false);
   const [donorSelected, setDonorSelected] = useState<Item>();
   const [modalOpen, setModalOpen] = useState<boolean>(false);
   const [validatingUsers, setValidatingUsers] = useState<number[]>([]);
   const [firstEffectCompleted, setFirstEffectCompleted] = useState(false);

   useEffect(() => {
      dispatch(
         listApprovals({
            donationStatus: donationStatus,
            validationStatus: validationStatus,
            pg: pg - 1,
            pp: 10,
            term: term,
         }),
      );
   }, [donationStatus, validationStatus, term, pg]);

   useEffect(() => {
      setLastPage(/*NUMBERS OF PAGES */ Math.ceil(paginate.total / pp));
   }, [paginate]);

   useEffect(() => {
      if (loadingStatus) {
      }
   }, [loadingStatus]);

   useEffect(() => {
      if (photoApproveStatus === 'ok' && actionSelected !== '') {
         if (actionSelected === 'approved') {
            toast.success(
               `${
                  selecteds.length === 0
                     ? t({ id: 'Foto Aprovada' })
                     : t({ id: 'Fotos Aprovadas' })
               }!`,
            );
         } else {
            toast.success(
               `${
                  selecteds.length === 0
                     ? t({ id: 'Foto Reprovada' })
                     : t({ id: 'Fotos Reprovadas' })
               }!`,
            );
         }

         setActionSelected('');
         setSelecteds([]);
         setValidatingUsers([]);
         setPhotoListOpen(false);

         dispatch(
            listApprovals({
               donationStatus: donationStatus,
               validationStatus: validationStatus,
               pg: pg - 1,
               pp: 10,
               term: term,
            }),
         );
      }

      if (photoApproveStatus === 'rejected' && actionSelected !== '') {
         if (actionSelected === 'approved') {
            toast.warning(
               `${
                  selecteds.length === 0
                     ? t({ id: 'Nao foi possivel aprovar a foto' })
                     : t({ id: 'Nao foi possivel aprovar as fotos' })
               }!`,
            );
         } else {
            toast.warning(
               `${
                  selecteds.length === 0
                     ? t({ id: 'Nao foi possivel reprovar a foto' })
                     : t({ id: 'Nao foi possivel reprovar as fotos' })
               }!`,
            );
         }
         setValidatingUsers([]);
         setActionSelected('');
      }
   }, [photoApproveStatus]);

   useEffect(() => {
      setSelecteds([]);
   }, [donationStatus, validationStatus]);

   const openModal = (item: Item) => {
      setSelectedItem(item);
      setPhotoModalOpen(true);
   };

   const approvalUserPhoto = ({ image_id, status }) => {
      dispatch(approvePhoto({ image_id, status }));
   };

   const allChecked = useMemo(() => {
      const pendingResults = result.filter(
         (item) => item.validation_status === 'pending',
      );

      if (pendingResults.length === 0) {
         return false;
      } else {
         return pendingResults.every((_item) =>
            selecteds.some((_selected) => _selected.id === _item.id),
         );
      }
   }, [result, selecteds]);

   const approvePhotos = () => {
      setPhotoListOpen(true);
   };

   const approveSelectedPhotos = () => {
      handleApproval(selecteds, true);
   };

   const cleanFilters = () => {
      setDonationStatus('all');
      setValidationStatus('all');
      setTerm('');
   };

   const searchFilter = debounce((event) => {
      if (event.target.value.length > 2 || event.target.value.length == 0) {
         setTerm(event.target.value);
      }
   }, 500);

   const handleOpenModal = (donor: Item, status: string) => {
      setDonorSelected(donor);
      setActionSelected(status);
      setModalOpen(true);
   };

   const handleItemCheck = (item: Item) => {
      const checked = selecteds.some((_selected) => {
         return _selected.id === item.id;
      });

      if (checked) {
         setSelecteds((_selecteds) =>
            _selecteds.filter((_selected) => _selected.id !== item.id),
         );
      } else {
         setSelecteds((_selecteds) => [..._selecteds, item]);
      }
   };

   const handleApproval = async (items: Item[], approve: boolean) => {
      const imageArray = items.map((item) => item.id);
      setValidatingUsers(imageArray);
      setActionSelected(approve ? 'approved' : 'reproved');

      await approvalUserPhoto({
         image_id: imageArray,
         status: approve ? 'approved' : 'reproved',
      });
   };

   const handleCheckAll = () => {
      const pendingResults = result.filter(
         (item) => item.validation_status === 'pending',
      );

      if (allChecked) {
         setSelecteds([]);
      } else {
         setSelecteds(pendingResults);
      }

      if (pendingResults.length === 0) {
         toast.warning(
            t({
               id: 'Nao existem fotos de doadores para serem aprovados',
            }).toString(),
         );
      }
   };

   const renderPhotoItem = (_item: Item, index: number) => {
      const checked = selecteds.some((_selected) => _selected.id === _item.id);

      return (
         <PhotoListItemWrapper
            key={index}
            checked={checked}
            onClick={() => {
               if (_item.validation_status === 'pending')
                  handleItemCheck(_item);
            }}
            disabled={_item.validation_status !== 'pending'}
         >
            <PhotoListItem
               key={index}
               src={
                  _item.donor_photo_base64
                     ? `https://chosenapi.worldvision.co/image${_item.donor_photo_base64}`
                     : `https://chosenapi.worldvision.co/image/static/images/sinImagen.png`
               }
            />
            {checked && (
               <PhotoListItemIndicator>
                  <i className="fas fa-check"></i>
               </PhotoListItemIndicator>
            )}

            {_item.validation_status !== 'pending' &&
               _item.validation_status === 'approved' && (
                  <PhotoListItemIndicator>
                     <i className="fas fa-check"></i>
                  </PhotoListItemIndicator>
               )}

            {_item.validation_status !== 'pending' &&
               _item.validation_status === 'reproved' && (
                  <ReprovedPhotoListItemIndicator>
                     <i className="fas fa-times"></i>
                  </ReprovedPhotoListItemIndicator>
               )}
         </PhotoListItemWrapper>
      );
   };

   const renderAprroval = (item: Item, index: number) => {
      const checked = selecteds.some((_selected) => _selected.id === item.id);
      const isValidating = validatingUsers.includes(item.donor_media_id);

      return (
         <ItemList key={index} checked={checked}>
            <Checkbox
               checked={checked}
               disabled={item.validation_status !== 'pending'}
               onClick={() => handleItemCheck(item)}
            />
            <TitleTable>{item.donation_id}</TitleTable>
            <TextTableBold>{item.name}</TextTableBold>
            <TextTableBold>
               {' '}
               {item.typeIncription ? 'Evento' : 'Plataforma'}
            </TextTableBold>
            <DivFlexIcon>
               <img src={`${theme.pathIcons}/user.svg`} />
               <TextTable>{item.child_quantity}</TextTable>
            </DivFlexIcon>
            <DivFlexBetween>
               <ItemListPhoto
                  role="button"
                  src={
                     item.donor_photo_base64
                        ? `https://chosenapi.worldvision.co/image${item.donor_photo_base64}`
                        : `https://chosenapi.worldvision.co/image/static/images/sinImagen.png`
                  }
                  onClick={() => {
                     if (item.validation_status === 'pending') {
                        openModal(item);
                     }
                  }}
               />
               <DivFlexColumn>
                  <ButtonAproved
                     disabled={
                        item.validation_status !== 'pending' || isValidating
                     }
                     gotChecked={item.validation_status === 'approved'}
                     onClick={() => {
                        handleOpenModal(item, 'approved');
                     }}
                  >
                     <i className="fas fa-check"></i>
                  </ButtonAproved>

                  <ButtonDisapproved
                     type="button"
                     disabled={
                        item.validation_status !== 'pending' || isValidating
                     }
                     gotChecked={item.validation_status === 'reproved'}
                     onClick={() => {
                        handleOpenModal(item, 'reproved');
                     }}
                  >
                     <i className="fas fa-times"></i>
                  </ButtonDisapproved>
               </DivFlexColumn>
            </DivFlexBetween>
            <ButtonDiv>
               <ButtonList
                  onClick={() => {
                     history.push(
                        `${routes.details.replace(
                           ':donorID',
                           item.id.toString(),
                        )}`,
                     );
                  }}
               >
                  {t({ id: 'Abrir' })}
               </ButtonList>
            </ButtonDiv>
         </ItemList>
      );
   };

   return (
      <Fragment>
         <Container>
            <Content>
               <H1>{t({ id: 'Solicitudes' })}</H1>

               <Input
                  type="email"
                  name="user"
                  placeholder={t({
                     id: 'Pesquise por nome ou email',
                  }).toString()}
                  onChange={(event) => searchFilter(event)}
               />

               <DivFlex>
                  <FilterSelect
                     value={donationStatus}
                     onChangeValue={(optionValue) =>
                        setDonationStatus(optionValue.value)
                     }
                     label={t({ id: 'Pagamento' }).toString()}
                     options={[
                        { label: t({ id: 'Todos' }).toString(), value: 'all' },
                        {
                           label: t({ id: 'Pendente' }).toString(),
                           value: 'pendent',
                        },
                        {
                           label: t({ id: 'Recusado' }).toString(),
                           value: 'refused',
                        },
                        { label: t({ id: 'Pago' }).toString(), value: 'paid' },
                     ]}
                  />

                  <FilterSelect
                     value={validationStatus}
                     onChangeValue={(optionValue) =>
                        setValidationStatus(optionValue.value)
                     }
                     label={t({ id: 'Foto' }).toString()}
                     options={[
                        { label: t({ id: 'Todos' }).toString(), value: 'all' },
                        {
                           label: t({ id: 'Pendente' }).toString(),
                           value: 'pending',
                        },
                        {
                           label: t({ id: 'Recusado' }).toString(),
                           value: 'reproved',
                        },
                        {
                           label: t({ id: 'approved' }).toString(),
                           value: 'approved',
                        },
                     ]}
                  />

                  <LineVertical />

                  <Filter onClick={cleanFilters}>
                     {t({ id: 'Limpar' })}
                     <img src={`${theme.pathIcons}/close.svg`} />
                  </Filter>
               </DivFlex>

               <LineHorizontal />

               <List>
                  <HeaderDivGrid>
                     <Checkbox checked={allChecked} onClick={handleCheckAll} />

                     <TitleTable>{t({ id: 'ID' })}</TitleTable>
                     <TitleTable>{t({ id: 'Nome' })}</TitleTable>
                     <TitleTable>{t({ id: 'PuntoInscripcion' })}</TitleTable>
                     <TitleTable>{t({ id: 'Criancas' })}</TitleTable>
                     <TitleTable>{t({ id: 'Foto' })}</TitleTable>

                     <ApprovePhotosButton
                        type="button"
                        disabled={selecteds.length === 0}
                        onClick={approvePhotos}
                     >
                        <DivFlex>
                           <TitleTableDestaque>
                              {t({ id: 'Aprovar fotos' })}
                           </TitleTableDestaque>
                           <img src={`${theme.pathIcons}/image.svg`} />
                        </DivFlex>
                     </ApprovePhotosButton>
                  </HeaderDivGrid>
                  {loadingStatus === 'ok' && result.map(renderAprroval)}

                  {loadingStatus === 'loading' && (
                     <LoadingContainer>
                        <ReactLoading
                           type="spin"
                           color={theme.colors.brand.primary.main}
                        />
                     </LoadingContainer>
                  )}
                  {loadingStatus === 'ok' && result.length === 0 && (
                     <NoResult
                        label={t({ id: 'Nenhum resultado' }).toString()}
                     />
                  )}
               </List>
               {loadingStatus === 'ok' && lastPage > 1 && (
                  <Pagination
                     pg={pg}
                     setPg={setPg}
                     lastPage={lastPage}
                     total={paginate.total}
                  />
               )}
            </Content>

            <ConfirmationApproveOrReproveDonor
               isOpen={modalOpen}
               donorName={donorSelected?.name}
               onClose={() => setModalOpen(false)}
               status={actionSelected}
               onConfirmation={() => {
                  handleApproval(
                     [donorSelected],
                     actionSelected === 'approved',
                  );
                  setModalOpen(false);
               }}
            />
         </Container>

         <ApprovalPhotosModal isVisible={photoListOpen}>
            <PhotoContainer>
               <PhotoListContainer>
                  <ModalCloseButton
                     onClick={() => {
                        setPhotoListOpen(false);
                     }}
                  />

                  <PhotoListTitle>
                     {t({ id: 'Aprovacao de Fotos' })}
                  </PhotoListTitle>
                  <PhotoListSubtitle>
                     {t({
                        id: 'Todas as fotos ja estao selecionadas Clique para desfazer a selecao das fotos que nao estao de acordo com o criterio de aprovacao',
                     })}
                  </PhotoListSubtitle>
                  <PhotoList>{items.map(renderPhotoItem)}</PhotoList>
                  <PhotoListFooter>
                     <PhotoListFooterButtonCounter>
                        {selecteds.length} {t({ id: 'fotos selecionadas' })}
                     </PhotoListFooterButtonCounter>
                     <PhotoListFooterButton
                        disabled={
                           selecteds.length === 0 ||
                           photoApproveStatus === 'loading'
                        }
                        onClick={approveSelectedPhotos}
                     >
                        {t({ id: 'Aprovar Fotos Selecionadas' })}
                     </PhotoListFooterButton>
                  </PhotoListFooter>
               </PhotoListContainer>
            </PhotoContainer>
         </ApprovalPhotosModal>

         <ApprovalPhotosModal isVisible={photoModalOpen}>
            <PhotoContainer>
               <PhotoRelativeContainer>
                  <ModalCloseButton
                     type="button"
                     onClick={() => {
                        setPhotoModalOpen(false);
                     }}
                  />

                  <Photo
                     src={
                        selectedItem
                           ? selectedItem.donor_photo_base64
                              ? `https://chosenapi.worldvision.co/image${selectedItem.donor_photo_base64}`
                              : `https://chosenapi.worldvision.co/image/static/images/sinImagen.png`
                           : `http://18.206.163.109/image/static/images/sinImagen.png`
                     }
                  />

                  <PhotoApproveButtons>
                     <PhotoApproveButton
                        positive
                        onClick={() => {
                           setPhotoModalOpen(false);
                           handleApproval([selectedItem], true);
                        }}
                        disabled={validatingUsers.includes(
                           selectedItem?.donor_media_id,
                        )}
                     />

                     <PhotoApproveButton
                        onClick={() => {
                           setPhotoModalOpen(false);
                           handleApproval([selectedItem], false);
                        }}
                        disabled={validatingUsers.includes(
                           selectedItem?.donor_media_id,
                        )}
                     />
                  </PhotoApproveButtons>
               </PhotoRelativeContainer>
            </PhotoContainer>
         </ApprovalPhotosModal>
      </Fragment>
   );
};

const ApprovalPhotosModal = styled.div<ModalProps>(
   ({ isVisible }) => css`
      position: fixed;
      top: 0;
      z-index: 1001;
      background-color: #6c6f77a2;
      opacity: ${isVisible ? 1 : 0};
      pointer-events: ${isVisible ? 'auto' : 'none'};
      transition: ease all 0.2s;
      width: 100%;
   `,
);

const ButtonDiv = styled.div`
   text-align: right;
`;

const LoadingContainer = styled.div`
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
`;

const ItemListPhoto = styled.img`
   cursor: pointer;
   width: 120px;
   height: 88px;
   object-fit: cover;
`;

const ModalCloseButton = styled.button`
   width: 30px;
   height: 30px;

   position: absolute;
   right: -62px;
   top: 5px;
   z-index: 1010;

   background: none;
   background-size: 23px;
   background-repeat: no-repeat;
   background-image: url('/assets/images/icons/checkboxFalseInverse.svg');
   background-position: center;
`;

const PhotoRelativeContainer = styled.div`
   position: relative;
   display: flex;
   flex-direction: column;
   align-items: center;
`;

const PhotoContainer = styled.div`
   position: relative;
   display: flex;
   flex-direction: column;
   width: 100%;
   height: 100vh;
   justify-content: center;
   align-items: center;
`;

const PhotoListContainer = styled.div`
   position: relative;
   width: 800px;
   background: ${(props) => props.theme.neutral.white};
   box-shadow: 0px 1px 0px #e2e2ea;
   border-radius: 8px;
   padding: 32px;
`;

const PhotoListTitle = styled.h1`
   margin: 0;
   margin-bottom: 16px;

   line-height: ${(props) => props.theme.text.heading1.lineHeight};
   font-family: ${(props) => props.theme.text.heading1.family};
   font-size: ${(props) => props.theme.text.heading1.size};
   font-weight: ${(props) => props.theme.text.heading1.weight};

   color: ${(props) => props.theme.neutral.main};
`;

const PhotoListSubtitle = styled.p`
   margin: 0;
   margin-bottom: 64px;

   line-height: ${(props) => props.theme.text.text1.lineHeight};
   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};

   color: ${(props) => props.theme.neutral.sub};
`;

const PhotoList = styled.div`
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   width: 100%;
   max-height: 360px;
   overflow: auto;
   margin-bottom: 32px;
   padding: 0;
`;

const PhotoListItemWrapper = styled.button<{ checked: boolean }>`
   position: relative;

   background: none;
   outline: none;
   border: none;
   padding: 0;

   width: 18%;
   margin: 0 1% 24px;
   height: 93px;

   opacity: ${(props) => (props.checked ? 1 : 0.32)};

   &:disabled {
      opacity: 0.32;
      cursor: no-drop;
   }

   @media (max-width: 991px) {
      width: 23%;
      margin: 0 1% 24px;
      height: 120px;
   }

   @media (max-width: 767px) {
      width: 31%;
      margin: 0 1% 24px;
      height: 120px;
   }
`;

const PhotoListItem = styled.img`
   width: 100%;
   height: 100%;
   object-fit: cover;
`;

const PhotoListItemIndicator = styled.div`
   position: absolute;
   bottom: 8px;
   right: 8px;
   width: 24px;
   height: 24px;
   background-color: ${(props) => props.theme.feedback.success.main};
   border-radius: 50%;
   color: ${(props) => props.theme.neutral.white};
   display: flex;
   align-items: center;
   justify-content: center;

   i {
      font-size: 12px;
   }
`;

const ReprovedPhotoListItemIndicator = styled(PhotoListItemIndicator)`
   background-color: ${(props) => props.theme.feedback.error.main};
   color: ${(props) => props.theme.neutral.white};
`;

const PhotoListFooter = styled.footer`
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
`;

const PhotoListFooterButtonCounter = styled.p`
   margin: 0;

   line-height: ${(props) => props.theme.text.text1.lineHeight};
   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};

   color: ${(props) => props.theme.neutral.sub};
`;

const PhotoListFooterButton = styled.button`
   padding: 16px 24px;
   border-radius: 90px;
   background-color: ${(props) => props.theme.feedback.success.main};

   line-height: ${(props) => props.theme.text.text1.lineHeight};
   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};

   color: ${(props) => props.theme.neutral.white};

   &:disabled {
      cursor: no-drop;
      opacity: 0.5;
   }
`;

const Photo = styled.img`
   width: 800px;
   height: 600px;
   max-width: 100%;
   object-fit: cover;
   margin-bottom: 24px;
`;

const PhotoApproveButtons = styled.div`
   display: flex;
   flex-direction: row;
`;

const getIcon = (positive: boolean, inverse = false) => {
   if (positive) {
      return inverse
         ? '/assets/images/icons/checkboxTrueInverse.svg'
         : '/assets/images/icons/checkboxTrue.svg';
   }

   return inverse
      ? '/assets/images/icons/checkboxFalseInverse.svg'
      : '/assets/images/icons/checkboxFalse.svg';
};

const PhotoApproveButton = styled.button<{ positive?: boolean }>`
   background: #fcfcfd;
   box-sizing: border-box;
   border-radius: 200px;

   width: 56px;
   height: 56px;

   background-image: url('${(props) => getIcon(props.positive ?? false)}');
   background-size: contain;
   background-position: center;
   background-repeat: no-repeat;
   background-size: 19px;

   &:disabled {
      opacity: 0.5;
      cursor: no-drop;
   }

   &:not(:last-child) {
      margin-right: 40px;
   }

   transition: all 0.2s;

   :active,
   &:hover,
   &:focus {
      background-color: ${(props) =>
         props.positive
            ? props.theme.feedback.success.main
            : props.theme.feedback.error.main};
      background-image: url('${(props) =>
         getIcon(props.positive ?? false, true)}');
   }
`;

const Content = styled.div(
   ({ theme }) => css`
      min-height: 100vh;
      padding-top: 56px;
      flex-direction: column;
      align-items: center;
      display: flex;
      margin-bottom: ${theme.spacing.xl};
   `,
);

const H1 = styled.h1(
   ({ theme }) => css`
      padding-bottom: ${theme.spacing.xl};
      font-size: ${theme.text.heading1.size};
      color: ${theme.neutral.main};
      font-family: ${theme.text.heading1.family};
      font-weight: ${theme.text.heading1.weight};
   `,
);

const Input = styled.input(
   ({ theme }) => css`
      border: 1.2px solid ${theme.neutral.lightest};
      margin: ${theme.spacing.xs} 0 ${theme.spacing.md};
      padding: ${theme.spacing.sm} ${theme.spacing.md};
      border-radius: ${theme.spacing.xs};
      display: block;
      width: 100%;

      line-height: ${theme.text.text1.lineHeight};
      font-family: ${theme.text.text1.family};
      font-size: ${theme.text.text1.size};
      font-weight: ${theme.text.text1.weight};

      :focus {
         outline-color: ${theme.colors.brand.secondary.main};
      }
   `,
);

const LineVertical = styled.div(
   ({ theme }) => css`
      background-color: ${theme.neutral.lightest};
      margin-right: ${theme.spacing.lg};
      height: 32px;
      width: 2px;
   `,
);

const LineHorizontal = styled.div(
   ({ theme }) => css`
      background-color: ${theme.neutral.lightest};
      margin: ${theme.spacing.xl} 0;
      width: 100%;
      height: 2px;
   `,
);

const Filter = styled.button(
   ({ theme }) => css`
      display: flex;
      align-items: center;
      font-family: ${theme.text.title.family};
      font-size: ${theme.text.title.size};
      line-height: ${theme.text.title.lineHeight};
      font-weight: ${theme.text.title.weight};
      color: ${theme.neutral.lightness};
      background: transparent;

      :nth-last-child(n + 2) {
         padding-right: ${theme.spacing.lg};
      }

      > span {
         color: ${theme.colors.brand.secondary.main};
         padding: 0 ${theme.spacing.xs};
      }
   `,
);

const DivGrid = styled.div(
   ({ theme }) => css`
      grid-template-columns: 0.5fr 2fr 5fr 2fr 1fr 2fr 1fr;
      margin-bottom: ${theme.spacing.md};
      padding: ${theme.spacing.sm} ${theme.spacing.md};
      width: 100%;
      grid-gap: 20px;
      align-items: center;
      display: grid;
   `,
);

const HeaderDivGrid = styled(DivGrid)`
   grid-template-columns: 0.5fr 2fr 4.5fr 2.2fr 1fr 1fr 2fr;
   grid-gap: 17px;
`;

const TitleTable = styled.p(
   ({ theme }) => css`
      line-height: ${theme.text.text1.lineHeight};
      font-family: ${theme.text.text1.family};
      font-size: ${theme.text.text1.size};
      font-weight: ${theme.text.text1.weight};
      color: ${theme.neutral.lightness};
   `,
);

const TitleTableDestaque = styled.p(
   ({ theme }) => css`
      line-height: ${theme.text.heading2.lineHeight};
      font-family: ${theme.text.heading2.family};
      font-size: ${theme.text.title.size};
      font-weight: ${theme.text.heading2.weight};
      color: ${theme.colors.brand.secondary.main};
      padding-right: ${theme.spacing.xs};
   `,
);

const TextTableBold = styled(TitleTable)(
   ({ theme }) => css`
      color: ${theme.neutral.main};
      font-weight: ${theme.text.title.weight};
   `,
);

const TextTable = styled(TitleTable)(
   ({ theme }) => css`
      color: ${theme.neutral.main};
   `,
);

const ItemList = styled(DivGrid)(
   ({ theme }) => css<{ checked: boolean }>`
      border: 1px solid
         ${(props) =>
            props.checked ? theme.colors.brand.secondary.main : 'transparent'};
      background-color: ${theme.neutral.white};
      margin-bottom: ${theme.spacing.xs};
      transition: all ease 0.2s;
      box-shadow: 0px 1px 0px ${theme.neutral.lightest};
      border-radius: 8px;
      transition: border ease 0.3s;
      will-change: border;

      &:hover {
         border: 1px solid ${theme.colors.brand.secondary.main};
      }
   `,
);

const List = styled.div(
   ({ theme }) => css`
      width: 100%;
      > :last-child {
         margin-bottom: ${theme.spacing.xl};
      }
   `,
);

const ButtonList = styled.button(
   ({ theme }) => css`
      background: rgba(255, 95, 0, 0.08);
      padding: ${theme.spacing.xs} ${theme.spacing.sm};
      font-family: ${theme.text.heading2.family};
      color: ${theme.colors.brand.primary.main};
      font-weight: ${theme.text.title.weight};
      font-size: ${theme.text.title.size};
      border-radius: ${theme.spacing.xxl};
      transition: all ease 0.3s;

      &:hover {
         color: ${theme.neutral.white};
         background: ${theme.colors.brand.primary.main};
      }
   `,
);

const ButtonAproved = styled.button<ButtonAprovationProps>(
   ({ theme, gotChecked }) => css`
      border: 1px solid ${theme.neutral.lightest};
      background: ${gotChecked
         ? theme.feedback.success.main
         : theme.neutral.background};

      color: ${gotChecked ? theme.neutral.white : theme.neutral.main};
      border-radius: 100px;
      height: 28px;
      width: 28px;

      transition: all 0.2s;

      :active,
      &:hover,
      &:focus {
         background-color: ${theme.feedback.success.main};
         color: ${theme.neutral.white};
      }
      &:disabled {
         cursor: no-drop;
         opacity: 0.5;
         &:hover {
            background-color: ${gotChecked
               ? theme.feedback.success.main
               : theme.neutral.background};
            color: ${gotChecked ? theme.neutral.white : theme.neutral.main};
         }
      }
   `,
);

const ButtonDisapproved = styled.button<ButtonAprovationProps>(
   ({ theme, gotChecked }) => css`
      border: 1px solid ${theme.neutral.lightest};
      background: ${gotChecked
         ? theme.feedback.error.main
         : theme.neutral.background};
      color: ${gotChecked ? theme.neutral.white : theme.neutral.main};
      border-radius: 100px;
      height: 28px;
      width: 28px;

      transition: all 0.2s;

      &:active,
      &:hover,
      &:focus {
         background-color: ${theme.feedback.error.main};
         color: ${theme.neutral.white};
      }
      &:disabled {
         cursor: no-drop;
         opacity: 0.5;
         &:hover {
            background-color: ${gotChecked
               ? theme.feedback.error.main
               : theme.neutral.background};
            color: ${gotChecked ? theme.neutral.white : theme.neutral.main};
         }
      }
   `,
);

const ApprovePhotosButton = styled.button`
   outline: none;
   border: none;
   background: none;
   transition: all ease 0.2s;
   &:disabled {
      opacity: 20%;
   }
`;

const DivFlex = styled.div`
   display: flex;
`;

const DivFlexColumn = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: space-around;
`;

const DivFlexBetween = styled.div`
   display: flex;
   justify-content: space-between;
`;

const DivFlexIcon = styled.div(
   ({ theme }) => css`
      display: flex;
      align-items: center;

      img {
         margin-right: ${theme.spacing.sm};
      }
   `,
);

export default Approvals;
