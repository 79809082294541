import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import Spacer from 'components/Spacer';
import Input, { InputRow, InputWrapper } from 'components/Input';
import Spinner from '../../components/spinner'; 


import axios from 'axios';



const financialInstitutionServices = () => {
  return axios.get(
      baseURL + 'payment_metods_pse'
  );
}

const getIpClientServices = () => {
    return axios.get(
      'https://api.ipify.org?format=json'
  );
}


const registerPayPSE = (data) => {
  return axios.post(
      baseURL + 'registerpayPSE',
      data,
  );
}

const baseURL = process.env.REACT_APP_API_URL

function createSelectOptions(elem, options, labelsAndKeys = { label: "name", value: "id", description: "description" }) {
    const { label, value, description} = labelsAndKeys;
    elem.options.length = 0;
    const tempOptions = document.createDocumentFragment();

    options.forEach(option => {
        const optValue = option[value];
        const optLabel = option[label] ?? option[description];
        const opt = document.createElement('option');
        opt.value = optValue;
        opt.textContent = optLabel;
        tempOptions.appendChild(opt);
    });

    elem.appendChild(tempOptions);
}

const CheckoutPse = (props) => {
    const mp = new MercadoPago(process.env.REACT_APP_MEPA_PUBLIC_KEY); 
    const formElement = useRef();
    const [loading, setLoading] = useState(false);
    


    useEffect(() => {

        let valueChilds = parseInt(props.data) * 50000;   

        let amount = document.getElementById('transactionAmount');
        amount.value = valueChilds;
        
        (async function getIdentificationTypes() {
        try {
            const identificationTypes = await mp.getIdentificationTypes();
            const identificationTypeElement = document.getElementById('form-checkout__identificationType');

            createSelectOptions(identificationTypeElement, identificationTypes);
        } catch (e) {
            return console.error('Error getting identificationTypes: ', e);
        }
        })();
        
        (async function getFinancialInstitution() {
        try {
            const financialInstitution = await financialInstitutionServices();
            const financialInstitutionElement = document.getElementById('form-checkout__financial_institution');

            createSelectOptions(financialInstitutionElement, financialInstitution.data);
        } catch (e) {
            return console.error('Error getting identificationTypes: ', e);
        }
        })();

        (async function getIpClient() {
            try {
            const ipElement = document.getElementById('ip');            
            const ipClient = await getIpClientServices()
                .then((body) => {
                    ipElement.value = body.data.ip
                })
            } catch (e) {
            return console.error('Error getting identificationTypes: ', e);
        }
        })();              

    }, [props])

    
    const redirectPse = (event) => {           
        setLoading(true);
        event.preventDefault();
        var payment_data = {
            transaction_amount: parseInt(document.getElementById('transactionAmount').value),
            description: 'Elegido PSE',
            payment_method_id: 'pse',
            payer: {
                entity_type: 'individual',
                email: document.getElementById('form-checkout__email').value,
                identification: {
                    type: document.getElementById('form-checkout__identificationType').value,
                    number: document.getElementById('form-checkout__identificationNumber').value,
                }
            },
            additional_info: {
                ip_address: document.getElementById('ip').value,
            },
            transaction_details: {
                financial_institution: document.getElementById('form-checkout__financial_institution').value,
            },
            callback_url: 'https://elegido.worldvision.co/thanks'
        };
        
        registerPayPSE(payment_data)
            .then((res) => {
                props.onCallback({ res, payment_data });
                setLoading(false);
            })
            .catch((e)=>{
            console.log("el consumo del servicio al back fallo", e)
            })
        
    }


    return (  
        <LoadingContainer>
             <form id="form-checkout" action="/process_payment" method="post" ref={formElement}>
                <div>        

                    <Input id="form-checkout__email" name="email" type="text" placeholder="Correo Electronico"/>
                    <Spacer bottom={16} />  

                    <Label for="financial_institution">Tipo y Numero de Documento</Label>                    
                    <InputRow>
                        <InputWrapper style={{ maxWidth: '20%' }}>
                            <SelectWrapper
                                id="form-checkout__identificationType"
                                name="identificationType"
                            >
                                <option value="N.N." disabled selected>Tipo de documento</option>
                            </SelectWrapper>
                        </InputWrapper>
                        <Spacer right={16} />
                        <InputWrapper style={{ maxWidth: '70%' }}>
                            <Input type="text" id="form-checkout__identificationNumber" name="identificationNumber" placeholder="Número de documento" />
                        </InputWrapper>
                    </InputRow>
                    <Spacer bottom={16} />           
                    <Label for="financial_institution">Entidad Bancaria</Label>
                    <div>                        
                        <SelectWrapper id="form-checkout__financial_institution" name="financial_institution" type="text"></SelectWrapper>
                    </div>
                    <Spacer bottom={16} />              

                </div>

                <div>
                    <div>
                        <input type="hidden" name="transactionAmount" id="transactionAmount" value="50001"/>
                        <input type="hidden" name="description" id="description" value="Elegido"/>
                        <input type="hidden" name="ip" id="ip" value="0.0.0.0"/>
                        <br/>
                        <Button type="submit" onClick={redirectPse}>Donar</Button>
                        <SpinnerDIv>
                            {loading && <Spinner />}
                        </SpinnerDIv>
                    </div>
                </div>
            </form>
        </LoadingContainer>   
    )
}

export default CheckoutPse;


const LoadingContainer = styled.div`
   width: 100%;
   display: flex;
   justify-content: center;
`;

const Wrapper = styled.div`
   width: 100%;
   outline: none;
   border: 1.2px solid ${(props) => props.theme.neutral.lightest};
   border-radius: 8px;
   height: 60px;
   padding: 16px;


   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   &::placeholder {
      color: ${(props) => props.theme.neutral.lightest};
   }

   &:focus {
      border-color: ${(props) => props.theme.colors.brand.secondary.main};
   }
 
`;

const SelectWrapper = styled.select`
    color:  #8D8F96;
    margin-top: 7px;
    width: 100%;
    outline: none;
    border: 1.2px solid ${(props) => props.theme.neutral.lightest};
    border-radius: 8px;
    height: 60px;


    font-family: ${(props) => props.theme.text.text1.family};
    font-size: ${(props) => props.theme.text.text1.size};
    font-weight: ${(props) => props.theme.text.text1.weight};
    line-height: ${(props) => props.theme.text.text1.lineHeight};

    &::placeholder {
      color: ${(props) => props.theme.neutral.lightest};
   }

   &:focus {
      border-color: ${(props) => props.theme.colors.brand.secondary.main};
   }
`

const Button = styled.button`
    font-size: 18px;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    border-radius: 90px;
    width: 90%;
    max-width: 380px;
    justify-content: center;
    margin: 7%;

    background:  ${(props) => props.theme.colors.brand.primary.main};
    font-family: ${(props) => props.theme.text.text1.family};
    color: ${(props) => props.theme.neutral.white};

`

const SpinnerDIv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const Label = styled.label`
   display: flex;
   justify-content: center;

   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   color: ${(props) => props.theme.neutral.sub};
`