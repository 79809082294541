import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Link, useParams } from 'react-router-dom'
import { useFormatMessage } from 'react-intl-hooks'
import theme from '../styles/theme'
import routes from '../routes/routes.json'
import { useDispatch, useSelector } from 'react-redux'
import {
   listRevelationDetails,
   PackageInfoProps,
} from 'services/slices/listRevelationDetails'
import useRegionalization from 'hooks/useRegionalization'
import { RootState } from 'services/store'
import ReactLoading from 'react-loading'
import BadgeStatus from '../components/BadgeStatus'
import 'react-datepicker/dist/react-datepicker.css'
import Container from 'components/Container'
import NoResult from 'components/NoResult'
import { useHistory } from 'react-router'
import { zipGeneratorChildId } from 'services/slices/zipGeneratorChildId'
import { toast } from 'react-toastify'
type isMexicoProps = {
   isMexico?: string
}
const RevelationDetails = (): JSX.Element => {
   const t = useFormatMessage()
   const history = useHistory()
   const { country } = useRegionalization()
   const { RevelationID } = useParams<{ RevelationID: string }>()
   const dispatch = useDispatch()
   const [isDownloading, setIsDownloading] = useState<boolean>(false)

   useEffect(() => {
      dispatch(
         listRevelationDetails({ revelationId: RevelationID, pg: 1, pp: 15 }),
      )
   }, [])

   const { result, loadingStatus } = useSelector((state: RootState) => ({
      result: state.listRevelationDetails.results,
      loadingStatus: state.listRevelationDetails.status,
   }))

   const [items, setItems] = useState<PackageInfoProps[]>([])

   useEffect(() => {
      if (result?.package_info) {
         setItems(result.package_info.package_donors)
      }
   }, [result])

   const handleDownloadPhotos = async () => {
      setIsDownloading(true)
      const mockChildId = [3, 4, 5]

      const response: any = await dispatch(
         zipGeneratorChildId({ children_id: mockChildId }),
      )

      if (zipGeneratorChildId.fulfilled.match(response)) {
         window.open(response.payload.link)
      } else {
         toast.error(t({ id: 'Algo deu errado no seu download' }))
      }
      setIsDownloading(false)
   }
   const renderAprroval = (item: PackageInfoProps, index: number) => {
      return (
         <ItemList key={index}>
            <TitleTable>{item.donor_id}</TitleTable>
            <TextTableBold>{item.name}</TextTableBold>
            <TextTable>{t({ id: item.revelation_type })}</TextTable>
            <TextTable>{item.tracking_code}</TextTable>
            <TextTable>
               <BadgeStatus status={item.revelation_sent ? 'yes' : 'not'} />
               {item.revelation_sent ? t({ id: 'yes' }) : t({ id: 'not' })}
            </TextTable>
            {country !== 'mx' && (
               <TextTable>
                  <BadgeStatus status={item.revelation_sent ? 'yes' : 'not'} />
                  {item.revelation_sent ? t({ id: 'yes' }) : t({ id: 'not' })}
               </TextTable>
            )}
            <ButtonContainer>
               <ButtonList
                  onClick={() => {
                     history.push(
                        `${routes.details.replace(
                           ':donorID',
                           item.donor_id.toString(),
                        )}`,
                     )
                  }}
               >
                  {t({ id: 'Editar' })}
               </ButtonList>
            </ButtonContainer>
         </ItemList>
      )
   }

   return (
      <Container>
         <Content>
            <HeaderContent>
               <HeaderContentLeft>
                  <BackButton to={routes.revelations}>
                     <img src={`${theme.pathIcons}/arrowLeft.svg`} />
                     {t({ id: 'Voltar' })}
                  </BackButton>
                  <H1>
                     {loadingStatus === 'ok' && result?.package_info.title}
                  </H1>
                  <DivFlexAround>
                     <img src={`${theme.pathIcons}/calendar.svg`} />
                     <EventDate>
                        {result.package_info?.date
                           ? new Date(result.package_info.date)
                                .toLocaleString()
                                .substr(0, 16)
                           : '-'}
                     </EventDate>
                  </DivFlexAround>
               </HeaderContentLeft>

               <HeaderContentRight>
                  <ApprovePhotosButton type="button">
                     <DivFlex>
                        <DownloadPhotosButton
                           disabled={items?.length === 0 || isDownloading}
                           onClick={handleDownloadPhotos}
                        >
                           {t({
                              id: `${
                                 isDownloading
                                    ? t({ id: 'Baixando fotos' })
                                    : t({ id: 'Baixar fotos' })
                              }`,
                           })}

                           <img src={`${theme.pathIcons}/downloadIcon.svg`} />
                        </DownloadPhotosButton>
                     </DivFlex>
                  </ApprovePhotosButton>
               </HeaderContentRight>
            </HeaderContent>

            <LineHorizontal />

            <List>
               <DivGrid isMexico={country}>
                  <TitleTable>{t({ id: 'ID' })}</TitleTable>
                  <TitleTable>{t({ id: 'Nome' })}</TitleTable>
                  <TitleTable>{t({ id: 'Revelacao' })}</TitleTable>
                  <TitleTable>{t({ id: 'Código' })}</TitleTable>
                  <TitleTable>{t({ id: 'Revelado' })}</TitleTable>
                  {country !== 'mx' && (
                     <TitleTable>{t({ id: 'Enviado' })}</TitleTable>
                  )}
               </DivGrid>
               {items && items.map(renderAprroval)}
               {loadingStatus === 'loading' && (
                  <LoadingContainer>
                     <ReactLoading
                        type="spin"
                        color={theme.colors.brand.primary.main}
                     />
                  </LoadingContainer>
               )}
               {loadingStatus === 'ok' && items?.length === 0 && (
                  <NoResult label={t({ id: 'Nenhum resultado' }).toString()} />
               )}
            </List>
         </Content>
      </Container>
   )
}

const EventDate = styled.span(
   ({ theme }) => css`
      color: ${theme.neutral.main};
      font-size: ${theme.text.text1.size};
      background-color: transparent;
      font-family: ${theme.text.text1.family};
   `,
)

const ButtonContainer = styled.div`
   text-align: right;
`

const LoadingContainer = styled.div`
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
`

const Content = styled.div`
   min-height: 100vh;
   padding-top: 56px;
   flex-direction: column;
   align-items: center;
   display: flex;
`

const H1 = styled.h1(
   ({ theme }) => css`
      font-size: ${theme.text.heading1.size};
      color: ${theme.neutral.main};
      font-family: ${theme.text.heading1.family};
      font-weight: ${theme.text.heading1.weight};
   `,
)

const LineHorizontal = styled.div(
   ({ theme }) => css`
      background-color: ${theme.neutral.lightest};
      margin: ${theme.spacing.xl} 0;
      width: 100%;
      height: 2px;
   `,
)

const DivGrid = styled.div<isMexicoProps>(
   ({ theme, isMexico }) => css`
      grid-template-columns: 1.4fr 2.4fr 1fr 0.7fr 1fr ${isMexico === 'mex'
            ? '1fr'
            : ''}1fr;
      margin-bottom: ${theme.spacing.md};
      padding: ${theme.spacing.sm} ${theme.spacing.md};
      width: 100%;
      grid-gap: 20px;
      align-items: center;
      display: grid;
   `,
)

const TitleTable = styled.p(
   ({ theme }) => css`
      line-height: ${theme.text.text1.lineHeight};
      font-family: ${theme.text.text1.family};
      font-size: ${theme.text.text1.size};
      font-weight: ${theme.text.text1.weight};
      color: ${theme.neutral.lightness};
      text-transform: capitalize;
   `,
)

const TitleTableDestaque = styled.p(
   ({ theme }) => css`
      line-height: ${theme.text.heading2.lineHeight};
      font-family: ${theme.text.heading2.family};
      font-size: ${theme.text.title.size};
      font-weight: ${theme.text.heading2.weight};
      color: ${theme.colors.brand.secondary.main};
      padding-right: ${theme.spacing.xs};
   `,
)

const TextTableBold = styled(TitleTable)(
   ({ theme }) => css`
      color: ${theme.neutral.main};
      font-weight: ${theme.text.title.weight};
   `,
)

const TextTable = styled(TitleTable)(
   ({ theme }) => css`
      color: ${theme.neutral.main};
   `,
)

const ItemList = styled(DivGrid)<isMexicoProps>(
   ({ theme, isMexico }) => css`
      background-color: ${theme.neutral.white};
      margin-bottom: ${theme.spacing.xs};
      box-shadow: 0px 1px 0px ${theme.neutral.lightest};
      border-radius: 8px;
      padding: 30px 24px;
      border: 1px solid transparent;
      transition: border ease 0.3s;
      will-change: border;
      text-transform: capitalize;
      grid-template-columns: 1.5fr 3fr 1fr 1fr 1fr ${isMexico === 'mex'
            ? '1fr'
            : ''}1.2fr;
      &:hover {
         border: 1px solid ${theme.colors.brand.secondary.main};
      }
   `,
)

const List = styled.div(
   ({ theme }) => css`
      width: 100%;
      > :last-child {
         margin-bottom: ${theme.spacing.xl};
      }
   `,
)

const ButtonList = styled.button(
   ({ theme }) => css`
      background: rgba(255, 95, 0, 0.08);
      padding: 12px ${theme.spacing.sm};
      font-family: ${theme.text.heading2.family};
      color: ${theme.colors.brand.primary.main};
      font-weight: ${theme.text.title.weight};
      font-size: ${theme.text.title.size};
      border-radius: 90px;
      text-decoration: none;
      transition: all ease 0.3s;

      &:hover {
         color: ${theme.neutral.white};
         background: ${theme.colors.brand.primary.main};
      }
   `,
)

const ApprovePhotosButton = styled.button`
   outline: none;
   border: none;
   background: none;
`

const DivFlex = styled.div`
   display: flex;
`

const DivFlexAround = styled.div`
   display: flex;
   align-items: center;

   > img {
      margin-right: 16px;
   }

   .react-datepicker__close-icon::after {
      background-color: #ff5f00 !important;
   }

   .react-datepicker__day--selected {
      background-color: #ff5f00 !important;
   }

   input[type='text'] {
      padding: 6px 20px;
   }

   .react-datepicker__day:hover {
      background: #ff7929 !important;
      color: white !important;
   }

   .react-datepicker__day--keyboard-selected {
      background: #ff7929 !important;
   }
`

const BackButton = styled(Link)(
   ({ theme }) => css`
      color: ${theme.neutral.light};
      font-size: ${theme.text.text1.size};
      font-family: ${theme.text.text1.family};
      line-height: ${theme.text.text1.lineHeight};
      font-weight: ${theme.text.text1.weight};

      text-decoration: none;
      display: flex;

      > img {
         margin-right: 11px;
      }
   `,
)

const HeaderContent = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
`

const HeaderContentLeft = styled.div(
   ({ theme }) => css`
      display: flex;
      align-items: center;

      > ${BackButton} {
         margin-right: ${theme.spacing.xl};
      }

      > ${H1} {
         margin-right: ${theme.spacing.xl};
      }
   `,
)

const HeaderContentRight = styled.div`
   display: flex;
   align-items: center;
`

const DownloadPhotosButton = styled.button(
   ({ theme }) => css`
      background-color: transparent;
      line-height: ${theme.text.heading2.lineHeight};
      font-family: ${theme.text.heading2.family};
      font-size: ${theme.text.title.size};
      font-weight: ${theme.text.heading2.weight};
      color: ${theme.colors.brand.secondary.main};
      padding-right: ${theme.spacing.xs};
      display: flex;
      align-items: center;

      &:disabled {
         opacity: 0.5;
         cursor: no-drop;
      }

      img {
         margin-left: ${theme.spacing.xs};
      }
   `,
)

export default RevelationDetails
