import { Regionalization } from '@types'
import constate from 'constate'
import { CountryCode } from 'libphonenumber-js'
import { useState } from 'react'

const [RegionalizationProvider, useRegionalization] = constate(() => {
   const aux: any = process.env.REACT_APP_LANGUAGE.toUpperCase()
   const [country, setCountry] = useState<string>(
      process.env.REACT_APP_LANGUAGE,
   )
   const [phoneCountry, setPhoneCountry] = useState<CountryCode>('CO')
   const [regionalization, setRegionalization] = useState<Regionalization>()

   return {
      country,
      setCountry,

      phoneCountry,
      setPhoneCountry,

      regionalization,
      setRegionalization,
   }
})

export { RegionalizationProvider }

export default useRegionalization
