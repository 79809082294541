import { useState } from 'react'
import constate from 'constate'

const [ModalProvider, useModal] = constate(() => {
   const [modalOpen, setModalOpen] = useState<boolean>(false)

   const showModal = () => {
      setModalOpen(true)
   }

   const hideModal = () => {
      setModalOpen(false)
   }

   return {
      modalOpen,
      setModalOpen,

      showModal,
      hideModal,
   }
})

export { ModalProvider }

export default useModal
