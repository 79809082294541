import React from 'react'
import { useFormatMessage } from 'react-intl-hooks'
import styled, { css } from 'styled-components'

type Props = {
   label: string
}

const Container = styled.div(
   ({ theme }) => css`
      text-align: center;
      font-size: ${theme.text.heading1.size};
      font-family: ${theme.text.heading2.family};
      font-weight: ${theme.text.text1.weight};
      padding: 30px 0;
   `,
)

const NoResult = ({ label }: Props) => {
   const t = useFormatMessage()

   return <Container>{t({ id: `${label}` })}</Container>
}

export default NoResult
