import Button, { ButtonRow } from 'components/Button'
import Instructions from 'components/Instructions'
import PhotoPicker, { ImgPicked } from 'components/PhotoPicker'
import Spacer from 'components/Spacer'
import { useState } from 'react'
import { useFormatMessage } from 'react-intl-hooks'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { chooseNewPhotoAgain } from 'services/slices/choosePhotoAgain'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import routes from '../routes/routes.json'
const instructions = [
   'Escolha uma foto bem iluminada, que se possa ver claramente o seu rosto',
   'De preferencia aquela foto que mostre seu sorriso Evite expor sua imagem ou cenarios inadequados',
   'A foto sera impressa para as criancas lhe escolherem, portanto selecione a imagem mais adequada e de melhor qualidade possivel',
]

const photos = [
   '/assets/photos/image_29.png',
   '/assets/photos/image_30.png',
   '/assets/photos/image_31.png',
]

const photosLimits = [
   'Selecione um arquivo com ate 10MB',
   'Formatos aceitos: JPG , JPEG e PNG',
]
type Values = {
   photo: ImgPicked
}

type CheckoutDataFormProps = {
   onSubmit: (values: Values) => void
}

const ChoosePhotoAgain = ({ onSubmit }: CheckoutDataFormProps): JSX.Element => {
   const t = useFormatMessage()
   const dispatch = useDispatch()

   const history = useHistory()
   const [filePicked, setFilePicked] = useState<ImgPicked>()
   const [isLoading, setIsLoading] = useState<boolean>(false)
   const { token } = useParams<{ token: string }>()
   const { id } = useParams<{ id: string }>()

   const handleSubmit = async (photo: ImgPicked) => {
      setIsLoading(true)

      const response: any = await dispatch(
         chooseNewPhotoAgain({
            token: token,
            photo_b64: photo.url,
            id: id,
         }),
      )
      if (chooseNewPhotoAgain.fulfilled.match(response)) {
         if (filePicked)
            toast.success(t({ id: 'Tudo certo' }), {
               autoClose: 2500,
            })
         setTimeout(() => {
            history.push('/')
         }, 2500)
      } else {
         toast.error(t({ id: 'Algo deu errado' }))
      }

      setIsLoading(false)
   }

   const renderPhoto = (path: string, index: number) => {
      return <Photo key={index} src={path} />
   }

   return (
      <Container>
         <H1>
            <p>{t({ id: 'Escolha sua foto' })}</p>
            {t({ id: 'novamente' })}
         </H1>
         <P>{t({ id: 'Observe atentamente as dicas' })}.</P>
         <Title>
            {t({ id: 'Escolha ou tire uma foto e nos envie no botao abaixo' })}
         </Title>
         <Spacer bottom={16} />

         <Subtitle>
            {t({
               id: 'Essa sera a foto que as criancas utilizarao para escolher voce, entao capriche, hein',
            })}
         </Subtitle>
         <Spacer bottom={40} />

         <Instructions instructions={instructions} />
         <Spacer bottom={40} />

         <Title>{t({ id: 'Exemplos de fotos aprovadas' })}</Title>
         <Spacer bottom={16} />

         <Photos>{photos.map(renderPhoto)}</Photos>
         <Spacer bottom={40} />

         <Title>{t({ id: 'Adicione sua foto' })}</Title>
         <Spacer bottom={28} />

         <PhotoPicker
            limits={photosLimits}
            previewUrl={filePicked?.url}
            onImgPicked={setFilePicked}
         />
         <Spacer bottom={40} />

         <ButtonRow>
            <Button
               type="submit"
               label={t({ id: 'Continuar' }).toString()}
               disabled={isLoading}
               onClick={() => handleSubmit(filePicked)}
            />
         </ButtonRow>
      </Container>
   )
}

export default ChoosePhotoAgain

const Container = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   max-width: 500px;
   margin: 0 auto;
   padding-bottom: 2rem;
`
const H1 = styled.h1`
   font-family: ${(props) => props.theme.text.heading1.family};
   font-weight: ${(props) => props.theme.text.heading1.weight};
   font-size: ${(props) => props.theme.text.heading1.size};
   line-height: ${(props) => props.theme.text.heading1.lineHeight};
   text-align: center;
   color: ${(props) => props.theme.neutral.main};
   padding-top: 56px;
`

const P = styled.p`
   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};
   line-height: ${(props) => props.theme.text.text1.lineHeight};
   color: ${(props) => props.theme.colors.input};
   padding-top: 16px;
`

const Title = styled.p`
   font-family: ${(props) => props.theme.text.title.family};
   font-size: ${(props) => props.theme.text.title.size};
   font-weight: ${(props) => props.theme.text.title.weight};
   line-height: ${(props) => props.theme.text.title.lineHeight};
   color: ${(props) => props.theme.neutral.main};
   margin: 0;
   padding-top: 64px;
`

const Subtitle = styled.p`
   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   color: ${(props) => props.theme.neutral.sub};

   margin: 0;
`

const Photos = styled.div`
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
`

const Photo = styled.img`
   flex: 1;
   height: 101px;
   object-fit: cover;
`
