import axios, { AxiosResponse, AxiosError } from 'axios'
import { toast } from 'react-toastify'
import fns from './storage'

const auth_secure_token = fns.getToken()

const headers = {
   Accept: 'application/json',
   'Content-Type': 'application/json',
   'Access-Control-Allow-Origin': '*',
}

const api = axios.create({
   baseURL: process.env.REACT_APP_API_URL,
   headers,
   proxy: false,
})

api.interceptors.request.use(
   (config) => {
      if (auth_secure_token) {
         try {
            config.headers.common['Auth-Secure-Token'] =
               auth_secure_token.access_token
         } catch (e) {
            console.error(e)
            //cookies.user.remove() remover token
         }
      }
      return config
   },
   (error) => Promise.reject(error),
)

const onResponse = (response: AxiosResponse): AxiosResponse => {
   //console.info(`[response] [${JSON.stringify(response)}]`)
   return response
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
   //  console.error(`[response error] [${JSON.stringify(error)}]`)
   if (error.response?.data.errors) {
      const validations = Object.entries(error.response.data.errors)

      validations.forEach((validation: Array<any>, _): any => {
         if (validation[1][0]) {
            error.message = validation[1][0]
            toast.error(validation[1][0])
         }
      })
   }

   if (error.response?.data.error?.validation) {
      const validations = Object.entries(error.response.data.error.validation)

      validations.forEach((validation: Array<any>, _): any => {
         if (validation[1][0]) {
            error.message = 'toast'
            toast.error(validation[1][0])
         }
      })
   }

   if (error.response.status === 401) {
      toast.error(error.response.data.message)
      setTimeout(() => {
         fns.clear()
         location.assign('/login')
      }, 3000)
   }

   return Promise.reject(error)
}

api.interceptors.response.use(onResponse, onResponseError)

export const externalApi = axios.create()

export default api
