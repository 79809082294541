import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import api from 'services/api'

// id: 1314503591
// status: "approved"
// status_detail: "accredited"

export type ApprovePayPops = {
    id: string,
    status: string
    status_detail: string
}


/**
 * 
 */
export const approvePay = createAsyncThunk(
    'approvePay',
    async () => {
        return await api.get('/products').then((res) => res.data)
    },
)

const approvePaySlice = createSlice({
    name: 'approvePay',
    initialState: {
        status: 'idle',
        response: {},
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(approvePay.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(
            approvePay.fulfilled,
            (state, action: PayloadAction<ApprovePayPops>) => {
                state.status = 'ok'
                state.response = action.payload
            },
        ),
            builder.addCase(approvePay.rejected, (state) => {
                state.status = 'rejected'
            })
    },
})

export default approvePaySlice.reducer
