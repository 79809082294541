import Footer from 'components/Footer'
import routes from '../routes/routes.json'
import { Fragment, useEffect, useState } from 'react'
import { useFormatMessage } from 'react-intl-hooks'
import { Link, useHistory } from 'react-router-dom'

import styled, { css } from 'styled-components'

const RecoveryPasswordConfirmation = (): JSX.Element => {
   const t = useFormatMessage()
   const history = useHistory()

   const toLogin = () => {
      history.push(routes.recovery)
   }

   return (
      <Fragment>
         <Content>
            <H1>{t({ id: 'Verifique seu e-mail' })}</H1>
            <Subtitle>
               {t({
                  id: 'Verifique se você recebeu um email contendo um link para cadastrar uma nova senha.',
               })}
            </Subtitle>

            <Form>
               <Submit onClick={toLogin} type="submit">
                  {t({
                     id: 'Reenviar email',
                  })}
               </Submit>

               <DivFlex>
                  <RecSenha to={routes.login}>
                     {t({ id: 'Voltar para login' })}
                  </RecSenha>
               </DivFlex>
            </Form>
         </Content>

         <Footer />
      </Fragment>
   )
}

const Content = styled.div(
   ({ theme }) => css`
      flex-direction: column;
      align-items: center;
      padding-top: ${theme.spacing.xl};
      min-height: 80vh;
      display: flex;
   `,
)

const H1 = styled.h1(
   ({ theme }) => css`
      padding-bottom: ${theme.spacing.xl};
      font-size: ${theme.text.heading1.size};
      color: ${theme.neutral.main};
      font-family: ${theme.text.heading1.family};
      font-weight: ${theme.text.heading1.weight};
   `,
)

const RecSenha = styled(Link)(
   ({ theme }) => css`
      font-family: ${theme.text.title.family};
      line-height: ${theme.text.title.lineHeight};
      color: ${theme.colors.brand.primary.main};
      font-size: ${theme.text.title.size};
      text-decoration: none;
   `,
)

const DivFlex = styled.div(
   ({ theme }) => css`
      padding-bottom: ${theme.spacing.xl};
      justify-content: center;
      display: flex;
      margin-top: ${theme.spacing.xl};
   `,
)

const Form = styled.form(
   ({ theme }) => css`
      box-shadow: '0px 1px 0px ${theme.neutral.lightest}';
      background: ${theme.neutral.white};
      border-radius: ${theme.spacing.xs};
      padding: ${theme.spacing.xl};
   `,
)

const Submit = styled.button(
   ({ theme }) => css`
      padding: ${theme.spacing.sm} ${theme.spacing.md};
      font-family: ${theme.text.title.family};
      border-radius: ${theme.spacing.xxl};
      background: ${theme.colors.brand.primary.main};
      color: ${theme.neutral.white};
      font-weight: ${theme.text.title.weight};
      border: none;
      width: 450px;
   `,
)

const Subtitle = styled.span(
   ({ theme }) => css`
      margin-bottom: ${theme.spacing.xl};
      font-family: ${theme.text.text1.family};
      line-height: ${theme.text.text1.lineHeight};
      max-width: 407px;
      text-align: center;
      color: ${theme.colors.input};
   `,
)

export default RecoveryPasswordConfirmation
