import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    code: null,
};

const idReferredSlice = createSlice({
    name: 'idReferred',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.code = action.payload;
        },
    },
});

export const { setUser } = idReferredSlice.actions;
export default idReferredSlice.reducer;