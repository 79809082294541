import styled, { css } from 'styled-components'

import { Property } from 'csstype'

type RowProps = {
   wrap?: Property.FlexWrap
   direction?: Property.FlexDirection
   alignItems?: Property.AlignItems
   justifyContent?: Property.JustifyContent
}

export const Row = styled.div<RowProps>`
   display: flex;
   width: 100%;
   flex-wrap: ${(props) => props.wrap ?? 'wrap'};
   flex-direction: ${(props) => props.direction ?? 'row'};
   align-items: ${(props) => props.alignItems ?? 'center'};
   justify-content: ${(props) => props.justifyContent ?? 'center'};

   margin-left: -15px;
   margin-right: -15px;
`

type ColProps = {
   xs?: number
   sm?: number
   md?: number
   lg?: number
   xl?: number
   xxl?: number
}

const ColWidths = {
   sm: 576,
   md: 768,
   lg: 992,
   xl: 1200,
   xxl: 1400,
}

export const Col = styled.div<ColProps>`
   width: ${(props) => props.xs ?? 100}%;

   padding: 0 15px;

   ${(props) =>
      Object.keys(ColWidths).map((_key) =>
         props[_key]
            ? css`
                 @media (min-width: ${ColWidths[_key]}px) {
                    width: ${props[_key]}%;
                 }
              `
            : undefined,
      )}
`

export const Text = () => {
   return <div style={{ justifyContent: '' }}></div>
}

export const ColPercent = (col: number): number => {
   if (col > 12) {
      throw new Error('Col number must be between 1 and 12')
   } else {
      return (100 / 12) * col
   }
}
