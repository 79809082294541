import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Actualiza el estado para mostrar el mensaje de error.
    console.log('ErrorBoundary - getDerivedStateFromError:', error);
    return { hasError: true, error };
  }

  
  componentDidCatch(error, info) {
    // Registra el error y la información del componente en la consola.
    console.error('ErrorBoundary - componentDidCatch:', error, info);
  }

  render() {
    if (this.state.hasError) {
      // Renderiza un mensaje de error personalizado.
      return (
        <div>
          <h2>¡Ups! Algo salió mal.</h2>
          <p>{this.state.error.message}</p>
          <p>Puedes consultar el registro para obtener más detalles.</p>
        </div>
      );
    }

    // Si no hay error, renderiza los componentes descendientes.
    return this.props.children;
  }
}

export default ErrorBoundary;