import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'services/api';

export type ResultsProps = {
   child_city: string;
   child_age: string;
   child_name: string;
   child_photo: string;
   child_video: string;
   digital_sending: string;
   letter_photo: string;
   revelation_type: string;
   tracking_code?: string;
};

interface listChosenChildInterface {
   status: string;
   results: [];
}

export const listChosenChild = createAsyncThunk(
   'listChosenChild',
   async ({ childId }: { childId: string }) => {
      return await api
         .get(`/revelations/child/${childId}`)
         .then((res) => res.data);
   },
);

const listChosenChildSlice = createSlice({
   name: 'listChosenChild',
   initialState: {
      status: 'idle',
      results: [],
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(
         listChosenChild.pending,
         (state, action: PayloadAction<listChosenChildInterface>) => {
            state.status = 'loading';
         },
      );
      builder.addCase(
         listChosenChild.fulfilled,
         (state, action: PayloadAction<listChosenChildInterface>) => {
            state.status = 'ok';
            state.results = action.payload.results;
         },
      ),
         builder.addCase(listChosenChild.rejected, (state, action) => {
            state.status = 'rejected';
         });
   },
});
export default listChosenChildSlice.reducer;
