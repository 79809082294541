import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import { Modal, Button, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';

import './css/ExcelUploaderStyled.css';
import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

const ExcelUploader: React.FC = () => {
   const [showModal, setShowModal] = useState(false);
   const [excelData, setExcelData] = useState<Array<Array<string | number>>>(
      [],
   );

   const onDrop = (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = (event) => {
         const binaryStr = event.target?.result;
         if (binaryStr) {
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const jsonData: Array<Array<string | number>> =
               XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            setExcelData(jsonData);
            setShowModal(true);
         }
      };
      reader.readAsBinaryString(file);
   };

   const { getRootProps, getInputProps } = useDropzone({ onDrop });

   const handleConfirm = () => {
      const datos = excelData.filter((subArreglo) => subArreglo.length > 0);
      const cabecera = datos.shift();
      console.log('Registros agregados:', datos);
      registerMasive(datos);
   };

   const registerMasive = async (data) => {
      try {
         await axios.post(baseURL + 'chosenRegisteMasive', data);
         toast.success('Registros agregados con éxito.');
         setShowModal(false);
      } catch (error) {
         toast.error('Hubo un error al agregar los registros.');
      }
   };

   const handleCancel = () => {
      setShowModal(false);
   };

   return (
      <div>
         <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            <p>
               Arrastra y suelta un archivo Excel aquí, o haz clic para
               seleccionar uno
            </p>
         </div>

         <Modal show={showModal} onHide={handleCancel}>
            <Modal.Header closeButton>
               <Modal.Title>Verifica los datos del archivo Excel</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Table striped bordered hover>
                  <thead>
                     <tr>
                        {excelData[0] &&
                           excelData[0].map((col, index) => (
                              <th key={index}>{col}</th>
                           ))}
                     </tr>
                  </thead>
                  <tbody>
                     {excelData.slice(1).map((row, rowIndex) => (
                        <tr key={rowIndex}>
                           {row.map((cell, cellIndex) => (
                              <td key={cellIndex}>{cell}</td>
                           ))}
                        </tr>
                     ))}
                  </tbody>
               </Table>
            </Modal.Body>
            <Modal.Footer>
               <Button variant="secondary" onClick={handleCancel}>
                  Cancelar
               </Button>
               <Button variant="primary" onClick={handleConfirm}>
                  Confirmar
               </Button>
            </Modal.Footer>
         </Modal>
      </div>
   );
};

export default ExcelUploader;
