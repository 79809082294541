import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import api from 'services/api'

export type ZipcodeRequest = {
   zipcode: string
}

export type AddressProps = {
   cep: string
   address_street: string
   address_neightborhood: string
   address_city: string
   address_state: string
}

type ZipcodeResponse = {
   address: AddressProps
}

export const zipcodeSearch = createAsyncThunk(
   'zipcode',
   async ({ zipcode }: ZipcodeRequest) => {
      return await api
         .get(`/search-zipcode?zipcode=${zipcode}`)
         .then((res) => res.data)
   },
)

const addressDefault: AddressProps = {
   cep: '',
   address_street: '',
   address_neightborhood: '',
   address_city: '',
   address_state: '',
}

const zipcodeSearchSlice = createSlice({
   name: 'zipcode',
   initialState: {
      status: 'idle',
      address: addressDefault,
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(zipcodeSearch.pending, (state) => {
         state.status = 'loading'
      })
      builder.addCase(
         zipcodeSearch.fulfilled,
         (state, action: PayloadAction<ZipcodeResponse>) => {
            state.status = 'ok'
            state.address = action.payload.address
         },
      )
      builder.addCase(zipcodeSearch.rejected, (state) => {
         state.status = 'rejected'
         state.address = addressDefault
      })
   },
})

export default zipcodeSearchSlice.reducer
