import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'services/api';

export interface DonorData {
   id: number;
   name: string;
   document: string;
   email: string;
   phone: {
      country: number;
      value: number;
   };
   ocupation: string;
   birthdate: string;
   gender: string;
   address_street: string;
   address_number: number;
   address_complement: string;
   address_zipcode: number;
   address_neightborhood: string;
   address_city: string;
   address_state: string;
   created_at: string;
   media_source: string;
   donor_photo_base64: string;
   validation_status: string;
   donation_id: number;
   event_status: string;
   simma_donor_id: number | null;
}

export interface DonationData {
   amount: number;
   child_amount: number;
   child_quantity: number;
   company_id: number;
   created_at: string;
   donation_status: string;
   donor_id: number;
   donor_media_id: number;
   event_id: number;
   extra_info: string;
   id: number;
   payment_date: string;
   payments_donation_id: number;
   revelation_amount: number;
   revelation_type: string;
   simma_payment_id: number;
   tracking_code: string;
   updated_at: string;
   donor_event_id: number;
}

export interface AppointmentsData {
   child_id: string;
   child_age?: string;
   child_city: string;
   child_name: string;
   child_photo: string;
   child_video: string;
   created_at: string;
   donation_id: number;
   donor_letter_revelation_group_id: string;
   extra_info: string;
   id: number;
   letter_photo: string;
   revelation_group_id: string;
   revelation_individual_id: string;
   simma_child_id: string;
   updated_at: string;
}

export interface DonorsDataRequest {
   appointments: AppointmentsData[];
   donor_data: DonorData;
   donation_data: DonationData;
   result: {
      appointments: AppointmentsData[];
      donor_data: DonorData;
      donation_data: DonationData;
   };
}

export const donorsDetails = createAsyncThunk(
   'donorsDetails',
   async ({ id }: { id: string }) => {
      return await api.get(`/info/ByIdChosen/${id}`).then((res) => res.data);
   },
);

export const donorsDetailsSlice = createSlice({
   name: 'donorsDetails',
   initialState: {
      status: 'idle',
      appointments: [],
      donation_data: {
         amount: 0,
         child_amount: 0,
         child_quantity: 0,
         company_id: 0,
         created_at: '',
         donation_status: '',
         donor_id: 0,
         donor_media_id: 0,
         event_id: 0,
         extra_info: '',
         id: 0,
         payment_date: '',
         payments_donation_id: 0,
         revelation_amount: 0,
         revelation_type: '',
         simma_payment_id: 0,
         tracking_code: '',
         updated_at: '',
         donor_event_id: 0,
      },
      donor_data: {
         id: 0,
         name: '',
         document: '',
         email: '',
         phone: {
            country: 0,
            value: 0,
         },
         ocupation: '',
         birthdate: '',
         gender: '',
         address_street: '',
         address_number: 0,
         address_complement: '',
         address_zipcode: 0,
         address_neightborhood: '',
         address_city: '',
         address_state: '',
         created_at: '',
         media_source: '',
         donor_photo_base64: '',
         validation_status: '',
         donation_id: 0,
         event_status: '',
         simma_donor_id: null,
      },
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(donorsDetails.pending, (state) => {
         state.status = 'loading';
      });
      builder.addCase(
         donorsDetails.fulfilled,
         (state, action: PayloadAction<DonorsDataRequest>) => {
            // eslint-disable-next-line @typescript-eslint/no-extra-semi
            (state.status = 'ok'),
               (state.appointments = action.payload.appointments),
               (state.donor_data = action.payload.donor_data),
               (state.donation_data = action.payload.donation_data);
         },
      );
      builder.addCase(donorsDetails.rejected, (state) => {
         state.status = 'rejected';
      });
   },
});

export default donorsDetailsSlice.reducer;
