import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Link, useParams } from 'react-router-dom'
import { useFormatMessage } from 'react-intl-hooks'
import theme from '../styles/theme'
import routes from '../routes/routes.json'
import { useDispatch, useSelector } from 'react-redux'
import {
   listPackageDetails,
   PackageProps,
} from 'services/slices/listPackageDetails'
import { RootState } from 'services/store'
import ReactLoading from 'react-loading'
import BadgeStatus from '../components/BadgeStatus'
import DatePicker from 'react-datepicker'
import pt from 'date-fns/locale/pt'
import { eventDate } from 'services/slices/eventDate'
import { toast } from 'react-toastify'
import Container from 'components/Container'
import NoResult from 'components/NoResult'
import { useHistory } from 'react-router'
import { zipGenerator } from 'services/slices/zipGenerator'
import { reproveDonorPackageDetails } from 'services/slices/reproveDonorPackageDetails'

const PackageDetails = () => {
   const t = useFormatMessage()
   const history = useHistory()
   const dispatch = useDispatch()
   const { packageID } = useParams<{ packageID: string }>()
   const { result, loadingStatus } = useSelector((state: RootState) => ({
      result: state.listPackageDetails.results,
      loadingStatus: state.listPackageDetails.status,
   }))

   const [items, setItems] = useState<PackageProps[]>([])
   const [date, setDate] = useState<Date | string>('')
   const [isDownloading, setIsDownloading] = useState<boolean>(false)

   useEffect(() => {
      dispatch(listPackageDetails({ packageId: packageID }))
   }, [])

   useEffect(() => {
      setItems(result.package_info.package_donors)

      if (
         result?.package_info?.date &&
         result.package_info.date !== 'pending'
      ) {
         setDate(new Date(result.package_info.date))
      } else {
         setDate('')
      }
   }, [result])
   const handleSetEventDate = async () => {
      if (date instanceof Date) {
         const fulldate =
            date.toISOString().substr(0, 10) +
            ' ' +
            date.toLocaleString().substr(11)

         const obj = { event_date: fulldate, id: result.package_info.id }

         const response: any = await dispatch(eventDate(obj))

         if (eventDate.fulfilled.match(response)) {
            toast.success(t({ id: 'Data alterada com sucesso' }).toString())
         } else {
            toast.error(
               t({
                  id: 'Nao valido, por favor selecione outro horario',
               }).toString(),
            )
         }
      }
   }

   const handleDownloadPhotos = async () => {
      setIsDownloading(true)
      const donorPhotos = items.map((item) => item.donor_id)

      const response: any = await dispatch(
         zipGenerator({ donor_id: donorPhotos }),
      )

      if (zipGenerator.fulfilled.match(response)) {
         window.open(response.payload.link)
      } else {
         toast.error(t({ id: 'Algo deu errado no seu download' }).toString())
      }
      setIsDownloading(false)
   }

   const handleReproveDonor = async (donor_event_id: number) => {
      const response: any = await dispatch(
         reproveDonorPackageDetails({ donor_id: donor_event_id }),
      )
      if (reproveDonorPackageDetails.fulfilled.match(response)) {
         toast.success(t({ id: 'Doador removido com sucesso' }))
         dispatch(listPackageDetails({ packageId: packageID }))
      } else {
         toast.error(t({ id: 'Algo deu errado' }))
      }
   }

   const renderAprroval = (item: PackageProps, index: number) => (
      <ItemList key={index}>
         <TitleTable>{item.simma_donor_id}</TitleTable>
         <TextTableBold>{item.name}</TextTableBold>
         <TextTable>{item.tracking_code}</TextTable>
         <TextTable>
            <BadgeStatus status={item.donation_status} />
            {t({ id: item.donation_status })}
         </TextTable>
         <DivFlexAround>
            <img src={`${theme.pathIcons}/user.svg`} />
            <TextTable>
               {t({ id: item.child_quantity_in_package.toString() })} /
               {t({ id: item.total_child_quantity })}
            </TextTable>
         </DivFlexAround>
         <DivFlexBetween>
            <ItemListPhoto role="button" src={item.media_source} />
         </DivFlexBetween>
         <DivFlexAround>
            <ButtonList
               onClick={() => {
                  history.push(
                     `${routes.details.replace(
                        ':donorID',
                        item.donor_id.toString(),
                     )}`,
                  )
               }}
            >
               {t({ id: 'Abrir' })}
            </ButtonList>
         </DivFlexAround>

         <ButtonDisapproved
            type="button"
            onClick={() => handleReproveDonor(item.donor_event_id)}
         />
      </ItemList>
   )

   return (
      <Container>
         <Content>
            <HeaderContent>
               <HeaderContentLeft>
                  <BackButton to={routes.packages}>
                     <img src={`${theme.pathIcons}/arrowLeft.svg`} />
                     {t({ id: 'Voltar' })}
                  </BackButton>
                  <H1>{loadingStatus === 'ok' && result.package_info.title}</H1>
                  <DivFlexAroundCenter>
                     <img src={`${theme.pathIcons}/calendar.svg`} />
                     {loadingStatus === 'ok' && (
                        <DatePicker
                           selected={date}
                           onChange={(date) => setDate(date)}
                           onCalendarClose={handleSetEventDate}
                           dateFormat="dd/MM/yyyy H:mm "
                           wrapperClassName="datePicker"
                           withPortal
                           minDate={new Date()}
                           showTimeSelect
                           timeCaption="Horário"
                           locale={pt}
                           placeholderText={t({ id: 'Selecione um horario' })}
                        />
                     )}
                  </DivFlexAroundCenter>
               </HeaderContentLeft>

               <HeaderContentRight>
                  <ApprovePhotosButton type="button">
                     <DivFlex>
                        <DownloadPhotosButton
                           disabled={items.length === 0 || isDownloading}
                           onClick={handleDownloadPhotos}
                        >
                           {t({
                              id: `${
                                 isDownloading
                                    ? t({ id: 'Baixando fotos' })
                                    : t({ id: 'Baixar fotos' })
                              }`,
                           })}
                           <img src={`${theme.pathIcons}/downloadIcon.svg`} />
                        </DownloadPhotosButton>
                     </DivFlex>
                  </ApprovePhotosButton>
               </HeaderContentRight>
            </HeaderContent>

            <LineHorizontal />

            <List>
               {loadingStatus === 'ok' && items.length > 0 && (
                  <DivGrid>
                     <TitleTable>{t({ id: 'ID' })}</TitleTable>
                     <TitleTable>{t({ id: 'Nome' })}</TitleTable>
                     <TitleTable>{t({ id: 'Código' })}</TitleTable>
                     <TitleTable>{t({ id: 'Pagamento' })}</TitleTable>
                     <TitleTable>{t({ id: 'Crianças' })}</TitleTable>
                     <TitleTable>{t({ id: 'Foto' })}</TitleTable>
                  </DivGrid>
               )}
               {loadingStatus === 'ok' && result ? (
                  items.map(renderAprroval)
               ) : (
                  <LoadingContainer>
                     <ReactLoading
                        type="spin"
                        color={theme.colors.brand.primary.main}
                     />
                  </LoadingContainer>
               )}
               {loadingStatus === 'ok' && items.length === 0 && (
                  <NoResult label={t({ id: 'Nenhum resultado' }).toString()} />
               )}
            </List>
         </Content>
      </Container>
   )
}

const LoadingContainer = styled.div`
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
`

const ItemListPhoto = styled.img`
   cursor: pointer;
   width: 120px;
   height: 88px;
   object-fit: cover;
`

const Content = styled.div`
   min-height: 100vh;
   padding-top: 56px;
   flex-direction: column;
   align-items: center;
   display: flex;
`

const H1 = styled.h1(
   ({ theme }) => css`
      font-size: ${theme.text.heading1.size};
      color: ${theme.neutral.main};
      font-family: ${theme.text.heading1.family};
      font-weight: ${theme.text.heading1.weight};
   `,
)

const LineHorizontal = styled.div(
   ({ theme }) => css`
      background-color: ${theme.neutral.lightest};
      margin: ${theme.spacing.xl} 0;
      width: 100%;
      height: 2px;
   `,
)

const DivGrid = styled.div(
   ({ theme }) => css`
      grid-template-columns: 1fr 5fr 2fr 2fr 2fr 2fr 1fr 1fr;
      margin-bottom: ${theme.spacing.md};
      padding: 4px 24px 2px;
      width: 100%;
      grid-gap: 20px;
      align-items: center;
      display: grid;
   `,
)

const TitleTable = styled.p(
   ({ theme }) => css`
      line-height: ${theme.text.text1.lineHeight};
      font-family: ${theme.text.text1.family};
      font-size: ${theme.text.text1.size};
      font-weight: ${theme.text.text1.weight};
      color: ${theme.neutral.lightness};
   `,
)

const DownloadPhotosButton = styled.button(
   ({ theme }) => css`
      background-color: transparent;
      line-height: ${theme.text.heading2.lineHeight};
      font-family: ${theme.text.heading2.family};
      font-size: ${theme.text.title.size};
      font-weight: ${theme.text.heading2.weight};
      color: ${theme.colors.brand.secondary.main};
      padding-right: ${theme.spacing.xs};
      display: flex;
      align-items: center;

      &:disabled {
         opacity: 0.5;
         cursor: no-drop;
      }

      img {
         margin-left: ${theme.spacing.xs};
      }
   `,
)

const TextTableBold = styled(TitleTable)(
   ({ theme }) => css`
      color: ${theme.neutral.main};
      font-weight: ${theme.text.title.weight};
   `,
)

const TextTable = styled(TitleTable)(
   ({ theme }) => css`
      color: ${theme.neutral.main};
   `,
)

const ItemList = styled(DivGrid)(
   ({ theme }) => css`
      background-color: ${theme.neutral.white};
      margin-bottom: ${theme.spacing.xs};
      box-shadow: 0px 1px 0px ${theme.neutral.lightest};
      border-radius: 8px;
      padding: ${theme.spacing.sm} ${theme.spacing.md};
      border: 1px solid transparent;
      transition: border ease 0.3s;
      will-change: border;

      &:hover {
         border: 1px solid ${theme.colors.brand.secondary.main};
      }
   `,
)

const List = styled.div(
   ({ theme }) => css`
      width: 100%;
      > :last-child {
         margin-bottom: ${theme.spacing.xl};
      }
   `,
)

const ButtonList = styled.button(
   ({ theme }) => css`
      background: rgba(255, 95, 0, 0.08);
      padding: ${theme.spacing.xs} ${theme.spacing.sm};
      font-family: ${theme.text.heading2.family};
      color: ${theme.colors.brand.primary.main};
      font-weight: ${theme.text.title.weight};
      font-size: ${theme.text.title.size};
      border-radius: ${theme.spacing.xxl};
      text-decoration: none;
      transition: all ease 0.3s;

      &:hover {
         color: ${theme.neutral.white};
         background: ${theme.colors.brand.primary.main};
      }
   `,
)

const ApprovePhotosButton = styled.button`
   outline: none;
   border: none;
   background: none;
`

const DivFlex = styled.div`
   display: flex;
`

const DivFlexBetween = styled.div`
   display: flex;
   justify-content: space-between;
`

const DivFlexAround = styled.div`
   display: flex;

   > img {
      margin-right: 16px;
   }
`

const DivFlexAroundCenter = styled.div`
   display: flex;
   justify-content: space-around;
   align-items: center;

   > img {
      margin-right: 11px;
   }
`

const ButtonDisapproved = styled.button(
   ({ theme }) => css`
      border: 1px solid ${theme.neutral.lightest};
      background: ${theme.neutral.background};
      border-radius: 100px;
      height: 28px;
      width: 28px;
      margin-left: auto;

      transition: all 0.2s;

      background-image: url('/assets/images/icons/checkboxFalse.svg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 13px;

      &:active,
      &:hover,
      &:focus {
         background-color: ${theme.feedback.error.main};
         background-image: url('/assets/images/icons/checkboxFalseInverse.svg');
      }
   `,
)

const BackButton = styled(Link)(
   ({ theme }) => css`
      color: ${theme.neutral.light};
      font-size: ${theme.text.text1.size};
      font-family: ${theme.text.text1.family};
      line-height: ${theme.text.text1.lineHeight};
      font-weight: ${theme.text.text1.weight};

      text-decoration: none;
      display: flex;

      > img {
         margin-right: 11px;
      }
   `,
)

const HeaderContent = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
`

const HeaderContentLeft = styled.div(
   ({ theme }) => css`
      display: flex;
      align-items: center;

      > ${BackButton} {
         margin-right: 40px;
      }

      > ${H1} {
         margin-right: 40px;
      }

      .react-datepicker__input-container {
         input {
            border: 0;
            cursor: pointer;
            color: ${theme.neutral.main};
            font-size: ${theme.text.text1.size};
            background-color: transparent;
            font-family: ${theme.text.text1.family};

            &:focus {
               outline: 0;
            }
         }
      }

      .react-datepicker__time-container {
         width: auto;
      }
      .react-datepicker__time-list-item {
         padding: 20px 10px !important;
         height: auto !important;
      }
   `,
)

const HeaderContentRight = styled.div`
   display: flex;
   align-items: center;
`

export default PackageDetails
