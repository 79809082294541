import Button from 'components/Button'
import Spacer from 'components/Spacer'
import { useFormatMessage } from 'react-intl-hooks'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const Container = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   max-width: 530px;
   margin: 0 auto;
   min-height: 90vh;
`

const ThanksIcon = styled.img`
   width: 400px;
   object-fit: container;
`

const Title = styled.p`
   max-width: 404px;

   font-family: ${(props) => props.theme.text.heading1.family};
   font-size: ${(props) => props.theme.text.heading1.size};
   font-weight: ${(props) => props.theme.text.heading1.weight};
   line-height: ${(props) => props.theme.text.heading1.lineHeight};

   color: ${(props) => props.theme.neutral.main};
   text-align: center;

   margin: 0;
`

const Subtitle = styled.p`
   max-width: 352px;

   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   color: ${(props) => props.theme.neutral.sub};
   text-align: center;

   margin: 0;
`

const ThanksUps = (): JSX.Element => {
   const history = useHistory()
   const t = useFormatMessage()
   return (
      <Container>
         <ThanksIcon src="/assets/icons/Group_19455.png" />
         <Spacer bottom={80} />

         <Title>{t({ id: 'sua doacao foi realizada com sucesso' })}</Title>
         <Spacer bottom={16} />

         <Subtitle>
            {t({
               id: 'voce recebera um email de confirmacao com a explicacao dos proximos passos_e',
            })}
         </Subtitle>
         <Spacer bottom={40} />

         <div>
            <Button
               label={t({ id: 'ir para pagina inicial' }).toString()}
               onClick={() => history.push('/')}
            />
         </div>
      </Container>
   )
}

export default ThanksUps
