import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import api from 'services/api'

type RequestProps = {
   event_date: string
   id: string
}

export const eventDate = createAsyncThunk(
   'eventDate',
   async ({ event_date, id }: RequestProps) => {
      return await api
         .put(`package/${id}`, {
            event_date,
         })
         .then((res) => res.data)
   },
)

const eventDateSlice = createSlice({
   name: 'eventDate',
   initialState: {
      status: 'idle',
      event_date: '',
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(eventDate.pending, (state) => {
         state.status = 'loading'
      })
      builder.addCase(eventDate.fulfilled, (state) => {
         state.status = 'ok'
      })
      builder.addCase(eventDate.rejected, (state) => {
         state.status = 'rejected'
      })
   },
})

export default eventDateSlice.reducer
