import styled, { css, DefaultTheme, ThemeProps } from 'styled-components'

type CheckboxProps = {
   checked: boolean
   disabled?: boolean
   onClick?: () => void
}

const Checkbox = ({ checked, disabled, onClick }: CheckboxProps) => {
   return (
      <Container
         checked={checked}
         disabled={disabled ?? false}
         onClick={() => (onClick && !disabled ? onClick() : {})}
      />
   )
}

const Container = styled.div(
   ({
      checked,
      disabled,
      theme,
   }: CheckboxProps & ThemeProps<DefaultTheme>) => css`
      width: 24px;
      height: 24px;
      border-radius: 4px;
      background-color: ${checked
         ? theme.colors.brand.secondary.main
         : 'transparent'} !important;
      position: relative;
      cursor: pointer;
      border: 2px solid ${checked ? 'transparent' : theme.neutral.lightness};
      opacity: ${disabled ? 0.5 : 1};

      &:after {
         content: '';
         position: absolute;
         left: 7px;
         top: 3px;
         width: 4px;
         height: 8px;
         border: solid white;
         border-width: 0 2.5px 2.5px 0;
         transform: rotate(45deg);
      }
   `,
)

export default Checkbox
