import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import api from 'services/api'

interface listDonorsInterface {
   status: string
   paginate: {
      has_more: boolean
      page: number
      total: number
   }
   results: [
      {
         id: number
         name: string
         simma_donor_id: string
         donor_media: {
            id: number
            donor_id: number
            media_source: string
            media_type: string
         }
         last_donation: {
            id: number
            donor_id: number
            donation_status: string
            child_quantity: number
            payment_date: string
         }
      },
   ]
}

interface listDonorsRequest {
   donationStatus: string
   pg: number
   pp: number
   term?: string
}

export const listDonors = createAsyncThunk(
   'listDonors',
   async ({ donationStatus, pg, pp, term }: listDonorsRequest) => {
      return await api
         .get(
            `/donors/list?donation_status=${
               donationStatus ? donationStatus : 'all'
            }&pg=${pg}&pp=${pp}${term ? `&term=${term}` : ''}`,
         )
         .then((res) => res.data)
   },
)

const listDonorsSlice = createSlice({
   name: 'listDonors',
   initialState: {
      status: 'idle',
      paginate: {
         has_more: false,
         page: 0,
         total: 0,
      },
      results: [],
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(listDonors.pending, (state) => {
         state.status = 'loading'
      })
      builder.addCase(
         listDonors.fulfilled,
         (state, action: PayloadAction<listDonorsInterface>) => {
            state.status = 'ok'
            state.results = action.payload.results
            state.paginate = action.payload.paginate
         },
      ),
         builder.addCase(listDonors.rejected, (state) => {
            state.status = 'rejected'
         })
   },
})

export default listDonorsSlice.reducer
