const hexToRgba = (hex: string, opacity = 1): string => {
   const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

   if (result) {
      const RGBArray = [
         parseInt(result[1], 16),
         parseInt(result[2], 16),
         parseInt(result[3], 16),
      ]

      return `rgba(${RGBArray.join(', ')}, ${opacity})`
   }
   return ''
}

export default hexToRgba;
