import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'services/api';

interface listApprovalsInterface {
   status: string;
   paginate: {
      has_more: boolean;
      page: number;
      total: number;
   };
   results: [
      {
         child_quantity: number;
         donation_id: number;
         donation_status: string;
         donor_media_id: number;
         email: string;
         id: number;
         media_source: string;
         name: string;
         simma_donor_id: string;
         typeIncription: string;
         validation_status: string;
      },
   ];
}

interface listApprovalsRequest {
   donationStatus: string;
   validationStatus: string;
   pg: number;
   pp: number;
   term?: string;
}

export const listApprovals = createAsyncThunk(
   'listApprovals',
   async ({
      donationStatus,
      validationStatus,
      pg,
      pp,
      term,
   }: listApprovalsRequest) => {
      try {
         const response = await api.get(
            `/listChosen?page=${pg}&donationStatus=${donationStatus}`,
         );
         return response.data;
      } catch (error) {
         // Manejar el error aquí si es necesario
         throw error;
      }
   },
);

const listApprovalsSlice = createSlice({
   name: 'listApprovals',
   initialState: {
      status: 'idle',
      paginate: {
         has_more: false,
         page: 0,
         total: 0,
      },
      results: [],
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(listApprovals.pending, (state) => {
         state.status = 'loading';
      });
      builder.addCase(
         listApprovals.fulfilled,
         (state, action: PayloadAction<listApprovalsInterface>) => {
            state.status = 'ok';
            state.results = action.payload.results;
            state.paginate = action.payload.paginate;
         },
      ),
         builder.addCase(listApprovals.rejected, (state) => {
            state.status = 'rejected';
         });
   },
});

export default listApprovalsSlice.reducer;
