import Button from 'components/Button';
import Input, { InputRow, InputWrapper } from 'components/Input';
import Select from 'react-select';
import InputSelect from 'components/InputSelect';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import PhoneInput from 'components/PhoneInput';
import Spacer from 'components/Spacer';
import { Field, Form, Formik } from 'formik';
import useRegionalization from 'hooks/useRegionalization';
import { useEffect, useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { DonorDetails, newDonor } from 'services/slices/newDonor';
import { zipcodeSearch } from 'services/slices/zipcodeSearch';
import { RootState } from 'services/store';
import { RouteComponentProps } from 'react-router-dom';
import {
   documentRegex,
   INVALID_FORMAT,
   INVALID_LEGALDATE,
   REQUIRED_CHECK,
   REQUIRED_LABEL,
} from 'services/validators';
import styled, { css } from 'styled-components';
import * as yup from 'yup';
import 'yup-phone';
import { CountryCode, getPhoneCode } from 'libphonenumber-js';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import calculateAge from 'utils/ageCalculator';
import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

const Wrapper = styled.div``;

interface FormProps {
   first_name: string;
   last_name: string;
   identificationNumber: string;
   identificationType: string;
   email: string;
   phone: string;
   birth_date: string;
   gender: string;
   address_street: string;
   address_number: string;
   address_complement?: string;
   address_neightborhood: string;
   address_city: string;
   address_state: string;
   checkbox: boolean;
   checkbox_referido: boolean;
   referral_code?: string;
}

const initialValues: FormProps = {
   address_state: sessionStorage.getItem('address_state') ?? '',
   address_number: sessionStorage.getItem('address_number') ?? '',
   birth_date: sessionStorage.getItem('birth_date') ?? '',
   gender: sessionStorage.getItem('gender') ?? '',
   address_street: sessionStorage.getItem('address_street') ?? '',
   address_neightborhood: sessionStorage.getItem('address_neightborhood') ?? '',
   address_complement: sessionStorage.getItem('address_complement') ?? '',
   identificationNumber: sessionStorage.getItem('identificationNumber') ?? '',
   identificationType: sessionStorage.getItem('identificationType') ?? '',
   email: sessionStorage.getItem('email') ?? '',
   address_city: sessionStorage.getItem('address_city') ?? '',
   first_name: sessionStorage.getItem('first_name') ?? '',
   last_name: sessionStorage.getItem('last_name') ?? '',
   referral_code: sessionStorage.getItem('referral_code') ?? '',
   phone: sessionStorage.getItem('phone') ?? '',
   checkbox: false,
   checkbox_referido: false,
};

type CheckoutDataFormProps = {
   onSubmit: (values: DonorDetails) => void;
};

type StateProps = {
   uf: string;
};

const getCities = () => {
   return axios.get(baseURL + 'cities');
};

const getDocumentType = () => {
   return axios.get(baseURL + 'documenTypes');
};

const stringTransformation = (inputString) => {
   const resultString = inputString.replace(/,|\n/g, ' ');
   return resultString;
};

const CheckoutDataForm = ({ onSubmit }: CheckoutDataFormProps): JSX.Element => {
   const { country, phoneCountry, setPhoneCountry, regionalization } =
      useRegionalization();
   const t = useFormatMessage();
   const dispatch = useDispatch();
   // const [districts] = useState<StateProps[]>(country === 'mx' ? mxStates : uf)
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [mostrarInput, setMostrarInput] = useState(false);
   const [valorInputCodigo, setValorInputCodigo] = useState('');
   const [loadingAddress, setLoadingAddress] = useState<boolean>(false);
   const [referredID, setreferredID] = useState('');

   const [selectedCityOption, setSelectedCityOption] = useState(null);
   const [cities, setcities] = useState([]);
   const [selectedDocTypeOption, setSelectedDocTypeOption] = useState(null);
   const [documentTypes, setdocumentTypes] = useState([]);

   useEffect(() => {
      getCities()
         .then((array) => {
            setcities(array.data);
         })
         .catch((err) => {
            console.log(err);
         });
      getDocumentType()
         .then((array) => {
            setdocumentTypes(array.data);
         })
         .catch((err) => {
            console.log(err);
         });
   }, []);

   const { loadingStatus, address } = useSelector((state: RootState) => ({
      loadingStatus: state.zipcodeSearch.status,
      address: state.zipcodeSearch.address,
   }));

   const handleCheckboxChange = () => {
      setMostrarInput(!mostrarInput);
   };

   const handleChangeCity = (selectedOption) => {
      setSelectedCityOption(selectedOption);
   };

   const handleChangeDocType = (selectedOption) => {
      setSelectedDocTypeOption(selectedOption);
   };

   const customStyles = {
      control: (baseStyles, state) => ({
         ...baseStyles,
         borderColor: state.isFocused ? 'grey' : '#e2e2ea',
         border: '1.2px solid #E2E2EA',
         borderRadius: '8px',
         height: '58px',
         fontFamily: 'Inter',
         lineHeight: '24px',
         fontSize: '16px',
         fontWeight: '500',
      }),
      option: (provided) => ({
         ...provided,
         fontFamily: 'Inter',
         color: '#8D8F96',
      }),
   };

   const handleReturnSelectCity = ({ onCityChange, onBlur }) => {
      const citiesOptions = cities.map((option) => ({
         value: option.ID,
         label: option.City,
      }));

      const handleCityChangeSelect = (selectedOption) => {
         onCityChange(selectedOption); //envia los datos de estado al forulario q lo invoca
         handleChangeCity(selectedOption); //envia los datos a la variable de contro externa para segmentar los envios a simma y a la bd del app
      };
      return (
         <div>
            <Select
               styles={customStyles}
               placeholder={t({ id: 'Digite' }).toString()}
               value={selectedCityOption}
               onChange={handleCityChangeSelect}
               onBlur={onBlur}
               options={citiesOptions}
               isSearchable={true}
            />
         </div>
      );
   };

   const handleReturnSelectDocType = ({ onTypeChange, onBlur }) => {
      const typeOptions = documentTypes.map((option) => ({
         value: option.ID,
         label: option.Description,
      }));

      const handleTypeChangeSelect = (selectedOption) => {
         onTypeChange(selectedOption); //envia los datos de estado al forulario q lo invoca
         handleChangeDocType(selectedOption); //envia los datos a la variable de contro externa para segmentar los envios a simma y a la bd del app
      };
      return (
         <div>
            <Select
               styles={customStyles}
               placeholder={t({ id: 'Selecione o type Documento' }).toString()}
               value={selectedDocTypeOption}
               onChange={handleTypeChangeSelect}
               onBlur={onBlur}
               options={typeOptions}
               isSearchable={false}
            />
         </div>
      );
   };

   const handleInputChange = (event) => {
      const inputValue = event.target.value.toUpperCase();
      setValorInputCodigo(inputValue);
   };

   const { code } = useSelector((state: RootState) => ({
      code: state.idReferred.code,
   }));

   const handleSubmit = async (values: FormProps) => {
      setIsLoading(true);

      const obj: DonorDetails = {
         first_name: values.first_name,
         last_name: values.last_name,
         identificationNumber: values.identificationNumber,
         identificationType: values.identificationType,
         email: values.email,
         phone: {
            country: getPhoneCode(phoneCountry).toString(),
            value: values.phone,
         },
         birthdate: values.birth_date,
         gender: values.gender,
         address_street: stringTransformation(values.address_street),
         address_city: values.address_city,
         simma_cityCode: selectedCityOption.value,
         simma_docTypeCode: selectedDocTypeOption.value,
         validation_status: 'pending',
         registerInSimma: false,
         approvedPhotography: false,
         referral_code: code ? code : valorInputCodigo,
         date: new Date().toDateString(),
      };

      const response: any = await dispatch(newDonor(obj));

      setIsLoading(false);

      if (newDonor.rejected.match(response)) {
         toast.error(t({ id: 'register invalido' }));
      }

      if (newDonor.fulfilled.match(response)) {
         if (onSubmit) onSubmit(obj);
      }
   };

   const FormSchema = yup.object().shape({
      first_name: yup.string().required(REQUIRED_LABEL).max(30),
      last_name: yup.string().required(REQUIRED_LABEL).max(80),
      identificationNumber: yup.string().required(REQUIRED_LABEL).max(15),
      email: yup.string().required(REQUIRED_LABEL).email(INVALID_FORMAT),
      gender: yup.string().required(REQUIRED_LABEL),
      address_city: yup.string().required(REQUIRED_LABEL),
      identificationType: yup.string().required(REQUIRED_LABEL),
      birth_date: yup
         .string()
         .required(REQUIRED_LABEL)
         .test('DOB', INVALID_LEGALDATE, (value) => {
            if (value) {
               const newDate = value.split('-').reverse().join('/');

               const age = calculateAge(newDate);

               return age >= 18 && age <= 120;
            }
         }),
      phone: yup
         .string()
         .phone(phoneCountry, true, INVALID_FORMAT)
         .required(REQUIRED_LABEL),
      checkbox: yup
         .bool()
         .required(REQUIRED_LABEL)
         .oneOf([true], REQUIRED_CHECK),
   });

   return (
      <Wrapper>
         <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={FormSchema}
            validateOnMount
         >
            {({
               values,
               errors,
               touched,
               handleChange,
               handleBlur,
               handleSubmit,
               setFieldValue,
               isValid,
            }) => {
               const handleSearchZipcode = async (value: string) => {
                  if (country !== 'mx') {
                     const zipcode = value.replace('-', '');

                     if (zipcode.length === 8) {
                        await dispatch(zipcodeSearch({ zipcode }));
                     }
                  }
               };

               const handleDataForm = () => {
                  sessionStorage.setItem('address_city', values.address_city);
                  sessionStorage.setItem(
                     'address_complement',
                     values.address_complement,
                  );
                  sessionStorage.setItem(
                     'address_complement',
                     values.address_complement,
                  );
                  sessionStorage.setItem(
                     'address_neightborhood',
                     values.address_neightborhood,
                  );
                  sessionStorage.setItem(
                     'address_number',
                     values.address_number,
                  );
                  sessionStorage.setItem('address_state', values.address_state);
                  sessionStorage.setItem(
                     'address_street',
                     values.address_street,
                  );
                  sessionStorage.setItem('birth_date', values.birth_date);
                  sessionStorage.setItem('email', values.email);
                  sessionStorage.setItem('gender', values.gender);
                  sessionStorage.setItem('first_name', values.first_name);
                  sessionStorage.setItem(
                     'checkbox',
                     values.checkbox.toString(),
                  );
                  sessionStorage.setItem(
                     'checkbox_referido',
                     values.checkbox.toString(),
                  );
                  sessionStorage.setItem('last_name', values.last_name);
                  sessionStorage.setItem(
                     'identificationNumber',
                     values.identificationNumber,
                  );
                  sessionStorage.setItem(
                     'identificationType',
                     values.identificationType,
                  );
                  sessionStorage.setItem('phone', values.phone);
                  sessionStorage.setItem('referral_code', valorInputCodigo);
               };

               const handleTermsLink = (page: string) => {
                  handleDataForm();
                  if (page === 'Termos de Uso') {
                     window.open(
                        'https://www.worldvision.co/static/webpage_app/docs/PoliticaDeTratamientoDeDatos.pdf',
                     );
                  } else if (page === 'Politicas de Privacidade') {
                     window.open(
                        'https://www.worldvision.co/static/webpage_app/docs/PoliticaDeTratamientoDeDatos.pdf',
                     );
                  }
               };

               useEffect(() => {
                  if (country === 'br') {
                     setFieldValue('address_city', address.address_city);
                     setFieldValue(
                        'address_neightborhood',
                        address.address_neightborhood,
                     );
                     setFieldValue('address_state', address.address_state);
                     setFieldValue('address_street', address.address_street);

                     if (loadingStatus === 'rejected') {
                        toast.error(t({ id: 'CEP nao encontrado' }), {
                           hideProgressBar: false,
                           autoClose: 3000,
                           pauseOnFocusLoss: false,
                        });
                     }
                  }
               }, [loadingStatus]);

               return (
                  <Form onSubmit={handleSubmit}>
                     <Input
                        label={`${t({ id: 'Nome' }).toString()} *`}
                        placeholder={t({ id: 'Digite' }).toString()}
                        value={values.first_name}
                        onChange={handleChange('first_name')}
                        onBlur={handleBlur('first_name')}
                        error={
                           touched.first_name && errors.first_name
                              ? t({ id: errors.first_name }).toString()
                              : undefined
                        }
                        type="text"
                        name="first_name"
                        autoComplete="first_name"
                        autoCapitalize="words"
                        maxLength={30}
                     />
                     <Spacer bottom={16} />

                     <Input
                        label={`${t({ id: 'Sobrenome' }).toString()} *`}
                        placeholder={t({ id: 'Digite' }).toString()}
                        value={values.last_name}
                        onChange={handleChange('last_name')}
                        onBlur={handleBlur('last_name')}
                        error={
                           touched.last_name && errors.last_name
                              ? t({ id: errors.last_name }).toString()
                              : undefined
                        }
                        type="text"
                        name="last_name"
                        autoComplete="last_name"
                        autoCapitalize="words"
                        maxLength={80}
                     />
                     <Spacer bottom={16} />

                     <Label>Tipo y Numero de Documento *</Label>

                     <InputRow>
                        <InputWrapper style={{ maxWidth: '40%' }}>
                           <InputSelect
                              value={values.identificationType}
                              onChange={handleChange('identificationType')}
                              onBlur={handleBlur('identificationType')}
                              renderSelect={() => (
                                 <div>
                                    {handleReturnSelectDocType({
                                       onBlur: handleBlur('identificationType'),
                                       onTypeChange: (selectedOption) => {
                                          handleChange('identificationType')(
                                             selectedOption.label,
                                          );
                                          setFieldValue(
                                             'identificationType',
                                             selectedOption.label,
                                          );
                                       },
                                    })}
                                 </div>
                              )}
                              error={
                                 touched.identificationType &&
                                 errors.identificationType
                                    ? t({
                                         id: errors.identificationType,
                                      }).toString()
                                    : undefined
                              }
                           ></InputSelect>
                        </InputWrapper>
                        <Spacer right={16} />
                        <InputWrapper style={{ maxWidth: '50%' }}>
                           <Input
                              value={values.identificationNumber}
                              onChange={handleChange('identificationNumber')}
                              onBlur={handleBlur('identificationNumber')}
                              error={
                                 touched.identificationNumber &&
                                 errors.identificationNumber
                                    ? t({
                                         id: errors.identificationNumber,
                                      }).toString()
                                    : undefined
                              }
                              type="text"
                              name="identificationNumber"
                              placeholder="Número de documento"
                           />
                        </InputWrapper>
                     </InputRow>

                     <Spacer bottom={16} />

                     <Input
                        label={t({ id: 'Email' }).toString()}
                        placeholder={t({ id: 'Digite' }).toString()}
                        value={values.email}
                        onChange={handleChange('email')}
                        onBlur={handleBlur('email')}
                        error={
                           touched.email && errors.email
                              ? t({ id: errors.email }).toString()
                              : undefined
                        }
                        type="email"
                        name="email"
                        autoComplete="email"
                        autoCapitalize="off"
                        maxLength={128}
                     />
                     <Spacer bottom={16} />

                     <InputRow>
                        <InputWrapper style={{ maxWidth: '50%' }}>
                           <InputSelect
                              label={t({ id: 'Sexo' }).toString()}
                              value={values.gender}
                              onChange={handleChange('gender')}
                              onBlur={handleBlur('gender')}
                              error={
                                 touched.gender && errors.gender
                                    ? t({ id: errors.gender }).toString()
                                    : undefined
                              }
                           >
                              <option>{t({ id: 'Selecione o genero' })}</option>
                              <option value="Masculino">
                                 {' '}
                                 {t({ id: 'Masculino' })}
                              </option>
                              <option value="Femenino">
                                 {t({ id: 'Feminino' })}
                              </option>
                              <option value="none">
                                 {t({ id: 'Nao informar' })}
                              </option>
                           </InputSelect>
                           <Spacer bottom={16} />
                        </InputWrapper>

                        <Spacer right={20} />

                        <InputWrapper style={{ maxWidth: '50%' }}>
                           <Input
                              label={t({ id: 'Nascimento' }).toString()}
                              placeholder={t({ id: 'Digite' }).toString()}
                              value={values.birth_date}
                              onChange={handleChange('birth_date')}
                              onBlur={handleBlur('birth_date')}
                              error={
                                 touched.birth_date && errors.birth_date
                                    ? t({ id: errors.birth_date }).toString()
                                    : undefined
                              }
                              type="date"
                              name="birth_date"
                              autoComplete="bday"
                           />
                           <Spacer bottom={16} />
                        </InputWrapper>
                     </InputRow>

                     <PhoneInput
                        label={t({ id: 'Telefone' }).toString()}
                        placeholder={t({ id: 'Digite' }).toString()}
                        value={values.phone}
                        onChange={(e: any) => {
                           handleChange('phone')(e.target.rawValue);
                        }}
                        onBlur={handleBlur('phone')}
                        error={
                           touched.phone && errors.phone
                              ? t({ id: errors.phone }).toString()
                              : undefined
                        }
                        type="tel"
                        name="phone"
                        autoComplete="tel"
                        autoCapitalize="off"
                        initialCountry={'co'}
                        maxLength={13}
                        onCountryChanged={(phoneCountry: CountryCode) => {
                           handleChange('phone')('');
                           setPhoneCountry(phoneCountry);
                        }}
                     />
                     <Spacer bottom={16} />

                     <Input
                        label={t({ id: 'Rua' }).toString()}
                        placeholder={
                           loadingAddress
                              ? 'buscando rua...'
                              : t({ id: 'Digite' }).toString()
                        }
                        value={values.address_street}
                        onChange={handleChange('address_street')}
                        onBlur={handleBlur('address_street')}
                        disabled={loadingAddress}
                        error={
                           touched.address_street && errors.address_street
                              ? t({ id: errors.address_street }).toString()
                              : undefined
                        }
                        type="text"
                        name="address_street"
                        autoComplete="street-address"
                        autoCapitalize="off"
                        maxLength={160}
                     />
                     <Spacer bottom={16} />

                     <InputRow>
                        <InputSelect
                           label={t({ id: 'Cidade' }).toString()}
                           value={values.address_city}
                           onChange={handleChange('address_city')}
                           onBlur={handleBlur('address_city')}
                           renderSelect={() => (
                              <div>
                                 {handleReturnSelectCity({
                                    onBlur: handleBlur('address_city'),
                                    onCityChange: (selectedOption) => {
                                       handleChange('address_city')(
                                          selectedOption.label,
                                       );
                                       setFieldValue(
                                          'address_city',
                                          selectedOption.label,
                                       );
                                    },
                                 })}
                              </div>
                           )}
                           error={
                              touched.address_city && errors.address_city
                                 ? t({ id: errors.address_city }).toString()
                                 : undefined
                           }
                        ></InputSelect>
                     </InputRow>

                     <Spacer bottom={16} />

                     <TermsPrivacy>
                        <Field name="checkbox_referido">
                           {({ field, form: { touched, errors }, meta }) => (
                              <>
                                 <DivCheckbox>
                                    <input
                                       type="checkbox"
                                       placeholder=""
                                       onChange={handleCheckboxChange}
                                    />
                                    <p>
                                       {t({
                                          id: 'Tienes codigo de referido',
                                       }).toString()}{' '}
                                    </p>
                                 </DivCheckbox>
                                 <div>
                                    {mostrarInput && (
                                       <Input
                                          placeholder="XAS123"
                                          value={valorInputCodigo}
                                          onChange={handleInputChange}
                                          onBlur={handleBlur('referral_code')}
                                          error={
                                             touched.referral_code &&
                                             errors.referral_code
                                                ? t({
                                                     id: errors.referral_code,
                                                  }).toString()
                                                : undefined
                                          }
                                          type="text"
                                          name="referral_code"
                                          autoComplete="referral_code"
                                          autoCapitalize="words"
                                          maxLength={80}
                                       />
                                    )}
                                 </div>
                                 <div>
                                    {meta.touched && meta.error && (
                                       <ErrorforCheckbox>
                                          {meta.error}
                                       </ErrorforCheckbox>
                                    )}
                                 </div>
                              </>
                           )}
                        </Field>
                     </TermsPrivacy>

                     <Spacer bottom={24} />

                     <InputWrapper>
                        <TermsPrivacy>
                           <Field name="checkbox">
                              {({ field, form: { touched, errors }, meta }) => (
                                 <>
                                    <DivCheckbox>
                                       <input
                                          type="checkbox"
                                          placeholder=""
                                          {...field}
                                       />
                                       <p>
                                          {t({ id: 'Li e concordo com os' })}{' '}
                                          {country === 'mx' ? (
                                             <TermsLink>
                                                <Span
                                                   onClick={() =>
                                                      handleTermsLink(
                                                         'Politicas de Privacidade',
                                                      )
                                                   }
                                                >
                                                   {t({
                                                      id: 'Politicas de Privacidade',
                                                   })}
                                                </Span>
                                                e{' '}
                                                <Span
                                                   onClick={() =>
                                                      handleTermsLink(
                                                         'Termos de Uso',
                                                      )
                                                   }
                                                >
                                                   {t({ id: 'Termos de Uso' })}
                                                </Span>{' '}
                                             </TermsLink>
                                          ) : (
                                             <Terms to="/terms">
                                                <span>
                                                   {t({ id: 'Termos de Uso' })}
                                                </span>{' '}
                                                e{' '}
                                                <span>
                                                   {t({
                                                      id: 'Politicas de Privacidade',
                                                   })}
                                                </span>
                                             </Terms>
                                          )}
                                       </p>
                                    </DivCheckbox>
                                    <div>
                                       {meta.touched && meta.error && (
                                          <ErrorforCheckbox>
                                             {meta.error}
                                          </ErrorforCheckbox>
                                       )}
                                    </div>
                                 </>
                              )}
                           </Field>
                        </TermsPrivacy>
                     </InputWrapper>

                     <Spacer bottom={40} />
                     <Button
                        type="submit"
                        label={t({ id: 'Siguiente_paso' }).toString()}
                        full
                        disabled={(!isValid && !values.checkbox) || isLoading}
                     />
                  </Form>
               );
            }}
         </Formik>
      </Wrapper>
   );
};

const LoadingContainer = styled.div`
   display: flex;
   justify-content: center;
`;

const TermsLink = styled.div`
   text-decoration: none;
   color: ${(props) => props.theme.colors.input};
`;
const Terms = styled(Link)`
   text-decoration: none;
`;

const TermsPrivacy = styled.div(
   ({ theme }) => css`
      font-family: ${theme.text.text1};
      font-family: 'Inter';
      font-weight: 500;

      p {
         color: ${theme.neutral.sub};
         margin-left: ${theme.spacing.xs};
      }
      span {
         color: ${theme.colors.brand.primary.main};
      }
   `,
);

const DivCheckbox = styled.div(
   ({ theme }) => css`
      display: flex;
      align-items: center;
   `,
);

const ErrorforCheckbox = styled.div(
   ({ theme }) => css`
      color: ${theme.feedback.error.main};
      margin: 10px 30px;
   `,
);

const Span = styled.span`
   cursor: pointer;
`;
const ForeignText = styled.p(
   ({ theme }) => css`
      font-family: ${theme.text.text1.family};
      font-weight: 500;
      color: ${theme.neutral.sub};
      margin-left: ${theme.spacing.xs};
   `,
);

const Label = styled.label`
   display: flex;
   justify-content: center;

   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   color: ${(props) => props.theme.neutral.sub};
`;

const SelectWrapper = styled.select`
   color: #8d8f96;
   margin-top: 7px;
   width: 100%;
   outline: none;
   border: 1.2px solid ${(props) => props.theme.neutral.lightest};
   border-radius: 8px;
   height: 60px;

   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   &::placeholder {
      color: ${(props) => props.theme.neutral.lightest};
   }

   &:focus {
      border-color: ${(props) => props.theme.colors.brand.secondary.main};
   }
`;

export default CheckoutDataForm;
/* function checkbox(checkbox: any, checkbox: boolean) {
   throw new Error('Function not implemented.')
}
 */
