import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import Spacer from 'components/Spacer';
import Input, { InputRow, InputWrapper } from 'components/Input';

import axios from 'axios';
const baseURL = process.env.REACT_APP_API_URL


const registerPay = (data) => {
  return axios.post(
      baseURL + 'registerpay',
      data,
  );
}

let binPer;
let inssuerPer;

function createSelectOptions(elem, options, labelsAndKeys = { label: "name", value: "id" }) {
    const { label, value } = labelsAndKeys;
    elem.options.length = 0;
    const tempOptions = document.createDocumentFragment();

    options.forEach(option => {
        const optValue = option[value];
        const optLabel = option[label];
        const opt = document.createElement('option');
        opt.value = optValue;
        opt.textContent = optLabel;
        tempOptions.appendChild(opt);
    });

    elem.appendChild(tempOptions);
}

// Este paso mejora las validaciones de cardNumber y securityCode


const CheckoutMercadoPagoForm = (props) => {

    const mp = new MercadoPago(process.env.REACT_APP_MEPA_PUBLIC_KEY);
    const formElement = useRef(null);
    const numCard = useRef(null)
    const [amountValue, setAmountValue] = useState('')

    useEffect(() => {       

        const cardNumberElement = mp.fields.create('cardNumber', {
            placeholder: "Numero de tarjeta, crédito o débito"
        }).mount('form-checkout__cardNumber');
        const expirationDateElement = mp.fields.create('expirationDate', {
            placeholder: "MM/YY",
        }).mount('form-checkout__expirationDate');
        const securityCodeElement = mp.fields.create('securityCode', {
            placeholder: "Código de seguridad"
        }).mount('form-checkout__securityCode');  

        let valueChilds = parseInt(props.data) * 50000;   

        let amount = document.getElementById('transactionAmount');
        amount.value = valueChilds;

        (async function getIdentificationTypes() {
        try {
            const identificationTypes = await mp.getIdentificationTypes();
            const identificationTypeElement = document.getElementById('form-checkout__identificationType');

            createSelectOptions(identificationTypeElement, identificationTypes);
        } catch (e) {
            return console.error('Error getting identificationTypes: ', e);
        }
        })();
               //   ////////////////////////////////////////////////////////////////////////////////////////////////

        /**
         * Obtener métodos de pago de la tarjeta

        */

        const paymentMethodElement = document.getElementById('paymentMethodId');
        const issuerElement = document.getElementById('form-checkout__issuer');
        const installmentsElement = document.getElementById('form-checkout__installments');

        const issuerPlaceholder = "Banco emisor";
        const installmentsPlaceholder = "Cuotas";

        let currentBin;
        cardNumberElement.on('binChange', async (data) => {
            const { bin } = data;
            // console.log(data) 
            try {
            if (!bin && paymentMethodElement.value) {
                clearSelectsAndSetPlaceholders();
                paymentMethodElement.value = "";
            }
            if (bin && bin !== currentBin) {
                const { results } = await mp.getPaymentMethods({ bin });
                const paymentMethod = results[0];

                paymentMethodElement.value = paymentMethod.id; //actualiza una vista
                updatePCIFieldsSettings(paymentMethod);
                updateIssuer(paymentMethod, bin);
                updateInstallments(paymentMethod, bin);
            }

                currentBin = bin;
                binPer = bin;
            } catch (e) {
            console.error('error getting payment methods: ', e)
            }
        });

        function clearSelectsAndSetPlaceholders() {
            clearHTMLSelectChildrenFrom(issuerElement);
            createSelectElementPlaceholder(issuerElement, issuerPlaceholder);

            clearHTMLSelectChildrenFrom(installmentsElement);
            createSelectElementPlaceholder(installmentsElement, installmentsPlaceholder);
        }

        function clearHTMLSelectChildrenFrom(element) {
            const currOptions = [...element.children];
            currOptions.forEach(child => child.remove());
        }

        function createSelectElementPlaceholder(element, placeholder) {
            const optionElement = document.createElement('option');
            optionElement.textContent = placeholder;
            optionElement.setAttribute('selected', "");
            optionElement.setAttribute('disabled', "");

            element.appendChild(optionElement);
        }

        // Este paso mejora las validaciones de cardNumber y securityCode
        function updatePCIFieldsSettings(paymentMethod) {
            const { settings } = paymentMethod;

            const cardNumberSettings = settings[0].card_number;
            // console.log(cardNumberSettings)
            cardNumberElement.update({
            settings: cardNumberSettings
            });


            const securityCodeSettings = settings[0].security_code;
            securityCodeElement.update({
            settings: securityCodeSettings
            });
        }


        //   /**
        //    * Obtener banco emisor
        //    */


        async function updateIssuer(paymentMethod, bin) {
            const { additional_info_needed, issuer } = paymentMethod;
            let issuerOptions = [issuer];
            inssuerPer = issuerOptions[0].name;

            if (additional_info_needed.includes('issuer_id')) {
            issuerOptions = await getIssuers(paymentMethod, bin);
            }
            createSelectOptions(issuerElement, issuerOptions);
        }

        async function getIssuers(paymentMethod, bin) {
            try {
            const { id: paymentMethodId } = paymentMethod;
            return await mp.getIssuers({ paymentMethodId, bin });
            } catch (e) {
            console.error('error getting issuers: ', e)
            }
        }

        //   /**
        //    * Obtener cantidad de cuotas
        //    * @param {*} paymentMethod 
        //    * @param {*} bin 
        //    */
        
        async function updateInstallments(paymentMethod, bin) {    
            let valueChildsMp = parseInt(props.data) * 50000;   

            try {
            const installments = await mp.getInstallments({
                amount: valueChildsMp.toString(),
                bin,
                paymentTypeId: 'credit_card'
            });
            const installmentOptions = installments[0].payer_costs;
            const installmentOptionsKeys = { label: 'recommended_message', value: 'installments' };
            createSelectOptions(installmentsElement, installmentOptions, installmentOptionsKeys);
            
            } catch (error) {
            console.error('error getting installments: ', e)
            }
        }




       

        const formElement = document.getElementById('form-checkout');
        formElement.addEventListener('submit', createCardToken);     

       
    }, [props]);    


    async function createCardToken(event) {
        let valueChildsMp = parseInt(props.data) * 50000;   
        try {
            event.preventDefault();
            const tokenElement = document.getElementById('token');
            if (!tokenElement.value) {
                event.preventDefault();
                const token = await mp.fields.createCardToken({
                cardholderName: document.getElementById('form-checkout__cardholderName').value,
                identificationType: document.getElementById('form-checkout__identificationType').value,
                identificationNumber: document.getElementById('form-checkout__identificationNumber').value,
                });
                tokenElement.value = token.id;
                let infoPayChosen={
                    identification:token.cardholder.identification.number,
                    typeIdentification:token.cardholder.identification.type,
                    expiration_month: token.expiration_month,
                    expiration_year: token.expiration_year,
                    last_four_digits: token.last_four_digits,
                    transaction_amount: valueChildsMp.toString(),
                    emitter: inssuerPer,
                    first_eigth_digits:binPer,
                }
                requestSubmit(token,infoPayChosen);
            }
            } catch (e) {
            console.error('error creating card token: ', e)
            }
    }
    
    const requestSubmit = (token,infoPayChosen)=>{
        let payment_data = {
        transaction_amount: parseInt(document.getElementById('transactionAmount').value),
        token:  document.getElementById('token').value,
        description:  document.getElementById('description').value,
        installments:  document.getElementById('form-checkout__installments').value,
        payment_method_id:  document.getElementById('paymentMethodId').value,
        issuer_id:  document.getElementById('form-checkout__issuer').value,
        email:  document.getElementById('form-checkout__email').value,
        type: document.getElementById('form-checkout__identificationType').value,
        number: document.getElementById('form-checkout__identificationNumber').value,    
        };   
        registerPay(payment_data)
        .then((res)=>{
            props.onCallback({res,infoPayChosen});
        })
        .catch((e)=>{
          console.log("el consumo del servicio al back fallo", e)
        })
        
    }
   

    return (
        <LoadingContainer>
            <form ref={formElement} id="form-checkout" action="/process_payment" method="POST">
            <Spacer bottom={16} />                
            <Wrapper ref={numCard} id="form-checkout__cardNumber" className='container'></Wrapper>
            <Spacer bottom={16} />                       
            <InputRow>
                    <InputWrapper style={{ maxWidth: '45%' }}>
                        <Wrapper id="form-checkout__expirationDate" className='container'></Wrapper>                        
                    </InputWrapper>
                    <Spacer right={16} />
                    <InputWrapper style={{ maxWidth: '45%' }}>
                         <Wrapper id="form-checkout__securityCode" className='container'></Wrapper>                        
                    </InputWrapper>
            </InputRow>
            <Spacer bottom={16} />
            <Input type="text" id="form-checkout__cardholderName" placeholder="Titular de la tarjeta" />
            <Spacer bottom={16} />
            <InputRow>
                    <InputWrapper style={{ maxWidth: '20%' }}>
                        <SelectWrapper
                            id="form-checkout__identificationType"
                            name="identificationType"
                        >
                            <option value="N.N." disabled selected>Tipo de documento</option>
                        </SelectWrapper>
                    </InputWrapper>
                    <Spacer right={16} />
                    <InputWrapper style={{ maxWidth: '70%' }}>
                         <Input type="text" id="form-checkout__identificationNumber" name="identificationNumber" placeholder="Número de documento" />
                    </InputWrapper>
            </InputRow>
            <Spacer bottom={16} />                
            <Input type="email" id="form-checkout__email" name="email" placeholder="Correo Electronico"/>  
            <Spacer bottom={20} />
            <SelectWrapper id="form-checkout__issuer" name="issuer">
              <option value="" disabled selected>Banco emisor</option>
            </SelectWrapper>
            <Spacer bottom={16} />              
            <SelectWrapper id="form-checkout__installments" name="installments">
              <option value="" disabled selected>Cuotas</option>
            </SelectWrapper>
            <Spacer bottom={16} />                
            <input id="token" name="token" placeholder="token" type="hidden"/>
            <input id="paymentMethodId" name="paymentMethodId" placeholder="paymentMethodId" type="hidden"/>
            <input id="transactionAmount" name="transactionAmount"  value="10000" type="hidden"/>
            <input id="description" name="description" value="Elegido" type="hidden"/>
            <Button type="submit" id="form-checkout__submit">Donar</Button>                
          </form>
        </LoadingContainer>
     )   
    }

export default CheckoutMercadoPagoForm;

const LoadingContainer = styled.div`
   width: 100%;
   display: flex;
   justify-content: center;
`;

const Wrapper = styled.div`
   width: 100%;
   outline: none;
   border: 1.2px solid ${(props) => props.theme.neutral.lightest};
   border-radius: 8px;
   height: 60px;
   padding: 16px;


   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   &::placeholder {
      color: ${(props) => props.theme.neutral.lightest};
   }

   &:focus {
      border-color: ${(props) => props.theme.colors.brand.secondary.main};
   }
 
`;

const SelectWrapper = styled.select`
    color:  #8D8F96;
    margin-top: 7px;
    width: 100%;
    outline: none;
    border: 1.2px solid ${(props) => props.theme.neutral.lightest};
    border-radius: 8px;
    height: 60px;


    font-family: ${(props) => props.theme.text.text1.family};
    font-size: ${(props) => props.theme.text.text1.size};
    font-weight: ${(props) => props.theme.text.text1.weight};
    line-height: ${(props) => props.theme.text.text1.lineHeight};

    &::placeholder {
      color: ${(props) => props.theme.neutral.lightest};
   }

   &:focus {
      border-color: ${(props) => props.theme.colors.brand.secondary.main};
   }
`

const Button = styled.button`
    font-size: 18px;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    border-radius: 90px;
    width: 90%;
    max-width: 380px;
    justify-content: center;
    margin: 7%;

    background:  ${(props) => props.theme.colors.brand.primary.main};
    font-family: ${(props) => props.theme.text.text1.family};
    color: ${(props) => props.theme.neutral.white};

`

const Label = styled.label`
   display: flex;
   justify-content: center;

   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   color: ${(props) => props.theme.neutral.sub};
`

// background: ${(props) =>
//       true
//          ? props.theme.neutral.lightest
//          : props.theme.colors.brand.primary.main};