import styled from 'styled-components'
import Input, { InputProps } from './Input'
import { CountryCode, getCountries } from 'libphonenumber-js'
import { useEffect, useState } from 'react'
import Spacer from './Spacer'
import useForceUpdate from 'hooks/useForceUpdate'
import ReactCountryFlag from 'react-country-flag'

type PhoneInputProps = InputProps & {
   initialCountry: string
   onCountryChanged?: (value: string) => void
}

const cdn =
   'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/'

const PhoneInput = ({
   initialCountry,
   onCountryChanged,
   ...inputProps
}: PhoneInputProps): JSX.Element => {
   const { error } = inputProps

   const [selectedCountry, setSelectedCountry] =
      useState<string>(initialCountry)
   const [countries, setCountries] = useState<CountryCode[]>([])
   const [opened, setOpened] = useState<boolean>(false)
   const forceUpdate = useForceUpdate()

   useEffect(() => {
      setCountries(getCountries())
   }, [])

   useEffect(() => {
      setSelectedCountry(initialCountry)
   }, [initialCountry])

   const handlerCountrySelect = (countryCode: string) => {
      const countryCodeUppercase = countryCode.toUpperCase()
      setSelectedCountry(countryCodeUppercase)
      if (onCountryChanged) onCountryChanged(countryCodeUppercase)
      setOpened(false)
      forceUpdate()
   }

   const renderCountry = (countryCode: CountryCode, index: number) => {
      const countryCodeLower = countryCode.toLowerCase()

      return (
         <CountriesItem
            key={index}
            type="button"
            onClick={() => {
               handlerCountrySelect(countryCodeLower)
            }}
         >
            <CountriesItemImage
               countryCode={countryCodeLower}
               svg
               cdnUrl={cdn}
            />
            <Spacer right={10} />
            {countryCode}
         </CountriesItem>
      )
   }

   return (
      <Wrapper>
         <CountryButton
            type="button"
            error={!!error}
            onClick={() => setOpened((_opened) => !_opened)}
         >
            <CountriesItemImage
               countryCode={selectedCountry}
               svg
               cdnUrl={cdn}
            />
         </CountryButton>

         <StyledInput
            {...inputProps}
            mask={{
               phone: true,
               phoneRegionCode: selectedCountry,
            }}
         />

         {opened && (
            <CountriesList>{countries.map(renderCountry)}</CountriesList>
         )}
      </Wrapper>
   )
}

const Wrapper = styled.div`
   display: flex;
   flex: 1;
   flex-direction: row;
   position: relative;
`

const StyledInput = styled(Input)`
   padding-left: 55px;
`

const CountryButton = styled.button<{ error?: boolean }>`
   position: absolute;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 40px;
   height: 40px;
   bottom: ${(props) => (props.error ? 41 : 9)}px;
   left: 9px;
   outline: none;
   border: none;
   background-color: white;
   cursor: pointer;
`

const CountriesList = styled.div`
   position: absolute;
   left: 0;
   bottom: -210px;
   box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
   border-radius: 8px;
   background-color: white;
   padding: 16px;
   width: 100%;
   height: 200px;
   overflow-x: scroll;
   overflow: auto;

   z-index: 1000;
`

const CountriesItem = styled.button`
   display: flex;
   flex-direction: row;
   align-items: center;
   width: 100%;
   padding: 10px 0;

   outline: none;
   border: none;
   background-color: white;
   cursor: pointer;

   border-bottom: 1px solid rgba(0, 0, 0, 0.1);

   font-family: ${(props) => props.theme.text.title.family};
   font-size: ${(props) => props.theme.text.title.size};
   font-weight: ${(props) => props.theme.text.title.weight};
   line-height: ${(props) => props.theme.text.title.lineHeight};
   color: ${(props) => props.theme.neutral.main};
`

const CountriesItemImage = styled(ReactCountryFlag)`
   width: 25px !important;
   height: 25px !important;

   background-size: contain;
   background-position: center;
   background-color: ${(props) => props.theme.neutral.white};
   background-repeat: no-repeat;
`

export default PhoneInput
