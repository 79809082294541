import Cleave from 'cleave.js/react'
import { CleaveOptions } from 'cleave.js/options'
import { Fragment, InputHTMLAttributes } from 'react'
import styled, { css, CSSProperties } from 'styled-components'
import Spacer from './Spacer'

const Wrapper = styled.div`
   display: flex;
   flex-direction: column;
   flex: 1;
`

const Label = styled.label`
   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   color: ${(props) => props.theme.neutral.sub};
`

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
   label?: string
   error?: string
   wrapperStyle?: CSSProperties
   component?: JSX.Element
   mask?: CleaveOptions
   observation?: string
   renderLabel?: (props: InputProps) => JSX.Element
   renderInput?: (props: InputProps) => JSX.Element
}

const InputStyle = css<InputProps>`
   padding: 16px;

   outline: none;

   border: 1.2px solid ${(props) => props.theme.neutral.lightest};
   border-radius: 8px;

   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   transition: border-color ease 0.5s;
   will-change: border-color;
   color: ${(props) => props.theme.neutral.sub};

   &::placeholder {
      color: ${(props) => props.theme.neutral.lightest};
   }

   &:focus {
      border-color: ${(props) => props.theme.colors.brand.secondary.main};
   }
   &:disabled {
      cursor: not-allowed;
   }
   ${(props) =>
      props.error &&
      css`
         border-color: ${(props) => props.theme.feedback.error.dark};
      `}
`

const StyledInput = styled.input<InputProps>`
   ${InputStyle};
`

const StyledMaskInput = styled(Cleave)`
   ${InputStyle};
`

const FormFeedback = styled.div`
   width: 100%;
   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   color: ${(props) => props.theme.feedback.error.dark};

   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
`

const Observation = styled.div`
   font-family: ${(props) => props.theme.text.text1.family};
   font-size: 14px;
   line-height: 18px;
   font-weight: ${(props) => props.theme.text.text1.weight};
   color: ${(props) => props.theme.neutral.sub};
   margin-top: ${(props) => props.theme.spacing.xs};
`

const Input = (inputProps: InputProps): JSX.Element => {
   const {
      label,
      wrapperStyle,
      error,
      mask,
      renderInput,
      renderLabel,
      observation,
      ...props
   } = inputProps

   const renderEntry = () => {
      if (renderInput) {
         return renderInput(inputProps)
      }

      if (mask) {
         return <StyledMaskInput error={error} options={mask} {...props} />
      }

      return <StyledInput error={error} {...props} />
   }

   return (
      <Wrapper style={wrapperStyle}>
         {renderLabel
            ? renderLabel(inputProps)
            : label && <Label>{label}</Label>}
         <Spacer bottom={8} />
         {renderEntry()}

         {observation && <Observation>{observation}</Observation>}

         {error && (
            <Fragment>
               <Spacer top={8} />
               <FormFeedback>{error}</FormFeedback>
            </Fragment>
         )}
      </Wrapper>
   )
}

export const InputWrapper = styled.div`
   display: flex;
   flex: 1;
   flex-direction: column;
`

export const InputRow = styled.div`
   display: flex;
   flex-wrap: wrap;
   flex-direction: row;
   justify-content: space-between;
   align-items: flex-start;
`

export const InputRowCenter = styled(InputRow)`
   align-items: center;
`

export default Input
