import { ButtonHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import Spacer from './Spacer'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
   label: string
   full?: boolean
}

const StyledButton = styled.button<Omit<ButtonProps, 'label'>>`
   display: flex;
   flex: 1;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   padding: 16px 24px;

   background: ${(props) =>
      props.disabled
         ? props.theme.neutral.lightest
         : props.theme.colors.brand.primary.main};
   border-radius: 90px;

   border: none;
   outline: none;

   cursor: pointer;

   ${(props) =>
      props.disabled &&
      css`
         cursor: not-allowed;
         span {
            color: ${(props) => props.theme.neutral.lightness} !important;
         }
      `}

   ${(props) =>
      props.full &&
      css`
         width: 100%;
      `}
`

const ButtonText = styled.span`
   font-family: ${(props) => props.theme.text.title.family};
   font-size: ${(props) => props.theme.text.title.size};
   font-weight: ${(props) => props.theme.text.title.weight};
   line-height: ${(props) => props.theme.text.title.lineHeight};

   color: ${(props) => props.theme.neutral.white};
`

const Button = ({ label, ...props }: ButtonProps): JSX.Element => {
   return (
      <StyledButton {...props}>
         <ButtonText>{label}</ButtonText>
      </StyledButton>
   )
}

export default Button

const StyledBackButton = styled.button<Omit<ButtonProps, 'label'>>`
   display: flex;
   flex: 1;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   padding: 16px 24px;

   background: transparent;
   border-radius: 90px;

   border: none;
   outline: none;

   cursor: pointer;

   ${(props) =>
      props.full &&
      css`
         width: 100%;
      `}
`
const BackButtonIcon = styled.i`
   font-size: 18px;
   color: ${(props) => props.theme.colors.brand.secondary.main};
`

const BackButtonText = styled.span`
   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   color: ${(props) => props.theme.neutral.sub};
`

const BackButton = ({ label, ...props }: ButtonProps): JSX.Element => {
   return (
      <StyledBackButton {...props}>
         <BackButtonIcon className="fas fa-arrow-left" />
         <Spacer right={11} />
         <BackButtonText>{label}</BackButtonText>
      </StyledBackButton>
   )
}

export { BackButton }

export const ButtonRow = styled.div`
   display: flex;
   flex-wrap: wrap;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   width: 100%;
`
