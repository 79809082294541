import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import api from 'services/api'

type RequestProps = {
   donor_photo_base64: string
   id: string
}

export const newDonorPhoto = createAsyncThunk(
   'donorImage',
   async ({ donor_photo_base64, id }: RequestProps) => {
      return await api
         .post(`checkout/upload-photo-donor/${id}`, {
            folderName: id,
            imageName: 'profilePicture',
            donor_photo_base64: donor_photo_base64,
         })
         .then((res) => console.log(res.data))
   },
)

const newDonorPhotoSlice = createSlice({
   name: 'donorImage',
   initialState: {
      status: 'idle',
      donor_photo_base64: '',
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(newDonorPhoto.pending, (state) => {
         state.status = 'loading'
      })
      builder.addCase(newDonorPhoto.fulfilled, (state) => {
         state.status = 'ok'
      })
      builder.addCase(newDonorPhoto.rejected, (state) => {
         state.status = 'rejected'
      })
   },
})

export default newDonorPhotoSlice.reducer
