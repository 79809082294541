const zipCodeHandler = () => {
   let cepRegex = /[0-9]{5}-[0-9]{3}$/g

   if (process.env.REACT_APP_LANGUAGE === 'mx') {
      cepRegex = /^\d{4,5}$/g
   }

   return cepRegex
}

const documentHandler = () => {
   let documentRegex =
      /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/g

   if (process.env.REACT_APP_LANGUAGE === 'mx') {
      documentRegex =
         /^([A-ZÑ\x26]{3,4}[ X]([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))[ X]((-)?([A-Z\d]{3}))?$/
   }

   return documentRegex
}

export const documentRegex = documentHandler()

export const PhoneRegex = /\([0-9]{2}\)\s[0-9]{4,5}-[0-9]{4}/g

export const CepRegex = zipCodeHandler()

export const DateRegex = /^[0-9]{2}[/][0-9]{2}[/][0-9]{4}$/g

export const HourRegex = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/g

export const REQUIRED_LABEL = 'Campo obrigatório'

export const REQUIRED_CHECK = 'Necessário confirmar'

export const INVALID_FORMAT = 'Formato inválido'

export const INVALID_LEGALDATE = 'Idade Inválida'

export const EXPIRED = 'Expirado'
