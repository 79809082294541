import useRegionalization from 'hooks/useRegionalization';
import { useFormatMessage } from 'react-intl-hooks';
import styled, { css } from 'styled-components/macro';
import Container from './Container';

const FooterTermsAndUsers = (): JSX.Element => {
   const { country } = useRegionalization();
   const t = useFormatMessage();

   return (
      <Footer>
         <SocialNetworks>
            <Container>
               <FooterStyled>
                  <LogoVmb>
                     {country === 'mx' ? (
                        <Img src="./assets/logo.png" />
                     ) : (
                        <Img src="./assets/logo-background.png" />
                     )}
                  </LogoVmb>
                  <DescriptionFooter>
                     {country === 'br'
                        ? '2020 @Copyright Visão Mundial - visaomundial.org'
                        : '@Copyright World Vision Colombia  - www.worldvision.co V.1.4.0'}
                  </DescriptionFooter>
                  <ListSocialNetwork>
                     <li>
                        <a
                           target="_blank"
                           href={
                              country === 'mx'
                                 ? 'https://www.facebook.com/WorldVisionCO'
                                 : '#'
                           }
                        >
                           <img src="./assets/images/facebook.png" alt="" />
                        </a>
                     </li>
                     <li>
                        <a
                           target="_blank"
                           href={
                              country === 'mx'
                                 ? 'https://www.instagram.com/worldvisionco'
                                 : '#'
                           }
                        >
                           <img src="./assets/images/instagram.png" alt="" />
                        </a>
                     </li>
                     <li>
                        <a
                           target="_blank"
                           href={
                              country === 'mx'
                                 ? 'https://www.youtube.com/user/VMCol'
                                 : '#'
                           }
                        >
                           <img src="./assets/images/youtube.png" alt="" />
                        </a>
                     </li>
                     <li>
                        <a
                           target="_blank"
                           href={
                              country === 'mx'
                                 ? 'https://twitter.com/WorldVisionCO'
                                 : '#'
                           }
                        >
                           <img src="./assets/images/twitter.png" alt="" />
                        </a>
                     </li>
                  </ListSocialNetwork>
               </FooterStyled>
            </Container>
         </SocialNetworks>
      </Footer>
   );
};

const Footer = styled.footer``;

const SocialNetworks = styled.div(
   ({ theme }) => css`
      background: ${theme.neutral.main};
      padding: 80px 0;

      @media (max-width: 767px) {
         padding: 40px 0;
      }
   `,
);

const FooterStyled = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   @media (max-width: 1020px) {
      flex-direction: column;
      justify-content: center;
   }
`;

const LogoVmb = styled.div`
   flex: 1;
   @media (max-width: 1020px) {
      margin-top: 20px;
   }
`;

const DescriptionFooter = styled.p(
   ({ theme }) => css`
      flex: 1;
      text-align: center;
      font-family: ${theme.text.text1.family};
      font-size: ${theme.text.text1.size};
      font-weight: ${theme.text.text1.weight};
      color: ${theme.neutral.lightness};
      min-width: 600px !important;
      @media (max-width: 1020px) {
         margin-top: 20px;
         font-family: ${theme.text.text1.family};
         font-size: ${theme.text.text1.size};
         line-height: ${theme.text.text1.lineHeight};
         font-weight: ${theme.text.text1.weight};
      }

      @media (max-width: 630px) {
         min-width: 300px !important;
      }
   `,
);

const ListSocialNetwork = styled.ul`
   list-style: none;
   display: flex;
   justify-content: flex-end;
   flex: 1;

   li {
      margin-left: 16px;

      &:first-child {
         margin-left: 0;
      }
   }

   @media (max-width: 1020px) {
      margin-top: 20px;
      margin-bottom: 20px;
   }
`;
const Authorship = styled.p(
   ({ theme }) => css`
      padding: 24px;
      background: ${theme.neutral.background};
      font-family: ${theme.text.text1.family};
      font-size: ${theme.text.text1.size};
      line-height: ${theme.text.text1.lineHeight};
      font-weight: ${theme.text.text1.weight};
      color: ${theme.neutral.sub};
      display: flex;
      align-items: center;
      justify-content: center;

      i {
         color: ${theme.colors.brand.primary.main};
         margin-left: 4px;
         margin-right: 4px;
         font-size: calc(${theme.text.text1.size} - 2px);
      }

      a {
         display: inline-block;
         margin-left: 4px;
         color: ${theme.neutral.sub};
         text-decoration: none;
      }
   `,
);

const Img = styled.img(
   ({ theme }) => css`
      max-width: 140px;
      width: 100%;
      object-fit: contain;
   `,
);
export default FooterTermsAndUsers;
