import defaultTheme from 'styles/theme'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import LanguageProvider from 'components/Language'
import Router from 'routes/Router'
import pipe from 'utils/pipe'
import withProvider from 'utils/withProvider'
import { RegionalizationProvider } from 'hooks/useRegionalization'
import 'react-phone-number-input/style.css'
import useModal, { ModalProvider } from 'hooks/useModal'
import { store } from './services/store'
import { Provider } from 'react-redux'

require('intl')
require('intl/locale-data/jsonp/pt-BR')

const GlobalStyle = createGlobalStyle<{ lockScroll: boolean }>`

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
    background-color: ${(props) => props.theme.neutral.background};
      overflow: ${(props) => (props.lockScroll ? 'hidden' : 'auto')}
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    box-sizing: border-box;
  }
`

const App = (): JSX.Element => {
   const { modalOpen } = useModal()

   return (
      <LanguageProvider>
         <ThemeProvider theme={defaultTheme}>
            <GlobalStyle lockScroll={modalOpen} />
            <Provider store={store}>
               <Router />
            </Provider>
         </ThemeProvider>
      </LanguageProvider>
   )
}

const enhance = pipe(
   withProvider(RegionalizationProvider),
   withProvider(ModalProvider),
)

export default enhance(App)
