import StepOne from '../../assets/images/card.png';
import StepTwo from '../../assets/images/pse.png';
import { ButtonRow } from 'components/Button';
import { BlueCard } from 'components/Card';
import Choices, { Choice } from 'components/Choices';
import theme from '../../styles/theme';
import Spacer from 'components/Spacer';
import { Form, Formik } from 'formik';
import useRegionalization from 'hooks/useRegionalization';
import { Fragment, useEffect, useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import {
   documentRegex,
   EXPIRED,
   INVALID_FORMAT,
   REQUIRED_LABEL,
} from 'services/validators';
import styled, { css } from 'styled-components';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
   DonorFormProps,
   newDonationPayment,
} from 'services/slices/checkoutDonationPayment';
import { RootState } from 'services/store';
import { toast } from 'react-toastify';
import useCheckoutForm from 'hooks/useCheckoutForm';
import {
   donationProducts,
   DonationProductsProps,
} from 'services/slices/donationProducts';
import ReactLoading from 'react-loading';
import formatMoney from 'utils/formatMoney';
import { getPaymentMethods } from 'utilsJs/mercadopagoe';
import CheckoutMercadoPagoForm from './CheckoutMercadoPagoForm';
import CheckoutPse from './CheckoutPse';
import Spinner from '../../components/spinner';

type Values = {
   childs: string;
   revelation: string;
   document: string;
   cc_number: string;
   cc_name: string;
   cc_exp: string;
   cc_csc: string;
   type: string;
   installments: string;
};
7;

const initialValues: Values = {
   childs: '',
   revelation: '',
   document: '',
   cc_number: '',
   cc_name: '',
   cc_exp: '',
   cc_csc: '',
   type: '',
   installments: '',
};

type CheckoutDonationFormProps = {
   onBack: () => void;
   onSubmit: (values: Values) => void;
};

type ChildChoice = {
   id: string;
   label: string;
   value: number;
   indicator: string;
};

type ChildRevelation = {
   label: string;
   description: string;
   indicator: string;
   value: number;
   id: string;
};

const CheckoutDonationForm = ({
   onBack,
   onSubmit,
}: CheckoutDonationFormProps): JSX.Element => {
   const { country } = useRegionalization();
   const { regionalization } = useRegionalization();
   const t = useFormatMessage();
   const dispatch = useDispatch();
   const { checkoutFormData } = useCheckoutForm();

   const [childChoice, setChildChoice] = useState<number>(-1);
   const [childs, setChilds] = useState<string>('3');
   const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
   const [revelationChoice, setRevelationChoice] = useState<number>(-1);
   const [donationInfo, setDonationInfo] = useState<DonationProductsProps>();
   const [digitalRevelation, setDigitalRevelation] =
      useState<DonationProductsProps>();
   const [physicalRevelation, setPhysicalRevelation] =
      useState<DonationProductsProps>();
   const [donationTypeSelected, setDonationTypeSelected] = useState<string>();
   const [revelationTypeSelected, setRevelationTypeSelected] =
      useState<string>();
   const [isExpanded, setIsExpanded] = useState(false);
   const [isExpandedPSE, setIsExpandedPSE] = useState(false);

   const [targetCcNum, settargetCcNum] = useState('');
   const [instalments, setinstalments] = useState('');
   const [typeDocument, settypeDocument] = useState('');
   const [loading, setLoading] = useState(false);

   const { id, response, loadingStatus } = useSelector((state: RootState) => ({
      id: state.newDonor.id,
      loadingStatus: state.donationProducts.status,
      response: state.donationProducts.response,
   }));

   useEffect(() => {
      dispatch(donationProducts());
   }, []);

   useEffect(() => {
      setDonationInfo(
         response.find(
            (item: DonationProductsProps) => item.type === 'donation',
         ),
      );
      setDigitalRevelation(
         response.find(
            (item: DonationProductsProps) =>
               item.description_type === 'digital',
         ),
      );

      setPhysicalRevelation(
         response.find(
            (item: DonationProductsProps) =>
               item.description_type === 'physical',
         ),
      );
   }, [response]);

   const oChangeTargetCcNum = (e) => {
      settargetCcNum(e.target.value);
      const cardNum = e.target.value;
      if (cardNum.length === 9) {
         console.log(getPaymentMethods(cardNum));
      }
   };

   const handleToggle = () => {
      if (isExpandedPSE) {
         setIsExpandedPSE(!isExpandedPSE);
      }
      setIsExpanded(!isExpanded);
      setTimeout(() => {
         const currentPosition = window.pageYOffset;
         const scrollAmount = 400;
         window.scrollTo(0, currentPosition + scrollAmount);
      }, 150);
   };

   const handleTogglePSE = () => {
      if (isExpanded) {
         setIsExpanded(!isExpanded);
      }
      setIsExpandedPSE(!isExpandedPSE);
      setTimeout(() => {
         const currentPosition = window.pageYOffset;
         const scrollAmount = 300;
         window.scrollTo(0, currentPosition + scrollAmount);
      }, 150);
   };

   const handEndToPage = () => {
      setTimeout(() => {
         window.scrollTo(0, document.body.scrollHeight);
      }, 150);
   };

   const onChangeTypeDocument = (e) => {
      settypeDocument(e.target.value);
   };

   const onChangeinstalments = (e) => {
      setinstalments(e.target.value);
   };

   const revelationChoices: Choice<ChildRevelation>[] = [
      {
         data: {
            label: t({ id: 'Revelacao fisica e digital' }).toString(),
            description: t({
               id: 'Receba no conforto da sua casa uma cartinha escrita a mao e uma foto da crianca que te escolheu.',
            }).toString(),
            indicator: 'physical',
            value: physicalRevelation ? physicalRevelation.price : 0,
            id: physicalRevelation ? physicalRevelation.id : '0',
         },
         component: (data) => (
            <ChildCard align="flex-start">
               <ChildCardInfo>
                  <ChildCardText>{data.label}</ChildCardText>
                  <Spacer bottom={8} />

                  <ChildCardDescription>
                     {data.description}
                  </ChildCardDescription>
                  <Spacer bottom={8} />

                  <ChildCardValue>{formatMoney(data.value)}</ChildCardValue>
               </ChildCardInfo>
               <Spacer right={24} />
               <ChildCardIcon size={56} src="/assets/icons/group_19450.png" />
            </ChildCard>
         ),
      },
      {
         data: {
            label: t({ id: 'Revelacao digital' }).toString(),
            description: t({
               id: 'Receba no conforto da sua casa uma cartinha escrita a mao e uma foto da crianca que te escolheu.',
            }).toString(),
            indicator: 'digital',
            value: digitalRevelation ? digitalRevelation.price : 0,
            id: digitalRevelation ? digitalRevelation.id : '0',
         },
         component: (data) => (
            <ChildCard align="flex-start">
               <ChildCardInfo>
                  <ChildCardText>{data.label}</ChildCardText>
                  <Spacer bottom={8} />

                  <ChildCardDescription>
                     {data.description}
                  </ChildCardDescription>
                  <Spacer bottom={8} />

                  <ChildCardValue> {formatMoney(data.value)}</ChildCardValue>
               </ChildCardInfo>
               <ChildCardIcon size={56} src="/assets/icons/group_19451.png" />
            </ChildCard>
         ),
      },
   ];

   /**
    * Submit para el pago con Tarjeta
    * @param data
    */
   const handleCallback = async (data) => {
      setIsSubmiting(true);
      const statusResponse = data.res.data.status;
      const dataResponse = {
         card: {
            emitter: data.infoPayChosen.emitter,
            expiration_month: data.infoPayChosen.expiration_month,
            expiration_year: data.infoPayChosen.expiration_year,
            first_eigth_digits: data.infoPayChosen.first_eigth_digits,
            last_four_digits: data.infoPayChosen.last_four_digits,
         },
         identification: data.infoPayChosen.identification,
         typeIdentification: data.infoPayChosen.typeIdentification,
         transaction: data.res.data,
         transaction_amount: data.infoPayChosen.transaction_amount,
         payment_method_id: 'card',
         contactForm: {
            email: checkoutFormData.email,
            firstName: checkoutFormData.first_name,
            lastName: checkoutFormData.last_name,
            phone: checkoutFormData.phone.value,
         },
      };

      const response: any = await dispatch(
         newDonationPayment({ data: dataResponse, id: id }),
      );

      if (newDonationPayment.fulfilled.match(response)) {
         if (statusResponse === 'approved') {
            toast.success(t({ id: 'Cadastro feito com sucesso' }), {
               hideProgressBar: true,
               autoClose: 2500,
            });
            setIsSubmiting(statusResponse);

            if (onSubmit) {
               setTimeout(() => {
                  onSubmit(data);
               }, 2500);
            }
         } else {
            toast.error(t({ id: 'Não foi possível cadastrar seu cartão' }));
            setIsSubmiting(statusResponse);

            if (onSubmit) {
               setTimeout(() => {
                  onSubmit(null);
               }, 2500);
            }
         }
      } else {
         toast.error(t({ id: 'Error al Guardar su informacion' }), {
            hideProgressBar: true,
            autoClose: 2500,
         });
         setIsSubmiting(false);
      }
   };

   /**
    *
    * @param data
    */
   const handlCallbackPse = async (data) => {
      setIsSubmiting(true);
      const url = data.res.data.external_resource_url;
      const statusResponsePSE = data.res.data.status;
      const dataResponse = {
         card: {
            emitter: '',
            expiration_month: 0,
            expiration_year: 0,
            first_eigth_digits: '',
            last_four_digits: '',
         },
         identification: data.payment_data.payer.identification.number,
         typeIdentification: data.payment_data.payer.identification.type,
         transaction: data.res.data,
         transaction_amount: data.payment_data.transaction_amount,
         payment_method_id: data.payment_data.payment_method_id,
         contactForm: {
            email: data.payment_data.payer.email,
            firstName: '',
            lastName: '',
            phone: '',
         },
      };

      const response: any = await dispatch(
         newDonationPayment({ data: dataResponse, id: id }),
      );

      if (newDonationPayment.fulfilled.match(response)) {
         if (statusResponsePSE === 'pending') {
            toast.success(t({ id: 'Redirecionando para PSE' }), {
               hideProgressBar: true,
               autoClose: 2500,
            });

            window.location.href = url;
         } else {
            toast.error(t({ id: 'Não consigo acessar o banco' }));
            setIsSubmiting(statusResponsePSE);

            if (onSubmit) {
               setTimeout(() => {
                  onSubmit(null);
               }, 2500);
            }
         }
      } else {
         toast.error(t({ id: 'Error al Guardar su informacion' }), {
            hideProgressBar: true,
            autoClose: 2500,
         });
         setIsSubmiting(false);
      }
   };

   /**
    * no esta en uso el submit por q se conecta a traves de modulo de mercadoPago
    * @param values
    */
   const handleSubmit = async (values: Values) => {
      setIsSubmiting(true);

      const auxRevelationType =
         country === 'mx' ? digitalRevelation.id : revelationTypeSelected;

      const data: DonorFormProps = {
         document: {
            type: typeDocument,
            value: values.document,
         },
         card: {
            number: targetCcNum.replace(/ /g, ''),
            exp_month: values.cc_exp.substr(0, 2),
            exp_year: values.cc_exp.substr(3, 5),
            cvc: values.cc_csc,
            holder_name: values.cc_name,
            installments: Number(instalments),
         },
         address: {
            street: checkoutFormData.address_street,
            number: checkoutFormData.address_number,
            complement: checkoutFormData.address_complement ?? '',
            zipcode: checkoutFormData.address_zipcode,
            neightborhood: checkoutFormData.address_neightborhood,
            city: checkoutFormData.address_city,
            state: checkoutFormData.address_state,
            country: country,
         },
         child_quantity: Number(values.childs),
         items_id: [donationTypeSelected, auxRevelationType],
      };

      if (country === 'mx') {
         delete data.address.complement;
         delete data.address.country;
         delete data.address.neightborhood;
         delete data.address.number;
         delete data.address.state;
         delete data.address.zipcode;
      }

      const response: any = () => {
         console.log('');
      };
      // await dispatch(
      //    newDonationPayment({ data: data, id: id }),
      // )

      if (newDonationPayment.fulfilled.match(response)) {
         toast.success(t({ id: 'Cadastro feito com sucesso' }), {
            hideProgressBar: true,
            autoClose: 2500,
         });
         setIsSubmiting(false);

         if (onSubmit) {
            setTimeout(() => {
               onSubmit(values);
            }, 2500);
         }
      } else {
         toast.error(t({ id: 'Não foi possível cadastrar seu cartão' }));
         setIsSubmiting(false);
      }
   };

   const FormSchema = yup.object().shape({
      childs: yup.string().required(REQUIRED_LABEL),
      revelation:
         country === 'mx'
            ? yup.string()
            : yup.string().required(REQUIRED_LABEL),
      document:
         country === 'mx'
            ? yup.string()
            : yup
                 .string()
                 .required(REQUIRED_LABEL)
                 .matches(documentRegex, INVALID_FORMAT),
      cc_number: yup.string(),
      cc_name: yup.string().required(REQUIRED_LABEL),
      cc_exp: yup
         .string()
         .required(REQUIRED_LABEL)
         .test('CVV', EXPIRED, (value: string) => {
            if (value) {
               const mes = Number(value.slice(0, 2));
               const year = value.slice(3, 5);
               const actualYear = Number(`20${year}`);
               const data = new Date(actualYear, mes);

               return data > new Date();
            }
         }),
      cc_csc: yup.string().required(REQUIRED_LABEL),
   });

   const childsObject = (values: Values) => {
      let number = 0;

      switch (values.childs) {
         case '1':
            number = 1;
            break;
         case '2':
            number = 2;
            break;
         case childs:
            number = Number(childs);
            break;
      }

      return {
         number,
         value: donationInfo?.price ? number * donationInfo.price : 0,
      };
   };

   const revelationsObject = (values: Values) => {
      let label = '';
      let value = 0;

      switch (values.revelation) {
         case 'physical':
            label = t({ id: 'Revelacao fisica e digital' }).toString();
            value = physicalRevelation.price;
            break;
         case 'digital':
            label = t({ id: 'Revelacao digital' }).toString();
            value = digitalRevelation.price;
            break;
      }

      return {
         label,
         value,
      };
   };

   const total = (values: Values) => {
      return (
         childsObject(values).number * donationInfo?.price +
         revelationsObject(values).value
      );
   };

   return (
      <Wrapper>
         {loadingStatus === 'loading' && (
            <LoadingContainer>
               <ReactLoading
                  type="spin"
                  color={theme.colors.brand.primary.main}
               />
            </LoadingContainer>
         )}

         {loadingStatus === 'ok' && (
            <Formik
               initialValues={initialValues}
               onSubmit={handleSubmit}
               validationSchema={FormSchema}
            >
               {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
               }) => {
                  const childChoices: Choice<ChildChoice>[] = [
                     {
                        data: {
                           label: t({ id: 'Uma crianca' }).toString(),
                           value: donationInfo ? donationInfo.price : 0,
                           indicator: '1',
                           id: donationInfo ? donationInfo.id : '0',
                        },
                        component: (data) => {
                           return (
                              <ChildCard align="center">
                                 <ChildCardInfo>
                                    <ChildCardText>{data.label}</ChildCardText>
                                    <Spacer bottom={8} />

                                    <ChildCardValue>
                                       {formatMoney(data.value)}
                                    </ChildCardValue>
                                 </ChildCardInfo>
                                 <ChildCardIcon
                                    size={80}
                                    src="/assets/icons/group_19453.png"
                                 />
                              </ChildCard>
                           );
                        },
                     },
                     {
                        data: {
                           label: t({ id: 'Duas criancas' }).toString(),
                           value: donationInfo ? donationInfo.price * 2 : 0,
                           indicator: '2',
                           id: donationInfo ? donationInfo.id : '0',
                        },
                        component: (data) => (
                           <ChildCard align="center">
                              <ChildCardInfo>
                                 <ChildCardText>{data.label}</ChildCardText>
                                 <Spacer bottom={8} />

                                 <ChildCardValue>
                                    {formatMoney(data.value)}
                                 </ChildCardValue>
                              </ChildCardInfo>
                              <ChildCardIcon
                                 size={80}
                                 src="/assets/icons/group_19454.png"
                              />
                           </ChildCard>
                        ),
                     },
                     {
                        data: {
                           label: t({
                              id: 'Informe a quantidade',
                           }).toString(),
                           value: donationInfo ? donationInfo.price : 0,
                           indicator: childs,
                           id: donationInfo ? donationInfo.id : '0',
                        },
                        component: (data) => (
                           <ChildCard align="center">
                              <ChildCardInfo>
                                 <ChildCardText>{data.label}</ChildCardText>
                                 <Spacer bottom={8} />

                                 <ChildCardInput
                                    type="number"
                                    value={childs}
                                    min={3}
                                    onChange={(e) => {
                                       setChilds(e.target.value);
                                       handleChange('childs')(e.target.value);
                                    }}
                                 />
                              </ChildCardInfo>

                              <ChildCardValue>
                                 {formatMoney(
                                    Number(childs) * Number(data.value),
                                 )}
                              </ChildCardValue>
                           </ChildCard>
                        ),
                     },
                  ];

                  return (
                     <Form onSubmit={handleSubmit}>
                        <Title>
                           {t({ id: 'Quantas criancas podem te escolher' })}
                        </Title>
                        <Spacer bottom={40} />

                        <Choices
                           choices={childChoices}
                           selected={childChoice}
                           onSelectedChange={(
                              choice: Choice<ChildChoice>,
                              index,
                           ) => {
                              handEndToPage();
                              setChildChoice(index);
                              handleChange('childs')(choice.data.indicator);
                              setDonationTypeSelected(choice.data.id);
                           }}
                        />
                        <Spacer bottom={40} />

                        {country !== 'mx' && (
                           <Fragment>
                              <Title>
                                 {t({ id: 'Selecione o Tipo de revelacao' })}
                              </Title>
                              <Spacer bottom={16} />

                              <Subtitle>
                                 {t({
                                    id: 'Agora e com voce Como voce gostaria de descobrir que foi escolhido',
                                 })}
                              </Subtitle>
                              <Spacer bottom={40} />

                              <Choices
                                 alignItems="flex-start"
                                 choices={revelationChoices}
                                 selected={revelationChoice}
                                 onSelectedChange={(
                                    choice: Choice<ChildRevelation>,
                                    index,
                                 ) => {
                                    setRevelationChoice(index);
                                    handleChange('revelation')(
                                       choice.data.indicator,
                                    );
                                    setRevelationTypeSelected(choice.data.id);
                                 }}
                              />
                              <Spacer bottom={16} />

                              <Subtitle>
                                 *{t({ id: 'Um valor de' })}{' '}
                                 <span>
                                    {physicalRevelation?.price
                                       ? formatMoney(physicalRevelation.price)
                                       : 0}
                                 </span>{' '}
                                 {t({
                                    id: 'por criança sera cobrado para a impressao e envio desse presente apenas uma vez',
                                 })}
                              </Subtitle>
                              <Spacer bottom={40} />
                           </Fragment>
                        )}

                        <BlueCard>
                           <Title>
                              {t({
                                 id: 'Detalhes do pagamento',
                              })}
                           </Title>
                           <Spacer bottom={24} />

                           <ResumeLine>
                              <Subtitle>
                                 {childsObject(values).number}{' '}
                                 {childsObject(values).number == 1 ? (
                                    <>
                                       {t({
                                          id: 'crianca mensal',
                                       })}
                                    </>
                                 ) : (
                                    <>
                                       {t({
                                          id: 'criancas mensal',
                                       })}
                                    </>
                                 )}
                              </Subtitle>
                              <Subtitle accent>
                                 {formatMoney(childsObject(values).value)}
                              </Subtitle>
                           </ResumeLine>
                           {/* <Spacer bottom={8} />

                           <ResumeLine>
                              <Subtitle>
                                 {revelationsObject(values).label}
                                 {t({
                                    id: 'unico',
                                 })}
                              </Subtitle>
                              <Subtitle accent>
                                 {formatMoney(revelationsObject(values).value)}
                              </Subtitle>
                           </ResumeLine> */}
                           <Spacer bottom={24} />

                           <ResumeDivider />
                           <Spacer bottom={24} />

                           <ResumeLine>
                              <Subtitle>
                                 {t({
                                    id: 'valor total',
                                 })}
                              </Subtitle>
                              <ResumeValue>
                                 {formatMoney(total(values))}
                              </ResumeValue>
                           </ResumeLine>
                        </BlueCard>

                        <Spacer bottom={24} />

                        <Title>
                           {t({
                              id: 'Meios de pagamento',
                           })}
                        </Title>
                        <Spacer bottom={28} />
                        <ImageRow>
                           <ImageWrapper>
                              <MercadoPagoIcon
                                 sizeW={100}
                                 sizeH={60}
                                 src="/assets/icons/TransProtec.png"
                              />
                           </ImageWrapper>
                        </ImageRow>
                        <ImageRow>
                           <MercadoPagoIcon
                              sizeW={20}
                              sizeH={30}
                              src="/assets/icons/merPago.ico"
                           />
                           <MercadoPagoIcon
                              style={{ marginTop: '2%' }}
                              sizeW={15}
                              sizeH={25}
                              src="/assets/icons/ver_VISA.png"
                           />
                        </ImageRow>

                        <Spacer bottom={24} />
                        <Steps>
                           <EachStep>
                              <Toggle onClick={handleToggle} type="button">
                                 <img src={StepOne} />
                              </Toggle>
                              <StepTitle>
                                 {t({ id: 'Crédito ou Débito' })}
                              </StepTitle>
                           </EachStep>

                           <EachStep>
                              <Toggle onClick={handleTogglePSE} type="button">
                                 <img src={StepTwo} />
                              </Toggle>
                              <StepTitle>{t({ id: 'Pse' })}</StepTitle>
                           </EachStep>
                        </Steps>

                        {isExpanded && (
                           <div>
                              <LoadingContainer>
                                 <CheckoutMercadoPagoForm
                                    data={values.childs}
                                    onCallback={handleCallback}
                                 />
                              </LoadingContainer>
                           </div>
                        )}

                        {isExpandedPSE && (
                           <Pse>
                              <LoadingContainer>
                                 <CheckoutPse
                                    data={values.childs}
                                    onCallback={handlCallbackPse}
                                 />
                              </LoadingContainer>
                           </Pse>
                        )}
                        <ButtonRow>
                           {/*<BackButton
                           type="button"
                           label={t({ id: 'Voltar' }).toString()}
                           onClick={onBack}
                        />*/}

                           {/* <Button
                              type="submit"
                              label={t({ id: 'Continuar' }).toString()}
                              disabled={
                                 values.childs === '' ||
                                 (revelationTypeSelected === '' &&
                                    country === 'mx') ||
                                 isSubmiting
                              }
                           /> */}
                        </ButtonRow>
                     </Form>
                  );
               }}
            </Formik>
         )}
      </Wrapper>
   );
};

export default CheckoutDonationForm;

const Wrapper = styled.div``;

const LoadingContainer = styled.div`
   display: flex;
   justify-content: center;
`;

const Title = styled.p`
   font-family: ${(props) => props.theme.text.title.family};
   font-size: ${(props) => props.theme.text.title.size};
   font-weight: ${(props) => props.theme.text.title.weight};
   line-height: ${(props) => props.theme.text.title.lineHeight};

   color: ${(props) => props.theme.neutral.main};

   margin: 0;
`;

const Subtitle = styled.p<{ accent?: boolean }>`
   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   color: ${(props) =>
      props.accent
         ? props.theme.colors.brand.secondary.main
         : props.theme.neutral.sub};

   margin: 0;

   span {
      font-weight: bold !important;
   }
`;

const ChildCard = styled.div<{ align: 'flex-start' | 'center' }>`
   display: flex;
   flex: 1;
   flex-direction: row;
   align-items: ${(props) => props.align};
`;

const ChildCardInfo = styled.div`
   display: flex;
   flex: 1;
   flex-direction: column;
`;

const ChildCardText = styled.p`
   font-family: ${(props) => props.theme.text.title.family};
   font-size: ${(props) => props.theme.text.title.size};
   font-weight: ${(props) => props.theme.text.title.weight};
   line-height: ${(props) => props.theme.text.title.lineHeight};

   color: ${(props) => props.theme.neutral.main};

   margin: 0;
`;

const ChildCardDescription = styled.p`
   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   color: ${(props) => props.theme.neutral.sub};

   margin: 0;
`;

const MenuOptions = styled.option`
   border-radius: 10px;
   transition: border-color ease 0.5s;
   &:focus {
      border-color: ${(props) => props.theme.colors.brand.secondary.main};
   }
`;

const ChildCardValue = styled.p`
   font-family: ${(props) => props.theme.text.heading2.family};
   font-size: ${(props) => props.theme.text.heading2.size};
   font-weight: ${(props) => props.theme.text.heading2.weight};
   line-height: ${(props) => props.theme.text.heading2.lineHeight};

   color: ${(props) => props.theme.colors.brand.secondary.main};

   margin: 0;
`;

const ChildCardInput = styled.input`
   max-width: 80px;

   padding: 8px 16px;

   outline: none;

   border: 1.2px solid ${(props) => props.theme.neutral.lightest};
   border-radius: 8px;

   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   transition: border-color ease 0.5s;
   will-change: border-color;
   color: ${(props) => props.theme.neutral.sub};

   &::placeholder {
      color: ${(props) => props.theme.neutral.lightest};
   }

   &:focus {
      border-color: ${(props) => props.theme.colors.brand.secondary.main};
   }
`;

const ChildCardIcon = styled.img<{ size: number }>`
   width: ${(props) => props.size}px;
   height: ${(props) => props.size}px;
   object-fit: contain;
`;

const ResumeLine = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
`;

const ResumeDivider = styled.div`
   width: 100%;
   height: 1px;
   background-color: ${(props) => props.theme.neutral.lightest};
`;

const ResumeValue = styled.p`
   font-family: ${(props) => props.theme.text.heading1.family};
   font-size: ${(props) => props.theme.text.heading1.size};
   font-weight: ${(props) => props.theme.text.heading1.weight};
   line-height: ${(props) => props.theme.text.heading1.lineHeight};

   color: ${(props) => props.theme.colors.brand.secondary.main};

   margin: 0;
`;
const ImageRow = styled.div`
   display: flex;
   flex-wrap: wrap;
   flex-direction: row;
   align-items: flex-start;
`;
const ImageWrapper = styled.div`
   display: flex;
   flex: 1;
   flex-direction: column;
`;

const MercadoPagoIcon = styled.img<{ sizeW: number; sizeH: number }>`
   width: ${(props) => props.sizeW}%;
   height: ${(props) => props.sizeH}%;
   max-width: 450px;
`;

const Toggle = styled.button`
   font-size: 14px;
   display: flex;
   flex: 1;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   padding: 16px 24px;
   border-radius: 20px;
   max-width: 380px;
   justify-content: center;

   background: white;
   font-family: ${(props) => props.theme.text.text1.family};
   color: ${(props) => props.theme.neutral.white};
`;

const EachStep = styled.div(
   ({ theme }) => css`
      flex: 1;

      &:not(:last-child) {
         margin-right: ${theme.spacing.xxl};
      }

      img {
         margin-bottom: 0px;
         width: 100%;
      }

      @media (max-width: 1199px) {
         width: 40%;
         flex: inherit;

         &:not(:last-child) {
            margin-right: 0;
         }
      }

      @media (max-width: 767px) {
         width: 40%;
      }
   `,
);

const Steps = styled.div(
   ({ theme }) => css`
      display: flex;
      align-items: flex-start;
      padding: 0 50px;
      margin-bottom: ${theme.spacing.md};

      @media (max-width: 1199px) {
         flex-wrap: wrap;
         padding: 0;
         justify-content: center;
      }

      @media (max-width: 767px) {
         margin-bottom: ${theme.spacing.md};
      }
   `,
);

const Pse = styled.div`
   margin-left: 5%;
`;

const StepTitle = styled.h6(
   ({ theme }) => css`
      text-align: center;
      font-family: ${theme.text.heading2.family};
      line-height: ${theme.text.heading2.lineHeight};
      color: ${theme.neutral.main};

      @media (max-width: 1199px) {
         font-size: 15px;
         margin-top: -5%;
      }

      @media (max-width: 767px) {
         font-size: 14px;
         margin-top: -5%;
      }
   `,
);
