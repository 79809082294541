import { useFormatMessage } from 'react-intl-hooks'
import styled, { css } from 'styled-components'

interface ConfirmationModalProps {
   onClose: () => void
   onConfirmation: () => void
   isOpen?: boolean
   donorName: string
   status: string
}

type ModalProps = {
   isVisible: boolean
}

export const ConfirmationApproveOrReproveDonor = ({
   onClose,
   onConfirmation,
   isOpen,
   donorName,
   status,
}: ConfirmationModalProps): JSX.Element => {
   const t = useFormatMessage()
   return (
      <AppModalCustom isVisible={isOpen}>
         <ContainerModal>
            <Content>
               <h1>
                  {t({
                     id: `Quer mesmo ${
                        status === 'approved' ? 'aprovar' : 'reprovar'
                     } esta foto`,
                  })}
               </h1>
               <h3>
                  <span>{donorName}</span>
                  {t({ id: 'foi selecionado' })}
               </h3>
               <DivFlex>
                  <ButtonAgree onClick={onConfirmation}>
                     {t({ id: 'Confirmar' })}
                  </ButtonAgree>
                  <ButtonDisagree onClick={onClose}>
                     {t({ id: 'Cancelar' })}
                  </ButtonDisagree>
               </DivFlex>
            </Content>
         </ContainerModal>
      </AppModalCustom>
   )
}

const AppModalCustom = styled.div<ModalProps>(
   ({ theme, isVisible }) => css`
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1001;
      background-color: #6c6f77a2;
      width: 100%;

      opacity: ${isVisible ? 1 : 0};
      pointer-events: ${isVisible ? 'auto' : 'none'};
      transition: all ease 0.2s;

      @media screen and (max-width: 940px) {
         position: fixed;
         top: 0;
         bottom: 0;
         right: 0;
         left: 0;
         width: 100vw;
         background-color: #6c6f77a2;
      }
   `,
)
const DivFlex = styled.div`
   display: flex;

   gap: 2rem;
`
const ContainerModal = styled.div(
   ({ theme }) => css`
      background-color: ${theme.neutral.white};
      max-width: 650px;

      border-radius: 10px;
      margin: 0 auto;
   `,
)

const Content = styled.div(
   ({ theme }) => css`
      margin-top: 10rem;
      display: flex;
      gap: 2rem;
      flex-direction: column;
      font-family: ${theme.text.heading2.family};

      color: ${theme.neutral.main};
      padding: 2.1rem;

      h1 {
         font-family: ${theme.text.heading1.family};
         font-size: ${theme.text.heading1.size};
         font-weight: ${theme.text.heading1.weight};
      }

      h3 {
         font-family: ${theme.text.text1.family};
         font-size: ${theme.text.text1.size};
         font-weight: ${theme.text.text1.weight};

         span {
            font-family: ${theme.text.text1.family};
            font-size: ${theme.text.text1.size};
            font-weight: ${theme.text.title.weight};
            color: ${theme.colors.brand.primary.main};
         }
      }
   `,
)

const ButtonAgree = styled.button(
   ({ theme }) => css`
      padding: 10px 30px;
      border: 0;
      border-radius: 5px;
      background-color: ${theme.feedback.success.main};
      color: ${theme.neutral.white};
      font-family: ${theme.text.heading2.family};
      font-size: ${theme.text.heading2.size};
      font-weight: ${theme.text.heading2.weight};
      transition: 0.2s ease all;
      :hover {
         opacity: 0.8;
      }
   `,
)

const ButtonDisagree = styled.button(
   ({ theme }) => css`
      padding: 10px 30px;
      border: 0;
      border-radius: 5px;
      background-color: ${theme.feedback.error.main};
      color: ${theme.neutral.white};
      font-family: ${theme.text.heading2.family};
      font-size: ${theme.text.heading2.size};
      font-weight: ${theme.text.heading2.weight};
      transition: 0.2s ease all;
      :hover {
         opacity: 0.8;
      }
   `,
)
