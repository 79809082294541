import styled, { css } from 'styled-components/macro'
import { useState, useEffect, Fragment } from 'react'

type PaginationProps = {
   isActive?: boolean
}

const Pagination = ({ pg, setPg, lastPage, total }): JSX.Element => {
   const [showResults, setShowResults] = useState<boolean>(false)
   const [skipButton, setSkipButton] = useState<boolean>(true)

   const handlePaginate = (props: string) => {
      if (props === 'previous') {
         if (pg > 1 && pg < total) {
            setPg(pg - 1)
         }
      } else if (props === 'next') {
         if (pg < lastPage && pg >= 1) {
            setPg(pg + 1)
         }
      }
   }

   useEffect(() => {
      pg > 4 ? setShowResults(true) : setShowResults(false)

      if (lastPage !== 0) {
         pg < lastPage - 3 ? setSkipButton(true) : setSkipButton(false)
      }
      if (lastPage < 5) {
         setShowResults(false)
      }
   }, [pg])

   const handleSkipThreeIndexes = () => {
      const skipThreeIndexes = pg
      setPg(skipThreeIndexes + 3)
   }

   const handleBackThreeIndexes = () => {
      const backThreeIndexes = pg
      setPg(backThreeIndexes - 3)
   }
   return (
      <DivFlex>
         <PageList>
            {pg > 1 && (
               <Paginate onClick={() => handlePaginate('previous')}>
                  <i className="fas fa-angle-left"></i>
               </Paginate>
            )}

            {pg > 3 && <Paginate onClick={() => setPg(1)}>1</Paginate>}

            {showResults ? (
               <Paginate onClick={handleBackThreeIndexes}>...</Paginate>
            ) : null}
            {Array.from(Array(lastPage), (item, index) => {
               if (
                  index + 1 === pg - 2 ||
                  index + 1 === pg - 1 ||
                  index + 1 === pg ||
                  index + 1 === pg + 1 ||
                  index + 1 === pg + 2
               ) {
                  if (pg <= lastPage) {
                     return (
                        <Paginate
                           value={index + 1}
                           onClick={(e) =>
                              setPg(parseInt(e.currentTarget.value))
                           }
                           isActive={index + 1 === pg}
                        >
                           {index + 1}
                        </Paginate>
                     )
                  }
               }
            })}

            {skipButton && (
               <Paginate onClick={handleSkipThreeIndexes}>...</Paginate>
            )}

            {!(pg >= lastPage - 2) && (
               <Paginate
                  isActive={pg === lastPage}
                  onClick={() => setPg(lastPage)}
               >
                  {lastPage}
               </Paginate>
            )}

            {!(pg === lastPage) && (
               <Paginate onClick={() => handlePaginate('next')}>
                  <i className="fas fa-angle-right"></i>
               </Paginate>
            )}
         </PageList>
      </DivFlex>
   )
}

const DivFlex = styled.div`
   display: flex;
`
const PageList = styled.ul``

const Paginate = styled.button<PaginationProps>(
   ({ theme, isActive }) => css`
      box-shadow: 0px 1px 0px ${theme.neutral.lightest};
      background-color: ${theme.neutral.white};
      color: ${isActive
         ? theme.colors.brand.primary.main
         : theme.neutral.light};
      font-size: ${theme.text.text1.size};
      font-family: ${theme.text.text1.family};
      line-height: ${theme.text.text1.lineHeight};
      font-weight: ${theme.text.text1.weight};
      border-radius: 8px;
      padding: 8px 16px;

      :nth-last-child(n + 2) {
         margin-right: ${theme.spacing.xs};
      }

      &:hover {
         color: ${theme.colors.brand.primary.main};
      }
   `,
)
export default Pagination
