const mp = new MercadoPago('TEST-09127da3-a732-4eff-8300-fe13135a1678');

let currentBin;

const createSelectOptions = (
   options,
   labelsAndKeys = { label: 'name', value: 'id' },
) => {
   const { label, value } = labelsAndKeys;

   let optionsArray = [];

   options.forEach((option) => {
      const optValue = option[value];
      const optLabel = option[label];

      optionsArray.push({ label: optLabel, value: optValue });
   });

   return optionsArray;
};

export const fieldCard = () => {
   const cardNumberElement = mp.fields
      .create('cardNumber', {
         placeholder: 'Número de la tarjeta',
      })
      .mount('form-checkout__cardNumber');

   cardNumberElement.on('binChange', async (data) => {
      console.log(data);
   });
};

export const getPaymentMethods = async (cartNum) => {
   const coreBin = cartNum.replace(/\s+/g, '');

   let objet = {
      bin: coreBin,
   };

   try {
      const { results } = await mp.getPaymentMethods(objet);
      const paymentMethod = results[0];

      return paymentMethod;
   } catch (error) {
      return console.error('Error getting PaymentMethod: ', e);
   }
};

export const getIdentificationTypes = async () => {
   try {
      const identificationTypes = await mp.getIdentificationTypes();
      const resp = await createSelectOptions(identificationTypes);
      return resp;
   } catch (e) {
      return console.error('Error getting identificationTypes: ', e);
   }
};

export const registro = () => {
   // const cardNumberElement = mp.fields.create('cardNumber', {
   //    placeholder: 'Número de la tarjeta',
   // })
   let opt = [
      { label: '1 Cuota', value: '1' },
      { label: '2 Cuotas', value: '2' },
      { label: '3 Cuotas', value: '3' },
      { label: '4 Cuotas', value: '4' },
      { label: '5 Cuotas', value: '5' },
      { label: '6 Cuotas', value: '3' },
   ];

   return opt;
};

export default {
   getIdentificationTypes,
   fieldCard,
   registro,
   getPaymentMethods,
};
