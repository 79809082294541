import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

const Container = styled.button`
   position: relative;
   display: flex;
   align-items: center;
   font-family: ${(props) => props.theme.text.title.family};
   font-size: ${(props) => props.theme.text.title.size};
   line-height: ${(props) => props.theme.text.title.lineHeight};
   font-weight: ${(props) => props.theme.text.title.weight};
   color: ${(props) => props.theme.neutral.lightness};
   background: transparent;

   :nth-last-child(n + 2) {
      padding-right: 56px;
   }

   > span {
      color: ${(props) => props.theme.colors.brand.secondary.main};
      padding: 0 ${(props) => props.theme.spacing.xs};
   }

   &:after {
      content: '';
      position: absolute;
      right: 0;
      width: 24px;
      height: 24px;

      background-image: url('/static/media/arrowDown.1ab1aed2.svg');
   }
`

const OptionsContainer = styled.div`
   position: absolute;
   top: 30px;
   right: 0;
   text-align: left;

   border-radius: 6px;
   box-shadow: 0px 10px 30px rgba(13, 51, 32, 0.1);
   border: 1px solid ${(props) => props.theme.neutral.lightest};

   background-color: ${(props) => props.theme.neutral.background};

   z-index: 999;
`

const OptionItem = styled.div`
   padding: 16px;
   min-width: 148px;

   border-bottom: 1px solid ${(props) => props.theme.neutral.lightest};

   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   line-height: ${(props) => props.theme.text.text1.lineHeight};
   font-weight: ${(props) => props.theme.text.text1.weight};
   color: ${(props) => props.theme.neutral.light};
`

type IconProps = {
   isActive: boolean
}

const Icon = styled.i<IconProps>`
   font-size: 12px;
   position: absolute;
   right: 31.5px;
   top: 31%;
   transform: rotate(${(props) => (props.isActive ? -180 : 0)}deg);
   transition: all ease 0.2s;
`

type Option = {
   label: string
   value: string
}

type FilterProps = {
   label: string
   options: Option[]
   value?: string
   onChangeValue?: (option: Option) => void
}

const FilterSelect = ({
   label,
   options,
   value,
   onChangeValue,
}: FilterProps): JSX.Element => {
   const optionWNone = useMemo(() => [...options], [options])

   const [selected, setSelected] = useState<Option>(optionWNone[0])
   const [opened, setOpened] = useState<boolean>(false)

   useEffect(() => {
      if (onChangeValue) onChangeValue(selected)
   }, [selected])

   useEffect(() => {
      if (value) {
         const opt = optionWNone.find((_option) => _option.value === value)
         if (opt) setSelected(opt)
      }
   }, [value])

   const handleSelect = (option: Option) => {
      setSelected(option)
   }

   const renderOption = (option: Option, index: number) => {
      return (
         <OptionItem key={index} onClick={() => handleSelect(option)}>
            {option.label}
         </OptionItem>
      )
   }

   return (
      <Container
         onClick={() => setOpened((_opened) => !_opened)}
         onMouseLeave={() => setOpened(false)}
      >
         <Icon isActive={opened} className="fas fa-chevron-down"></Icon>
         {label} <span>{selected.label}</span>
         {opened && (
            <OptionsContainer>{optionWNone.map(renderOption)}</OptionsContainer>
         )}
      </Container>
   )
}

export default FilterSelect
