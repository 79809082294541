import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import FilterSelect from 'components/FilterSelect'
import theme from '../styles/theme'
import { useFormatMessage } from 'react-intl-hooks'
import ReactLoading from 'react-loading'
import routes from '../routes/routes.json'
import { useDispatch, useSelector } from 'react-redux'
import { listDonors } from 'services/slices/listDonors'
import { RootState } from 'services/store'
import debounce from 'lodash.debounce'
import BadgeStatus from '../components/BadgeStatus'
import Pagination from 'components/Pagination'
import Container from 'components/Container'
import NoResult from 'components/NoResult'
import { useHistory } from 'react-router'
import useRegionalization from '../hooks/useRegionalization'
type Item = {
   id: number
   name: string
   simma_donor_id: string
   donor_media: {
      id: number
      donor_id: number
      media_source: string
      media_type: string
   }
   last_donation: {
      id: number
      donor_id: number
      donation_status: string
      child_quantity: number
      payment_date: string
   }
}

const Donors = (): JSX.Element => {
   const t = useFormatMessage()
   const dispatch = useDispatch()
   const history = useHistory()
   const { country } = useRegionalization()
   const { result, loadingStatus, paginate } = useSelector(
      (state: RootState) => ({
         result: state.listDonors.results,
         loadingStatus: state.listDonors.status,
         paginate: state.listDonors.paginate,
      }),
   )
   const [donationStatus, setDonationStatus] = useState<string>('all')
   const [term, setTerm] = useState<string>('')
   const [pg, setPg] = useState<number>(1)
   const [pp] = useState<number>(country === 'mx' ? 9 : 15)
   const [lastPage, setLastPage] = useState<number>(0)

   useEffect(() => {
      dispatch(
         listDonors({
            donationStatus: donationStatus,
            pp: pp,
            pg: pg,
            term: term,
         }),
      )
   }, [term, donationStatus, pg])

   useEffect(() => {
      setLastPage(/*NUMBERS OF PAGES */ Math.ceil(paginate.total / pp))
   }, [paginate])

   const renderAprroval = (item: Item, index: number) => {
      return (
         <ItemList key={index}>
            <TitleTable>{item.id}</TitleTable>
            <TextTableBold>{item.name}</TextTableBold>
            <TextTable>
               <BadgeStatus status={item.last_donation.donation_status} />
               {t({ id: item.last_donation.donation_status })}
            </TextTable>
            <DivFlexAround>
               <img src={`${theme.pathIcons}/user.svg`} />
               <TextTable>{item.last_donation.child_quantity}</TextTable>
            </DivFlexAround>
            <DivFlexBetween>
               {item.donor_media && (
                  <ItemListPhoto
                     role="button"
                     src={item.donor_media.media_source}
                  />
               )}
            </DivFlexBetween>
            <ButtonContainer>
               <ButtonList
                  onClick={() => {
                     history.push(
                        `${routes.details.replace(
                           ':donorID',
                           item.id.toString(),
                        )}`,
                     )
                  }}
               >
                  {t({ id: 'Abrir' })}
               </ButtonList>
            </ButtonContainer>
         </ItemList>
      )
   }

   const cleanFilters = () => {
      setDonationStatus('all')
      setTerm('')
   }

   const searchFilter = debounce((event) => {
      if (event.target.value.length > 2 || event.target.value.length == 0) {
         setTerm(event.target.value)
      }
   }, 500)

   return (
      <Container>
         <Content>
            <H1>{t({ id: 'Doadores' })}</H1>
            <Input
               type="email"
               name="user"
               placeholder={t({ id: 'Pesquise por nome ou email' }).toString()}
               onChange={searchFilter}
            />

            <DivFlex>
               <FilterSelect
                  value={donationStatus}
                  onChangeValue={(optionValue) =>
                     setDonationStatus(optionValue.value)
                  }
                  label={t({ id: 'Pagamento' }).toString()}
                  options={[
                     { label: t({ id: 'Todos' }).toString(), value: 'all' },
                     {
                        label: t({ id: 'Pendente' }).toString(),
                        value: 'pendent',
                     },
                     {
                        label: t({ id: 'Recusado' }).toString(),
                        value: 'refused',
                     },
                     { label: t({ id: 'Pago' }).toString(), value: 'paid' },
                  ]}
               />

               <LineVertical />

               <Filter onClick={cleanFilters}>
                  {t({ id: 'Limpar' })}
                  <img src={`${theme.pathIcons}/close.svg`} />
               </Filter>
            </DivFlex>
            <LineHorizontal />

            <List>
               <DivGrid>
                  <TitleTable>{t({ id: 'ID' })}</TitleTable>
                  <TitleTable>{t({ id: 'Nome' })}</TitleTable>
                  <TitleTable>{t({ id: 'Pagamento' })}</TitleTable>
                  <TitleTable>{t({ id: 'Criancas' })}</TitleTable>
                  <TitleTable>{t({ id: 'Foto' })}</TitleTable>
               </DivGrid>

               {loadingStatus === 'ok' && result && result.map(renderAprroval)}
               {loadingStatus === 'loading' && (
                  <LoadingContainer>
                     <ReactLoading
                        type="spin"
                        color={theme.colors.brand.primary.main}
                     />
                  </LoadingContainer>
               )}
               {loadingStatus === 'rejected' && result.length === 0 && (
                  <NoResult
                     label={t({ id: 'Nenhum resultado encontrado' }).toString()}
                  />
               )}
            </List>

            {loadingStatus === 'ok' && Math.ceil(paginate.total / pp) > 1 && (
               <Pagination
                  pg={pg}
                  setPg={setPg}
                  lastPage={lastPage}
                  total={paginate.total}
               />
            )}
         </Content>
      </Container>
   )
}

const LoadingContainer = styled.div`
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
`

const ButtonContainer = styled.div`
   text-align: right;
`

const ItemListPhoto = styled.img`
   cursor: pointer;
   width: 120px;
   height: 88px;
   object-fit: cover;
`

const Content = styled.div(
   ({ theme }) => css`
      min-height: 100vh;
      padding-top: 56px;
      flex-direction: column;
      align-items: center;
      display: flex;
      margin-bottom: ${theme.spacing.xl};
   `,
)

const H1 = styled.h1(
   ({ theme }) => css`
      padding-bottom: ${theme.spacing.xl};
      font-size: ${theme.text.heading1.size};
      color: ${theme.neutral.main};
      font-family: ${theme.text.heading1.family};
      font-weight: ${theme.text.heading1.weight};
   `,
)
const LineVertical = styled.div(
   ({ theme }) => css`
      background-color: ${theme.neutral.lightest};
      margin-right: ${theme.spacing.lg};
      height: 32px;
      width: 2px;
   `,
)

const LineHorizontal = styled.div(
   ({ theme }) => css`
      background-color: ${theme.neutral.lightest};
      margin: ${theme.spacing.xl} 0;
      width: 100%;
      height: 2px;
   `,
)

const DivGrid = styled.div(
   ({ theme }) => css`
      grid-template-columns: 2fr 5fr 1.5fr 1.5fr 2fr 1fr;
      margin-bottom: ${theme.spacing.md};
      padding: ${theme.spacing.sm} ${theme.spacing.md};
      width: 100%;
      grid-gap: 20px;
      align-items: center;
      display: grid;
   `,
)

const TitleTable = styled.p(
   ({ theme }) => css`
      line-height: ${theme.text.text1.lineHeight};
      font-family: ${theme.text.text1.family};
      font-size: ${theme.text.text1.size};
      font-weight: ${theme.text.text1.weight};
      color: ${theme.neutral.lightness};
   `,
)

const TextTableBold = styled(TitleTable)(
   ({ theme }) => css`
      color: ${theme.neutral.main};
      font-weight: ${theme.text.title.weight};
   `,
)

const TextTable = styled(TitleTable)(
   ({ theme }) => css`
      color: ${theme.neutral.main};
   `,
)

const ItemList = styled(DivGrid)(
   ({ theme }) => css`
      background-color: ${theme.neutral.white};
      margin-bottom: ${theme.spacing.xs};
      box-shadow: 0px 1px 0px ${theme.neutral.lightest};
      border-radius: 8px;
      border: 1px solid transparent;
      transition: border ease 0.3s;
      will-change: border;

      &:hover {
         border: 1px solid ${theme.colors.brand.secondary.main};
      }
   `,
)

const List = styled.div(
   ({ theme }) => css`
      width: 100%;
      > :last-child {
         margin-bottom: ${theme.spacing.xl};
      }
   `,
)

const ButtonList = styled.button(
   ({ theme }) => css`
      background: rgba(255, 95, 0, 0.08);
      padding: ${theme.spacing.xs} ${theme.spacing.sm};
      font-family: ${theme.text.heading2.family};
      color: ${theme.colors.brand.primary.main};
      font-weight: ${theme.text.title.weight};
      font-size: ${theme.text.title.size};
      border-radius: ${theme.spacing.xxl};
      transition: all ease 0.3s;

      &:hover {
         color: ${theme.neutral.white};
         background: ${theme.colors.brand.primary.main};
      }
   `,
)

const DivFlex = styled.div`
   display: flex;
`

const DivFlexBetween = styled.div`
   display: flex;
   justify-content: space-between;
`

const DivFlexAround = styled.div`
   display: flex;

   > img {
      margin-right: 16px;
   }
`

const Filter = styled.button(
   ({ theme }) => css`
      display: flex;
      align-items: center;
      font-family: ${theme.text.title.family};
      font-size: ${theme.text.title.size};
      line-height: ${theme.text.title.lineHeight};
      font-weight: ${theme.text.title.weight};
      color: ${theme.neutral.lightness};
      background: transparent;

      :nth-last-child(n + 2) {
         padding-right: ${theme.spacing.lg};
      }

      > span {
         color: ${theme.colors.brand.secondary.main};
         padding: 0 ${theme.spacing.xs};
      }
   `,
)
const Input = styled.input(
   ({ theme }) => css`
      border: 1.2px solid ${theme.neutral.lightest};
      margin: ${theme.spacing.xs} 0 ${theme.spacing.md};
      padding: ${theme.spacing.sm} ${theme.spacing.md};
      border-radius: ${theme.spacing.xs};
      display: block;
      width: 100%;

      line-height: ${theme.text.text1.lineHeight};
      font-family: ${theme.text.text1.family};
      font-size: ${theme.text.text1.size};
      font-weight: ${theme.text.text1.weight};

      :focus {
         outline-color: ${theme.colors.brand.secondary.main};
      }
   `,
)

export default Donors
