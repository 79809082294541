import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import api from 'services/api'

export type DonationProductsProps = {
   id: string
   item: string
   price: number
   type: string
   description_type: string
}


/**
 * inicializa los productos necesarios para efectuar los pagos
 * !1)solo se esta utilizando el tipo 1 del arreglo q retorna el servicio
 */
export const donationProducts = createAsyncThunk(
   'donationProducts',
   async () => {
      return await api.get('/products').then((res) => res.data)
   },
)

const donationProductsSlice = createSlice({
   name: 'donationProducts',
   initialState: {
      status: 'idle',
      response: [],
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(donationProducts.pending, (state) => {
         state.status = 'loading'
      })
      builder.addCase(
         donationProducts.fulfilled,
         (state, action: PayloadAction<DonationProductsProps[]>) => {
            state.status = 'ok'
            state.response = action.payload
         },
      ),
         builder.addCase(donationProducts.rejected, (state) => {
            state.status = 'rejected'
         })
   },
})

export default donationProductsSlice.reducer
