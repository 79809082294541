import Spacer from 'components/Spacer';
import Steps, { Step } from 'components/Steps';
import useCheckoutForm, { CheckoutFormProvider } from 'hooks/useCheckoutForm';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import pipe from 'utils/pipe';
import withProvider from 'utils/withProvider';
import CheckoutDataForm from './CheckoutForms/CheckoutDataForm';
import CheckoutDonationForm from './CheckoutForms/CheckoutDonationForm';
import CheckoutPhotoForm from './CheckoutForms/CheckoutPhotoForm';
import 'cleave.js/dist/addons/cleave-phone.br';
import useRegionalization from 'hooks/useRegionalization';
import routes from '../routes/routes.json';
import { useFormatMessage } from 'react-intl-hooks';

const Checkout = (): JSX.Element => {
   const t = useFormatMessage();
   const history = useHistory();

   const { checkoutStep, setCheckoutStep, addCheckoutFormData } =
      useCheckoutForm();

   const {
      country,
      setCountry,
      regionalization,
      setRegionalization,
      phoneCountry,
      setPhoneCountry,
   } = useRegionalization();

   const importReginalization = async (country: string) => {
      try {
         const regionalization = await import(
            '../translations/inputs/' + country + '.ts'
         );
         setRegionalization(regionalization.default);
      } catch (error) {
         importReginalization('br');
      }
   };

   useEffect(() => {
      setRegionalization(undefined);
      setTimeout(() => {
         importReginalization(country);
      }, 500);
   }, [country]);

   const steps: Step[] = [
      {
         title: t({ id: 'Dados pessoais' }).toString(),
         component: (
            <CheckoutDataForm
               onSubmit={(values) => {
                  addCheckoutFormData(values);
                  setCheckoutStep(1);
               }}
            />
         ),
      },
      {
         title: t({ id: 'Sua fotos' }).toString(),
         component: (
            <CheckoutPhotoForm
               onBack={() => setCheckoutStep(0)}
               onSubmit={(values) => {
                  addCheckoutFormData(values);
                  setCheckoutStep(2);
               }}
            />
         ),
      },
      {
         title: t({ id: 'Sua doacao' }).toString(),
         component: (
            <CheckoutDonationForm
               onBack={() => setCheckoutStep(1)}
               onSubmit={(values?) => {
                  if (values) {
                     addCheckoutFormData(values);
                     history.push(routes.thanks);
                  } else {
                     history.push(routes.thanksUps);
                  }
               }}
            />
         ),
      },
   ];

   return (
      <Container>
         <Spacer bottom={56} />

         <Title>{t({ id: 'Seja escolhido por uma crianca hoje' })}</Title>
         <Spacer bottom={16} />

         <Subtitle>{t({ id: 'Preencha o formulario a seguir' })}</Subtitle>
         <Spacer bottom={40} />

         {regionalization && (
            <Steps
               steps={steps}
               activeStep={checkoutStep}
               onStepChange={(_, index) => setCheckoutStep(index)}
            />
         )}
      </Container>
   );
};

const enhance = pipe(withProvider(CheckoutFormProvider));

export default enhance(Checkout);

const Container = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   max-width: 530px;
   margin: 0 auto;
`;

const Title = styled.h1`
   font-family: ${(props) => props.theme.text.heading1.family};
   font-size: ${(props) => props.theme.text.heading1.size};
   font-weight: ${(props) => props.theme.text.heading1.weight};
   line-height: ${(props) => props.theme.text.heading1.lineHeight};
   color: ${(props) => props.theme.neutral.main};
   margin: 0;
   max-width: 300px;
   text-align: center;
`;

const Subtitle = styled.h2`
   font-family: ${(props) => props.theme.text.text1.family};
   font-size: ${(props) => props.theme.text.text1.size};
   font-weight: ${(props) => props.theme.text.text1.weight};
   line-height: ${(props) => props.theme.text.text1.lineHeight};
   color: ${(props) => props.theme.neutral.sub};
   margin: 0;
   max-width: 300px;
   text-align: center;
`;
