import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'services/api';

export type DonorFormProps = {
   document: {
      type: string | number;
      value: string | number;
   };
   card: {
      number: string | number;
      exp_month: string | number;
      exp_year: string | number;
      cvc: string | number;
      holder_name: string | number;
      installments: string | number;
   };
   child_quantity: string | number;
   address: {
      street: string;
      number: string;
      complement: string;
      zipcode: string;
      neightborhood: string;
      city: string;
      state: string;
      country: string;
   };
   items_id: string[];
};

type DataResponse = {
   card: {
      emitter: string;
      expiration_month: number;
      expiration_year: number;
      first_eigth_digits: string;
      last_four_digits: string;
   };
   identification: string;
   typeIdentification: string;
   transaction: {
      id: number;
      status: string;
      status_detail: string;
   };
   transaction_amount: string;
   payment_method_id: string;
   contactForm: {
      email: string;
      firstName: string;
      lastName: string;
      phone: string;
   };
};

type RequestProps = {
   data: DataResponse;
   id: string;
};

type PaymentResponse = {
   message: string;
};

export type ApprovePayPops = {
   id: string;
   status: string;
   status_detail: string;
};

export const newDonationPayment = createAsyncThunk(
   'donorImage',
   async ({ data, id }: RequestProps) => {
      return await api
         .post(`checkout/add-pay-donor/${id}`, data)
         .then((res) => res.data);
   },
);

const newDonationPaymentSlice = createSlice({
   name: 'donorImage',
   initialState: {
      status: 'idle',
      message: {},
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(newDonationPayment.pending, (state) => {
         state.status = 'loading';
      });
      builder.addCase(
         newDonationPayment.fulfilled,
         (state, action: PayloadAction<ApprovePayPops>) => {
            state.status = 'ok';
            state.message = action.payload;
         },
      );
      builder.addCase(newDonationPayment.rejected, (state) => {
         state.status = 'rejected';
      });
   },
});

export default newDonationPaymentSlice.reducer;
