const formatMoney = (amount: number) => {
   const amountStr = String(amount)
   const amountLength = amountStr.length

   let newAmountNumber = 0
   let locale = 'pt-BR'
   let currencyMoney = 'BRL'

   if (amountLength > 2) {
      const unityLength = amountLength
      const amountUnity = amountStr.substr(0, unityLength)
      const amountCents = amountStr.substr(-2)

      const newAmount = `${amountUnity}.${amountCents}`

      newAmountNumber = Number(newAmount)
   } else {
      const newAmount = `0.${amount}`

      newAmountNumber = Number(newAmount)
   }

   if (process.env.REACT_APP_LANGUAGE === 'mx') {
      locale = 'es-CO'
      currencyMoney = 'COP'
   }

   return Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyMoney,
      maximumSignificantDigits: 2
   }).format(newAmountNumber)
}

export default formatMoney
