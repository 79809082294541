import { useFormatMessage } from 'react-intl-hooks';
import { Link } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import styled, { css } from 'styled-components/macro';
import Container from './Container';
import useRegionalization from 'hooks/useRegionalization';
import { useState } from 'react';

type NaveHeder = {
   handleNaveLP: (position: string) => void;
};

const LandingHeader = ({ handleNaveLP }: NaveHeder): JSX.Element => {
   const [isOpen, setIsOpen] = useState(false);

   const { country } = useRegionalization();
   const t = useFormatMessage();

   const toggleMenu = () => {
      console.log('dioclick');
      setIsOpen(!isOpen);
   };

   const handleNaveTarget = (targetRef: string) => {
      handleNaveLP(targetRef);
   };
   return (
      <HeaderBackground>
         <Container>
            <Content>
               <CounterInfo>
                  {country === 'mx' ? (
                     <Img
                        src="/assets/images/logo-chosen.png"
                        alt="Logo Visão Mundial"
                     />
                  ) : (
                     <Img
                        src="/assets/images/vmb.png"
                        alt="Logo Visão Mundial"
                     />
                  )}
                  <CounterBugerMenu>
                     <MenuButton onClick={toggleMenu}>
                        <StyleIconMenu />
                     </MenuButton>
                     <Menu isOpen={isOpen}>
                        <MenuItem
                           onClick={() => {
                              handleNaveTarget('counterSummary');
                           }}
                        >
                           Que es Elegido
                        </MenuItem>
                        <MenuItem
                           onClick={() => {
                              handleNaveTarget('howItImpact');
                           }}
                        >
                           Impacto de tu donacion
                        </MenuItem>
                        <MenuItem
                           onClick={() => {
                              handleNaveTarget('howItWorks');
                           }}
                        >
                           Como ser Elegido
                        </MenuItem>
                        <MenuItem
                           onClick={() => {
                              handleNaveTarget('successStoryes');
                           }}
                        >
                           Testimonios
                        </MenuItem>
                        {/* <MenuItem
                           onClick={() => {
                              handleNaveTarget('scrollTargetRef');
                           }}
                        >
                           Preguntas
                        </MenuItem> */}
                     </Menu>
                  </CounterBugerMenu>
               </CounterInfo>
               <ButtonNave
                  onClick={() => {
                     handleNaveTarget('counterSummary');
                  }}
               >
                  Que es Elegido
               </ButtonNave>
               <ButtonNave
                  onClick={() => {
                     handleNaveTarget('howItImpact');
                  }}
               >
                  Impacto de tu donacion
               </ButtonNave>
               <ButtonNave
                  onClick={() => {
                     handleNaveTarget('howItWorks');
                  }}
               >
                  Como ser Elegido
               </ButtonNave>
               <ButtonNave
                  onClick={() => {
                     handleNaveTarget('successStoryes');
                  }}
               >
                  Testimonios
               </ButtonNave>
               {/* <ButtonNave
                  onClick={() => {
                     handleNaveTarget('scrollTargetRef');
                  }}
               >
                  Preguntas
               </ButtonNave> */}
               <CustomLink to="/checkout">{t({ id: 'doe' })}</CustomLink>
            </Content>
         </Container>
      </HeaderBackground>
   );
};

const CounterInfo = styled.div`
   display: flex;
   flex-direction: row;
`;

const CounterBugerMenu = styled.div`
   display: none;
   @media (max-width: 900px) {
      display: flex;
   }
`;

const ButtonNave = styled.button(
   ({ theme }) => css`
      background-color: ${theme.neutral.white};
      font-family: ${theme.text.text1.family};
      font-weight: ${theme.text.text1.weight};
      color: ${theme.neutral.sub};
      font-size: 14px;
      max-width: 90px;

      @media (max-width: 900px) {
         display: none;
      }
   `,
);

const HeaderBackground = styled.header(
   ({ theme }) => css`
      box-shadow: inset 0px -1px 0px ${theme.neutral.lightest};
      background-color: ${theme.neutral.white};
      padding: 16px 0;
   `,
);

const Content = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
`;

const CustomLink = styled(Link)`
   align-items: center;
   padding: 8px 24px;
   background: ${({ theme }) => theme.colors.brand.primary.main};
   border-radius: 90px;
   text-transform: capitalize;
   border: 2px solid ${({ theme }) => theme.colors.brand.primary.main};
   font-family: ${({ theme }) => theme.text.title.family};
   font-size: ${({ theme }) => theme.text.title.size};
   font-weight: ${({ theme }) => theme.text.title.weight};
   line-height: ${({ theme }) => theme.text.title.lineHeight};
   color: ${({ theme }) => theme.neutral.white};
   text-decoration: none;
   transition: all ease 300ms;

   &:hover {
      background: ${({ theme }) => theme.neutral.white};
      color: ${({ theme }) => theme.colors.brand.primary.main};
   }
`;

const Img = styled.img`
   max-width: 146px;
   width: 100%;
   object-fit: contain;
`;

const Menu = styled.nav<{ isOpen }>`
   display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
   flex-direction: column;
   background-color: #fff3eb;
   border-radius: 9px;
   margin-top: 38px;

   position: absolute;
   z-index: 1;
   padding: 20px;
`;

const MenuButton = styled.div`
   cursor: pointer;
   margin-left: 20px;
   margin-top: 6px;
`;

// Estilos para los elementos del menú
const MenuItem = styled.button`
   background-color: #fff3eb;
   color: #8d8f96;
   font-family: Inter;
   font-size: 14px;
   margin-bottom: 10px;

   &:hover {
      border-bottom: 1px solid #8d8f96;
   }
`;

const StyleIconMenu = styled(GiHamburgerMenu)`
   color: #ff5f00;
   font-size: 20px;
`;

export default LandingHeader;
