import { HTMLAttributes } from 'react'
import styled from 'styled-components'

type DirectionProps = {
   all?: number
   left?: number
   top?: number
   right?: number
   bottom?: number
}

const Container = styled.div<DirectionProps>`
   margin-left: ${(props) =>
      props.all ? props.all : props.left ? `${props.left}px` : 0};
   margin-top: ${(props) =>
      props.all ? props.all : props.top ? `${props.top}px` : 0};
   margin-right: ${(props) =>
      props.all ? props.all : props.right ? `${props.right}px` : 0};
   margin-bottom: ${(props) =>
      props.all ? props.all : props.bottom ? `${props.bottom}px` : 0};
`

type Props = HTMLAttributes<HTMLDivElement> & DirectionProps

const Spacer = ({ ...props }: Props): JSX.Element => {
   return <Container {...props} />
}

export default Spacer
