export const uf = [
   { uf: 'AC' },
   { uf: 'AL' },
   { uf: 'AP' },
   { uf: 'AM' },
   { uf: 'BA' },
   { uf: 'CE' },
   { uf: 'DF' },
   { uf: 'ES' },
   { uf: 'GO' },
   { uf: 'MA' },
   { uf: 'MT' },
   { uf: 'MS' },
   { uf: 'MG' },
   { uf: 'PA' },
   { uf: 'PB' },
   { uf: 'PR' },
   { uf: 'PE' },
   { uf: 'PI' },
   { uf: 'RJ' },
   { uf: 'RN' },
   { uf: 'RS' },
   { uf: 'RO' },
   { uf: 'RR' },
   { uf: 'SC' },
   { uf: 'SP' },
   { uf: 'SE' },
   { uf: 'TO' },
]

export const mxStates = [
   { uf: 'Distrito Federal' },
   { uf: 'Aguascalientes' },
   { uf: 'Baja California' },
   { uf: 'Baja California Sur' },
   { uf: 'Campeche' },
   { uf: 'Chiapas' },
   { uf: 'Chihuahua' },
   { uf: 'Coahuila' },
   { uf: 'Colima' },
   { uf: 'Durango' },
   { uf: 'Guanajuato' },
   { uf: 'Guerrero' },
   { uf: 'Hidalgo' },
   { uf: 'Jalisco' },
   { uf: 'Michoacán' },
   { uf: 'Morelos' },
   { uf: 'México' },
   { uf: 'Nayarit' },
   { uf: 'Nuevo León' },
   { uf: 'Oaxaca' },
   { uf: 'Puebla' },
   { uf: 'Querétaro' },
   { uf: 'Quintana Roo' },
   { uf: 'San Luis Potosí' },
   { uf: 'Sinaloa' },
   { uf: 'Sonora' },
   { uf: 'Tabasco' },
   { uf: 'Tamaulipas' },
   { uf: 'Tlaxcala' },
   { uf: 'Veracruz' },
   { uf: 'Yucatán' },
   { uf: 'Zacateca' },
]
