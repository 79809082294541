import AppModal from 'components/AppModal'

import styled, { css } from 'styled-components/macro'
import Spacer from 'components/Spacer'

type ChosenMediaModal = {
   onClose: () => void
   src: string
   type: string
}
const ChosenMedia = ({ onClose, src, type }: ChosenMediaModal): JSX.Element => {
   return (
      <>
         <AppModalCustom>
            <Spacer top={75} />
            <ContainerModal>
               <ModalCloseButton type="button" onClick={() => onClose()} />

               {type === 'video' ? (
                  <VideofromChild>
                     <iframe
                        width="100%"
                        height="100%"
                        src={src}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                     ></iframe>
                  </VideofromChild>
               ) : (
                  <ImgChosen
                     src={src ?? '/assets/images/examples/letter-chosen.png'}
                  ></ImgChosen>
               )}
            </ContainerModal>
            <Spacer bottom={75} />
         </AppModalCustom>
      </>
   )
}

export default ChosenMedia

const AppModalCustom = styled.div(
   ({ theme }) => css`
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1001;
      width: 100%;
      height: 100%;
      background-color: #6c6f77a2;
      overflow: auto;
   `,
)

const VideofromChild = styled.div(
   ({ theme }) => css`
      width: 100%;
      height: 720px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
   `,
)
const ContainerModal = styled.div(
   ({ theme }) => css`
      max-width: 990px;
      border-radius: ${theme.spacing.xxs};
      background-color: ${theme.neutral.white};
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      position: relative;

      @media screen and (max-width: 940px) {
         height: 100vh;
         background-color: #6c6f77;
      }
   `,
)
const ImgChosen = styled.img(
   ({ theme }) => css`
      width: 90%;
      display: flex;
      justify-content: center;
   `,
)
const ModalCloseButton = styled.button`
   width: 30px;
   height: 30px;

   position: absolute;
   right: -62px;
   top: 5px;
   z-index: 1010;

   background: none;
   background-size: 23px;
   background-repeat: no-repeat;
   background-image: url('/assets/images/icons/checkboxFalseInverse.svg');
   background-position: center;
   @media screen and (max-width: 1470px) {
      top: 20px;
      position: absolute;
      right: 70px;
   }
   @media screen and (max-width: 1172px) {
      top: 20px;
      position: absolute;
      right: 2px;
   }
   @media screen and (max-width: 890px) {
      position: absolute;
      right: 5px;
      top: 20px;
      background-image: url('/assets/images/icons/checkboxFalseInverse.svg');
   }
`
