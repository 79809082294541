import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import api from 'services/api'

export type FinancialInstitutionsProps = {
    id: string
    description: string
}
export const financialInstitutions = createAsyncThunk(
    'financialInstitutions',
    async () => {
        return await api.get('/payment_metods_pse').then((res) => res.data)
    },
)

const financialInstitutionsSlice = createSlice({
    name: 'financialInstitutions',
    initialState: {
        status: 'idle',
        response: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(financialInstitutions.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(
            financialInstitutions.fulfilled,
            (state, action: PayloadAction<FinancialInstitutionsProps[]>) => {
                state.status = 'ok'
                state.response = action.payload
            },
        ),
            builder.addCase(financialInstitutions.rejected, (state) => {
                state.status = 'rejected'
            })
    },
})

export default financialInstitutionsSlice.reducer
